import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {PeripheralAbilityCommandApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import PeripheralAbilityCommandTable from '../../components/PeripheralAbilityCommand/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import PeripheralAbilityCommandEditForm from '../../components/PeripheralAbilityCommand/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function PeripheralAbilityCommandIndexPage() {
  const searchHook = useOpenApiFpRequest(PeripheralAbilityCommandApi, PeripheralAbilityCommandApi.prototype.peripheralAbilityCommandSearchGet);
  const isAddPeripheralAbilityCommand = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          外设功能指令管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddPeripheralAbilityCommand.setTrue}>
              录入外设功能指令
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <PeripheralAbilityCommandTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入外设功能指令'} visible={isAddPeripheralAbilityCommand.value} maskClosable={false} closable={false} footer={false}>
        <PeripheralAbilityCommandEditForm
          onCancel={isAddPeripheralAbilityCommand.setFalse}
          onSuccess={res => {
            isAddPeripheralAbilityCommand.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
