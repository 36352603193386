import React, {useEffect} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionTemplateActionApi, MissionTemplateApi} from '../../scaffold';
import {MissionTemplateDescriptions} from './Descriptions';
import {Card, Space, Typography} from 'antd';
import MissionTemplateActionTable from '../MissionTemplateAction/Table';
import {useAntdTable} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
export function MissionTemplateDetail(props: {id?: number}) {
  return (
    <div>
      <Typography.Title level={4}>任务信息</Typography.Title>
      <Card bodyStyle={{padding: 0}}>
        <MissionTemplateDescriptions id={props.id} />
      </Card>
      <Typography.Title level={4} style={{marginTop: 16}}>
        指令序列
      </Typography.Title>
      <MissionTemplateActionTable id={props.id} />
    </div>
  );
}
