export enum UserRolePermissionType {
  用户管理,
  角色管理,
  机器人管理,
  外设管理,
  任务基础数据管理,
  地点管理,

  下发任务,

  任务查看,

  任务控制,

  历史任务查询,

  日志查询,

  外接设备状态查询,

  历史外接设备状态查询,

  音频对讲,
  音频视频,
}
