import React, {useEffect, useState} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, RobotMessageDto, RobotMessageApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Button, Image, message, Modal, Space, Table, TableProps, Typography} from 'antd';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import ReactJson from 'react-json-view';

export default function RobotMessageTable(props: {antDTable: Result<RobotMessageDto>; tableProps?: TableProps<any>}) {
  const {tableProps, search, loading} = props.antDTable;
  const removeHook = useOpenApiFpRequest(RobotMessageApi, RobotMessageApi.prototype.robotMessageRemovePost);
  const [selected, setSelected] = useState<RobotMessageDto>();
  return (
    <>
      <Table<RobotMessageDto> {...tableProps} {...props.tableProps} rowKey={'id'} bordered>
        <Table.Column<RobotMessageDto> title="PackageId" width={300} dataIndex={['packageId']} />
        <Table.Column<RobotMessageDto> title="机器人" width={100} dataIndex={['robot', 'name']} />
        <Table.Column<RobotMessageDto> title="发送方" width={100} dataIndex={'from'} />
        <Table.Column<RobotMessageDto> title="发送时间" width={200} dataIndex={'createdTime'} />
        <Table.Column<RobotMessageDto>
          title="发送内容"
          dataIndex={'message'}
          render={txt => (
            <Typography.Paragraph
              ellipsis={{
                rows: 3,
              }}>
              {txt}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<RobotMessageDto>
          title="操作"
          render={(txt, row) => (
            <Button
              type={'link'}
              onClick={() => {
                setSelected(row);
              }}>
              查看报文
            </Button>
          )}
        />
      </Table>
      <Modal title={`PackageId: ${selected?.packageId} 报文信息`} visible={!!selected} onCancel={() => setSelected(undefined)} footer={null} width={800}>
        {selected?.message && <ReactJson name={null} collapsed={2} src={JSON.parse(selected?.message)} />}
      </Modal>
    </>
  );
}
