import React from 'react';
import {Button, message, notification} from 'antd';
import {MissionApi, MissionDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
export function MissionPauseButton(props: {mission?: MissionDto; onSuccess?: () => any}) {
  const pauseHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionPausePost);
  return (
    <Button
      type={'primary'}
      disabled={!props.mission}
      onClick={() => {
        const res = confirm('您确认要暂停此任务?');
        if (res) {
          props.mission?.id &&
            pauseHook
              .request({
                missionPauseParams: {
                  id: props.mission?.id,
                },
              })
              .then(r => {
                notification.success({
                  message: '暂停任务',
                  description: '您已成功暂停此任务,如要恢复请点击「继续任务」',
                });
              })
              .catch(e => {
                message.error({
                  content: e.message,
                });
              })
              .finally(() => {
                props.onSuccess && props.onSuccess();
              });
        }
      }}>
      暂停任务
    </Button>
  );
}
