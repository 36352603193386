import React, {useMemo} from 'react';
import {Tag} from 'antd';

export function IsDeleteTag(props: {isDelete: boolean}) {
  const msg = useMemo(() => {
    return props.isDelete ? '冻结' : '正常';
  }, [props.isDelete]);
  const color = useMemo(() => {
    return props.isDelete ? 'red' : 'green';
  }, [props.isDelete]);
  return <Tag color={color}>{msg}</Tag>;
}
