import React, {useEffect, useState} from 'react';
import {Gender, AlertDto, AlertApi, MissionApi} from '../../scaffold';
import {Avatar, Button, Drawer, Image, message, Modal, Space, Table, TableProps, Tag, Typography} from 'antd';
import {Result} from 'ahooks/lib/useAntdTable';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {AlertIcon} from './Icon';
import Flex from '../Shared/Flex';
import {MissionDetail} from '../Mission/Detail';

export default function AlertTable(props: {antDTable: Result<AlertDto>; tableProps?: TableProps<any>; onSuccess?: () => any}) {
  const {tableProps, search, loading} = props.antDTable;
  const removeHook = useOpenApiFpRequest(AlertApi, AlertApi.prototype.alertRemovePost);
  const missionFindHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionFindGet);
  const [selected, setSelected] = useState<AlertDto>();
  const [missionId, setMissionId] = useState<number>();
  const [robotId, setRobotId] = useState<number>();
  useEffect(() => {
    if (missionId) {
      missionFindHook.requestSync({
        id: missionId,
      });
    }
  }, [missionId]);
  return (
    <>
      <Table<AlertDto> {...tableProps} {...props.tableProps} rowKey={'id'} size={'small'} bordered>
        <Table.Column<AlertDto>
          width={200}
          title="警报标题"
          dataIndex={'title'}
          render={(txt, row) => (
            <Flex align={'center'}>
              <AlertIcon alert={row} />
              <Typography.Text strong style={{marginLeft: 8}}>
                {txt}
              </Typography.Text>
            </Flex>
          )}
        />
        <Table.Column<AlertDto>
          width={500}
          title="警报内容"
          dataIndex={'description'}
          render={txt => (
            <Typography.Paragraph ellipsis={{rows: 2}} strong style={{width: 500}}>
              {txt}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<AlertDto> width={120} title="触发时间" dataIndex={'createdTime'} />
        <Table.Column<AlertDto>
          title="相关任务 ID"
          dataIndex={'missionId'}
          render={txt => {
            return (
              <Button
                type={'link'}
                onClick={() => {
                  setMissionId(txt);
                }}>
                {txt}
              </Button>
            );
          }}
        />
        <Table.Column<AlertDto> title="相关人员" dataIndex={['user', 'name']} />
        <Table.Column<AlertDto> title="相关机器人" dataIndex={['robot', 'name']} />
        <Table.Column<AlertDto>
          title="操作"
          width={80}
          render={(text, row) => {
            return (
              <Space>
                <Button
                  type={'link'}
                  danger={true}
                  onClick={() => {
                    const res = confirm('您确认是否删除此楼层');
                    if (res && row.id) {
                      removeHook
                        .request({
                          alertRemoveParams: {id: row.id},
                        })
                        .then(() => {
                          props.onSuccess && props.onSuccess();
                        })
                        .catch(e => message.error(e));
                    }
                  }}>
                  删除
                </Button>
                <Button
                  type={'link'}
                  onClick={() => {
                    setSelected(row);
                  }}>
                  查看错误信息
                </Button>
              </Space>
            );
          }}
        />
      </Table>
      <Modal
        title={'错误信息'}
        visible={!!selected}
        footer={null}
        width={800}
        onCancel={() => {
          setSelected(undefined);
        }}>
        <Typography.Text style={{whiteSpace: 'pre-line'}}>{selected?.description}</Typography.Text>
      </Modal>
      <Modal
        title={'任务信息'}
        visible={!!missionId}
        footer={null}
        width={1000}
        onCancel={() => {
          setMissionId(undefined);
        }}>
        <MissionDetail mission={missionFindHook.data} />
      </Modal>
    </>
  );
}
