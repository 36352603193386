import {Checkbox, Row} from 'antd';
import React from 'react';
import {Col} from 'antd';
import {CheckboxGroupProps} from 'antd/es/checkbox';
export function DayOfWeeksEditor(props: CheckboxGroupProps) {
  return (
    <Checkbox.Group {...props}>
      <Checkbox value={1}>周一</Checkbox>
      <Checkbox value={2}>周二</Checkbox>
      <Checkbox value={3}>周三</Checkbox>
      <Checkbox value={4}>周四</Checkbox>
      <Checkbox value={5}>周五</Checkbox>
      <Checkbox value={6}>周六</Checkbox>
      <Checkbox value={0}>周日</Checkbox>
    </Checkbox.Group>
  );
}
