import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {RobotFunctionApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import RobotFunctionTable from '../../components/RobotFunction/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import RobotFunctionEditForm from '../../components/RobotFunction/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';

export function RobotFunctionIndexPage() {
  const searchHook = useOpenApiFpRequest(RobotFunctionApi, RobotFunctionApi.prototype.robotFunctionSearchGet);
  const isAdd = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            第三方服务
          </Title>
          <Typography.Text type={'secondary'}>用于监控机器人连接的各种第三方服务,如 OSS、FTP 等</Typography.Text>
        </div>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAdd.setTrue}>
              录入第三方服务
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <RobotFunctionTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入机器人服务'} visible={isAdd.value} maskClosable={false} closable={false} footer={false}>
        <RobotFunctionEditForm
          onCancel={isAdd.setFalse}
          onSuccess={res => {
            isAdd.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
