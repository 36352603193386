import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {AlertApi, SorterOrder} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import AlertTable from '../../components/Alert/Table';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {ReloadOutlined} from '@ant-design/icons';
import Flex from '../../components/Shared/Flex';
export function AlertIndexPage() {
  const searchHook = useOpenApiFpRequest(AlertApi, AlertApi.prototype.alertSearchGet);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  function refresh() {
    antdConfig.search.submit();
  }
  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <Title level={4} style={{marginBottom: 0}}>
            异常警报
          </Title>
          <div
            style={{marginLeft: 4, marginRight: 4, cursor: 'pointer'}}
            onClick={() => {
              refresh();
            }}>
            <ReloadOutlined spin={searchHook.loading} style={{color: '#666', width: 16, height: 16}} />
          </div>
          <Typography.Text type={'secondary'}>您可以再次查询系统历史异常警报</Typography.Text>
        </Flex>
      }>
      <Skeleton loading={searchHook.loading}>
        <AlertTable
          onSuccess={() => {
            antdConfig.search.submit();
          }}
          antDTable={antdConfig}
          tableProps={{size: 'small'}}
        />
      </Skeleton>
    </LayoutPage>
  );
}
