import {useAntdTable} from 'ahooks';
import {message} from 'antd';
import {CombineService, PaginatedParams} from '@ahooksjs/use-request/lib/types';
import {BaseOptions, OptionsWithFormat} from 'ahooks/lib/useAntdTable';

export const DefaultAntdTableConfig = {
  defaultPageSize: 10,
  loadingDelay: 500,
  onSuccess: () => message.success('加载成功'),
  onError: (e: Error) => message.error(e.message),
};
export const ListToAntdTable = async (task: Promise<any[]>) => {
  return async () => {
    const res = await task;
    return {
      list: res,
      total: res.length,
    };
  };
};

export function useAntdTableWithList<R = any, Item = any, U extends Item = any>(task: () => Promise<any[]>, config?: BaseOptions<U>) {
  const antdConfig = useAntdTable(
    async params => {
      const res = await task();
      return {
        list: res,
        total: res.length,
      };
    },
    {...DefaultAntdTableConfig, ...config},
  );
  return antdConfig;
}
