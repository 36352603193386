import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {UserApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import UserTable from '../../components/User/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import UserEditForm from '../../components/User/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
export function UserIndexPage() {
  const searchHook = useOpenApiFpRequest(UserApi, UserApi.prototype.userSearchGet);
  const isRegisterUser = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          用户管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isRegisterUser.setTrue}>
              录入用户
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <UserTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入用户'} visible={isRegisterUser.value} maskClosable={false} closable={false} footer={false}>
        <UserEditForm
          onCancel={isRegisterUser.setFalse}
          onSuccess={res => {
            isRegisterUser.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
