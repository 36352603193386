import React, {CSSProperties} from 'react';
import LayoutHeader from './LayoutHeader';
import Title from 'antd/lib/typography/Title';
import './LayoutPage.less';
export default function LayoutPage(props: {header?: any; style?: CSSProperties; children?: any}) {
  return (
    <div>
      <LayoutHeader>{props.header}</LayoutHeader>
      <div style={{...props.style}} className={'AppLayoutMainBody LayoutPage'}>
        {props.children}
      </div>
    </div>
  );
}
