/* tslint:disable */
/* eslint-disable */
/**
 * Oxiracetam-BE
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActionObjectDto
 */
export interface ActionObjectDto {
    /**
     * 
     * @type {number}
     * @memberof ActionObjectDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionObjectDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionObjectDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionObjectDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionObjectDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionObjectDtoSearchResponseDto
 */
export interface ActionObjectDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ActionObjectDto>}
     * @memberof ActionObjectDtoSearchResponseDto
     */
    'list'?: Array<ActionObjectDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ActionObjectDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ActionObjectSaveParams
 */
export interface ActionObjectSaveParams {
    /**
     * 
     * @type {string}
     * @memberof ActionObjectSaveParams
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActionObjectSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionTargetDto
 */
export interface ActionTargetDto {
    /**
     * 
     * @type {number}
     * @memberof ActionTargetDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionTargetDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionTargetDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionTargetDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionTargetDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ActionTargetDtoSearchResponseDto
 */
export interface ActionTargetDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ActionTargetDto>}
     * @memberof ActionTargetDtoSearchResponseDto
     */
    'list'?: Array<ActionTargetDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ActionTargetDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ActionTargetSaveParams
 */
export interface ActionTargetSaveParams {
    /**
     * 
     * @type {string}
     * @memberof ActionTargetSaveParams
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActionTargetSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionType
 */
export interface ActionType {
    /**
     * 
     * @type {string}
     * @memberof ActionType
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActionType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionType
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionType
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionType
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionType
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface ActionTypeDto
 */
export interface ActionTypeDto {
    /**
     * 
     * @type {number}
     * @memberof ActionTypeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActionTypeDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionTypeDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActionTypeDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActionTypeDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActionTypeDto
     */
    'actionObjectId'?: number;
    /**
     * 
     * @type {ActionObjectDto}
     * @memberof ActionTypeDto
     */
    'actionObject'?: ActionObjectDto;
}
/**
 * 
 * @export
 * @interface ActionTypeDtoSearchResponseDto
 */
export interface ActionTypeDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ActionTypeDto>}
     * @memberof ActionTypeDtoSearchResponseDto
     */
    'list'?: Array<ActionTypeDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ActionTypeDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ActionTypeSaveParams
 */
export interface ActionTypeSaveParams {
    /**
     * 
     * @type {string}
     * @memberof ActionTypeSaveParams
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActionTypeSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface AlertDto
 */
export interface AlertDto {
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {AlertLevel}
     * @memberof AlertDto
     */
    'level'?: AlertLevel;
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'missionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AlertDto
     */
    'robotId'?: number | null;
    /**
     * 
     * @type {MissionDto}
     * @memberof AlertDto
     */
    'mission'?: MissionDto;
    /**
     * 
     * @type {UserDto}
     * @memberof AlertDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {RobotDto}
     * @memberof AlertDto
     */
    'robot'?: RobotDto;
}
/**
 * 
 * @export
 * @interface AlertDtoSearchResponseDto
 */
export interface AlertDtoSearchResponseDto {
    /**
     * 
     * @type {Array<AlertDto>}
     * @memberof AlertDtoSearchResponseDto
     */
    'list'?: Array<AlertDto> | null;
    /**
     * 
     * @type {number}
     * @memberof AlertDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AlertLevel = {
    提醒: '提醒',
    警告: '警告',
    严重: '严重'
} as const;

export type AlertLevel = typeof AlertLevel[keyof typeof AlertLevel];


/**
 * 
 * @export
 * @interface AlertRemoveParams
 */
export interface AlertRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof AlertRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ApiExceptionCode = {
    NUMBER_400: 400,
    NUMBER_401: 401,
    NUMBER_403: 403,
    NUMBER_404: 404,
    NUMBER_409: 409,
    NUMBER_500: 500
} as const;

export type ApiExceptionCode = typeof ApiExceptionCode[keyof typeof ApiExceptionCode];


/**
 * 
 * @export
 * @interface AuthorizeHeartbeatDto
 */
export interface AuthorizeHeartbeatDto {
    /**
     * 
     * @type {UserDto}
     * @memberof AuthorizeHeartbeatDto
     */
    'user'?: UserDto;
}
/**
 * 
 * @export
 * @interface AuthorizeLoginParams
 */
export interface AuthorizeLoginParams {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeLoginParams
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeLoginParams
     */
    'password': string;
    /**
     * 
     * @type {AuthorizeLoginPlatform}
     * @memberof AuthorizeLoginParams
     */
    'platform'?: AuthorizeLoginPlatform;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthorizeLoginPlatform = {
    Web: 'Web',
    DeviceControlApp: 'DeviceControlApp'
} as const;

export type AuthorizeLoginPlatform = typeof AuthorizeLoginPlatform[keyof typeof AuthorizeLoginPlatform];


/**
 * 
 * @export
 * @interface Building
 */
export interface Building {
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'principalId'?: number;
    /**
     * 
     * @type {User}
     * @memberof Building
     */
    'principal'?: User;
    /**
     * 
     * @type {number}
     * @memberof Building
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Building
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Building
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface BuildingDto
 */
export interface BuildingDto {
    /**
     * 
     * @type {number}
     * @memberof BuildingDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuildingDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BuildingDto
     */
    'principalId'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof BuildingDto
     */
    'principal'?: UserDto;
}
/**
 * 
 * @export
 * @interface BuildingDtoSearchResponseDto
 */
export interface BuildingDtoSearchResponseDto {
    /**
     * 
     * @type {Array<BuildingDto>}
     * @memberof BuildingDtoSearchResponseDto
     */
    'list'?: Array<BuildingDto> | null;
    /**
     * 
     * @type {number}
     * @memberof BuildingDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface BuildingSaveParams
 */
export interface BuildingSaveParams {
    /**
     * 
     * @type {string}
     * @memberof BuildingSaveParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BuildingSaveParams
     */
    'principalId': number;
    /**
     * 
     * @type {string}
     * @memberof BuildingSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuildingSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuildingSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface ChatHistoryDto
 */
export interface ChatHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryDto
     */
    'chatAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryDto
     */
    'senderName'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatHistoryDtoSearchResponseDto
 */
export interface ChatHistoryDtoSearchResponseDto {
    /**
     * 
     * @type {Array<ChatHistoryDto>}
     * @memberof ChatHistoryDtoSearchResponseDto
     */
    'list'?: Array<ChatHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface ChatHistorySaveParams
 */
export interface ChatHistorySaveParams {
    /**
     * 
     * @type {string}
     * @memberof ChatHistorySaveParams
     */
    'senderName': string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistorySaveParams
     */
    'chatAddress': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistorySaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChatHistorySaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistorySaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChatHistorySaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface DeepCameraPeripheral
 */
export interface DeepCameraPeripheral {
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'liveAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeepCameraPeripheral
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof DeepCameraPeripheral
     */
    'robot'?: Robot;
    /**
     * 
     * @type {PeripheralType}
     * @memberof DeepCameraPeripheral
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeepCameraPeripheral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeepCameraPeripheral
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeepCameraPeripheral
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface DoubleSpectrumCameraPeripheral
 */
export interface DoubleSpectrumCameraPeripheral {
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'liveAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'robot'?: Robot;
    /**
     * 
     * @type {PeripheralType}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DoubleSpectrumCameraPeripheral
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface Floor
 */
export interface Floor {
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'commandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'buildingId'?: number | null;
    /**
     * 
     * @type {Building}
     * @memberof Floor
     */
    'building'?: Building;
    /**
     * 
     * @type {Array<Spot>}
     * @memberof Floor
     */
    'spots'?: Array<Spot> | null;
    /**
     * 
     * @type {any}
     * @memberof Floor
     */
    'map'?: any;
    /**
     * 
     * @type {number}
     * @memberof Floor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Floor
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Floor
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface FloorDto
 */
export interface FloorDto {
    /**
     * 
     * @type {number}
     * @memberof FloorDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FloorDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'commandName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FloorDto
     */
    'buildingId'?: number | null;
    /**
     * 
     * @type {BuildingDto}
     * @memberof FloorDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {Array<SpotDto>}
     * @memberof FloorDto
     */
    'spots'?: Array<SpotDto> | null;
    /**
     * 
     * @type {MapDto}
     * @memberof FloorDto
     */
    'map'?: MapDto;
}
/**
 * 
 * @export
 * @interface FloorDtoSearchResponseDto
 */
export interface FloorDtoSearchResponseDto {
    /**
     * 
     * @type {Array<FloorDto>}
     * @memberof FloorDtoSearchResponseDto
     */
    'list'?: Array<FloorDto> | null;
    /**
     * 
     * @type {number}
     * @memberof FloorDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface FloorRemoveParams
 */
export interface FloorRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof FloorRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface FloorSaveParams
 */
export interface FloorSaveParams {
    /**
     * 
     * @type {string}
     * @memberof FloorSaveParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FloorSaveParams
     */
    'buildingId': number;
    /**
     * 
     * @type {string}
     * @memberof FloorSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FloorSaveParams
     */
    'commandName': string;
    /**
     * 
     * @type {boolean}
     * @memberof FloorSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FloorSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FloorSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FloorSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Female: 'Female',
    Male: 'Male'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface IMapPosition
 */
export interface IMapPosition {
    /**
     * 
     * @type {number}
     * @memberof IMapPosition
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IMapPosition
     */
    'y'?: number | null;
}
/**
 * 
 * @export
 * @interface MapCalculatePositionParams
 */
export interface MapCalculatePositionParams {
    /**
     * 
     * @type {number}
     * @memberof MapCalculatePositionParams
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapCalculatePositionParams
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof MapCalculatePositionParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface MapCalibrateParams
 */
export interface MapCalibrateParams {
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point1X': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point1Y': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point1RobotX': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point1RobotY': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point2X': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point2Y': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point2RobotX': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'point2RobotY': number;
    /**
     * 
     * @type {number}
     * @memberof MapCalibrateParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface MapDto
 */
export interface MapDto {
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MapDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MapDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MapDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MapDto
     */
    'url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MapDto
     */
    'isTranspose'?: boolean;
    /**
     * 
     * @type {FloorDto}
     * @memberof MapDto
     */
    'floor'?: FloorDto;
    /**
     * 
     * @type {string}
     * @memberof MapDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point1X'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point1Y'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point1RobotX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point1RobotY'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point2X'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point2Y'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point2RobotX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MapDto
     */
    'point2RobotY'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapDto
     */
    'isCalibrated'?: boolean;
}
/**
 * 
 * @export
 * @interface MapDtoSearchResponseDto
 */
export interface MapDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MapDto>}
     * @memberof MapDtoSearchResponseDto
     */
    'list'?: Array<MapDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MapDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MapRemoveParams
 */
export interface MapRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof MapRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface MapSaveParams
 */
export interface MapSaveParams {
    /**
     * 
     * @type {string}
     * @memberof MapSaveParams
     */
    'url': string;
    /**
     * 
     * @type {boolean}
     * @memberof MapSaveParams
     */
    'isTranspose'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MapSaveParams
     */
    'floorId': number;
    /**
     * 
     * @type {string}
     * @memberof MapSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MapSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MapSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MapSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface Mission
 */
export interface Mission {
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {MissionTemplate}
     * @memberof Mission
     */
    'missionTemplate'?: MissionTemplate;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'robotId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'currentRepeatCount'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof Mission
     */
    'robot'?: Robot;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'missionTemplateActionId'?: number | null;
    /**
     * 
     * @type {MissionTemplateAction}
     * @memberof Mission
     */
    'missionTemplateAction'?: MissionTemplateAction;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'positionX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'positionY'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'positionZ'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'floorId'?: number | null;
    /**
     * 
     * @type {Floor}
     * @memberof Mission
     */
    'floor'?: Floor;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'positionDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'lastReportTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'finishTime'?: string;
    /**
     * 
     * @type {MissionStatus}
     * @memberof Mission
     */
    'status'?: MissionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Mission
     */
    'isFinished'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Mission
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Mission
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Mission
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionDto
 */
export interface MissionDto {
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {MissionTemplateDto}
     * @memberof MissionDto
     */
    'missionTemplate'?: MissionTemplateDto;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'missionTemplateActionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'currentRepeatCount'?: number;
    /**
     * 
     * @type {MissionTemplateAction}
     * @memberof MissionDto
     */
    'missionTemplateAction'?: MissionTemplateAction;
    /**
     * 
     * @type {RobotDto}
     * @memberof MissionDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'positionX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'positionY'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'positionZ'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionDto
     */
    'floorId'?: number | null;
    /**
     * 
     * @type {FloorDto}
     * @memberof MissionDto
     */
    'floor'?: FloorDto;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'positionDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'lastReportTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionDto
     */
    'finishTime'?: string;
    /**
     * 
     * @type {MissionStatus}
     * @memberof MissionDto
     */
    'status'?: MissionStatus;
}
/**
 * 
 * @export
 * @interface MissionDtoSearchResponseDto
 */
export interface MissionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MissionDto>}
     * @memberof MissionDtoSearchResponseDto
     */
    'list'?: Array<MissionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MissionForceStopAllParams
 */
export interface MissionForceStopAllParams {
    /**
     * 
     * @type {number}
     * @memberof MissionForceStopAllParams
     */
    'robotId'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MissionFrom = {
    定时触发: '定时触发',
    手动下发: '手动下发'
} as const;

export type MissionFrom = typeof MissionFrom[keyof typeof MissionFrom];


/**
 * 
 * @export
 * @interface MissionLogDto
 */
export interface MissionLogDto {
    /**
     * 
     * @type {number}
     * @memberof MissionLogDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionLogDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'descriptions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionLogDto
     */
    'missionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionLogDto
     */
    'positionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionLogDto
     */
    'userId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MissionLogDto
     */
    'rawData'?: string | null;
    /**
     * 
     * @type {MissionFrom}
     * @memberof MissionLogDto
     */
    'from'?: MissionFrom;
    /**
     * 
     * @type {UserDto}
     * @memberof MissionLogDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {RobotPositionDto}
     * @memberof MissionLogDto
     */
    'position'?: RobotPositionDto;
    /**
     * 
     * @type {MissionDto}
     * @memberof MissionLogDto
     */
    'mission'?: MissionDto;
}
/**
 * 
 * @export
 * @interface MissionLogDtoSearchResponseDto
 */
export interface MissionLogDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MissionLogDto>}
     * @memberof MissionLogDtoSearchResponseDto
     */
    'list'?: Array<MissionLogDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionLogDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MissionPauseParams
 */
export interface MissionPauseParams {
    /**
     * 
     * @type {number}
     * @memberof MissionPauseParams
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface MissionResumeParams
 */
export interface MissionResumeParams {
    /**
     * 
     * @type {number}
     * @memberof MissionResumeParams
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface MissionScheduleDto
 */
export interface MissionScheduleDto {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {RobotDto}
     * @memberof MissionScheduleDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof MissionScheduleDto
     */
    'dayOfWeeks'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDto
     */
    'minute'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleDto
     */
    'time'?: string | null;
    /**
     * 
     * @type {MissionTemplateDto}
     * @memberof MissionScheduleDto
     */
    'missionTemplate'?: MissionTemplateDto;
}
/**
 * 
 * @export
 * @interface MissionScheduleDtoSearchResponseDto
 */
export interface MissionScheduleDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MissionScheduleDto>}
     * @memberof MissionScheduleDtoSearchResponseDto
     */
    'list'?: Array<MissionScheduleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MissionScheduleRemoveParams
 */
export interface MissionScheduleRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface MissionScheduleSaveParams
 */
export interface MissionScheduleSaveParams {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleSaveParams
     */
    'missionTemplateId': number;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleSaveParams
     */
    'robotId': number;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleSaveParams
     */
    'time': string;
    /**
     * 
     * @type {string}
     * @memberof MissionScheduleSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MissionScheduleSaveParams
     */
    'dayOfWeeks': Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionStartParams
 */
export interface MissionStartParams {
    /**
     * 
     * @type {number}
     * @memberof MissionStartParams
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionStartParams
     */
    'robotId'?: number;
    /**
     * 
     * @type {MissionFrom}
     * @memberof MissionStartParams
     */
    'from'?: MissionFrom;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MissionStatus = {
    未开始: '未开始',
    进行中: '进行中',
    任务完成: '任务完成',
    任务异常: '任务异常',
    任务暂停: '任务暂停',
    强制结束: '强制结束'
} as const;

export type MissionStatus = typeof MissionStatus[keyof typeof MissionStatus];


/**
 * 
 * @export
 * @interface MissionTemplate
 */
export interface MissionTemplate {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplate
     */
    'creatorId'?: number;
    /**
     * 
     * @type {User}
     * @memberof MissionTemplate
     */
    'creator'?: User;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplate
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplate
     */
    'repeatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplate
     */
    'robotId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplate
     */
    'autoAppendRecharge'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplate
     */
    'isTemporary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplate
     */
    'parameters'?: string | null;
    /**
     * 
     * @type {Robot}
     * @memberof MissionTemplate
     */
    'robot'?: Robot;
    /**
     * 
     * @type {Building}
     * @memberof MissionTemplate
     */
    'building'?: Building;
    /**
     * 
     * @type {Array<MissionTemplateAction>}
     * @memberof MissionTemplate
     */
    'missionTemplateActions'?: Array<MissionTemplateAction> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplate
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplate
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplate
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplate
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionTemplateAction
 */
export interface MissionTemplateAction {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateAction
     */
    'actionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateAction
     */
    'actionTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateAction
     */
    'spotId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateAction
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {MissionTemplate}
     * @memberof MissionTemplateAction
     */
    'missionTemplate'?: MissionTemplate;
    /**
     * 
     * @type {Spot}
     * @memberof MissionTemplateAction
     */
    'spot'?: Spot;
    /**
     * 
     * @type {ActionType}
     * @memberof MissionTemplateAction
     */
    'actionType'?: ActionType;
    /**
     * 
     * @type {Array<MissionTemplateActionToPeripheralCommand>}
     * @memberof MissionTemplateAction
     */
    'missionTemplateActionToPeripheralCommands'?: Array<MissionTemplateActionToPeripheralCommand> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateAction
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateAction
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateAction
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateAction
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateAction
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionTemplateActionDto
 */
export interface MissionTemplateActionDto {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionDto
     */
    'actionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionDto
     */
    'actionTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionDto
     */
    'spotId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionDto
     */
    'missionTemplateId'?: number;
    /**
     * 
     * @type {MissionTemplateDto}
     * @memberof MissionTemplateActionDto
     */
    'missionTemplate'?: MissionTemplateDto;
    /**
     * 
     * @type {SpotDto}
     * @memberof MissionTemplateActionDto
     */
    'spot'?: SpotDto;
    /**
     * 
     * @type {ActionTypeDto}
     * @memberof MissionTemplateActionDto
     */
    'actionType'?: ActionTypeDto;
    /**
     * 
     * @type {Array<MissionTemplateActionToPeripheralCommandDto>}
     * @memberof MissionTemplateActionDto
     */
    'missionTemplateActionToPeripheralCommands'?: Array<MissionTemplateActionToPeripheralCommandDto> | null;
}
/**
 * 
 * @export
 * @interface MissionTemplateActionDtoSearchResponseDto
 */
export interface MissionTemplateActionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MissionTemplateActionDto>}
     * @memberof MissionTemplateActionDtoSearchResponseDto
     */
    'list'?: Array<MissionTemplateActionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MissionTemplateActionSaveParams
 */
export interface MissionTemplateActionSaveParams {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionSaveParams
     */
    'actionName': string;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionSaveParams
     */
    'actionTypeId': number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionSaveParams
     */
    'spotId': number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionSaveParams
     */
    'missionTemplateId': number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionTemplateActionToPeripheralCommand
 */
export interface MissionTemplateActionToPeripheralCommand {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'peripheralAbilityCommandId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'missionTemplateActionId'?: number;
    /**
     * 
     * @type {PeripheralAbilityCommand}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'peripheralAbilityCommand'?: PeripheralAbilityCommand;
    /**
     * 
     * @type {MissionTemplateAction}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'missionTemplateAction'?: MissionTemplateAction;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionToPeripheralCommand
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionTemplateActionToPeripheralCommandDto
 */
export interface MissionTemplateActionToPeripheralCommandDto {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'peripheralAbilityCommandId'?: number;
    /**
     * 
     * @type {PeripheralAbilityCommandDto}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'peripheralAbilityCommand'?: PeripheralAbilityCommandDto;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'missionTemplateActionId'?: number;
    /**
     * 
     * @type {MissionTemplateActionDto}
     * @memberof MissionTemplateActionToPeripheralCommandDto
     */
    'missionTemplateAction'?: MissionTemplateActionDto;
}
/**
 * 
 * @export
 * @interface MissionTemplateBasicParams
 */
export interface MissionTemplateBasicParams {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateBasicParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateBasicParams
     */
    'buildingId': number;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateBasicParams
     */
    'isRepeat': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateBasicParams
     */
    'autoAppendRecharge': boolean;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateBasicParams
     */
    'repeatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateBasicParams
     */
    'robotId': number;
}
/**
 * 
 * @export
 * @interface MissionTemplateDto
 */
export interface MissionTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDto
     */
    'creatorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDto
     */
    'buildingId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDto
     */
    'repeatCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateDto
     */
    'autoAppendRecharge'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateDto
     */
    'isTemporary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateDto
     */
    'parameters'?: string | null;
    /**
     * 
     * @type {RobotDto}
     * @memberof MissionTemplateDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MissionTemplateDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {BuildingDto}
     * @memberof MissionTemplateDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {Array<MissionTemplateActionDto>}
     * @memberof MissionTemplateDto
     */
    'missionTemplateActions'?: Array<MissionTemplateActionDto> | null;
}
/**
 * 
 * @export
 * @interface MissionTemplateDtoSearchResponseDto
 */
export interface MissionTemplateDtoSearchResponseDto {
    /**
     * 
     * @type {Array<MissionTemplateDto>}
     * @memberof MissionTemplateDtoSearchResponseDto
     */
    'list'?: Array<MissionTemplateDto> | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface MissionTemplateSaveParams
 */
export interface MissionTemplateSaveParams {
    /**
     * 
     * @type {MissionTemplateBasicParams}
     * @memberof MissionTemplateSaveParams
     */
    'basicForm': MissionTemplateBasicParams;
    /**
     * 
     * @type {Array<MissionTemplateStepParams>}
     * @memberof MissionTemplateSaveParams
     */
    'steps': Array<MissionTemplateStepParams>;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface MissionTemplateStepParams
 */
export interface MissionTemplateStepParams {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateStepParams
     */
    'actionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateStepParams
     */
    'actionTypeId'?: number;
    /**
     * 
     * @type {Array<MissionTemplateStepPeripheralsParams>}
     * @memberof MissionTemplateStepParams
     */
    'peripherals'?: Array<MissionTemplateStepPeripheralsParams> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MissionTemplateStepParams
     */
    'spotIdList'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface MissionTemplateStepPeripheralsParams
 */
export interface MissionTemplateStepPeripheralsParams {
    /**
     * 
     * @type {string}
     * @memberof MissionTemplateStepPeripheralsParams
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateStepPeripheralsParams
     */
    'commandId'?: number;
}
/**
 * 
 * @export
 * @interface ModelMap
 */
export interface ModelMap {
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    'url'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'floorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMap
     */
    'isTranspose'?: boolean;
    /**
     * 
     * @type {Floor}
     * @memberof ModelMap
     */
    'floor'?: Floor;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point1X'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point1Y'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point1RobotX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point1RobotY'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point2X'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point2Y'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point2RobotX'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'point2RobotY'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMap
     */
    'isCalibrated'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelMap
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelMap
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelMap
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface OperateResultDto
 */
export interface OperateResultDto {
    /**
     * 
     * @type {ApiExceptionCode}
     * @memberof OperateResultDto
     */
    'code'?: ApiExceptionCode;
    /**
     * 
     * @type {boolean}
     * @memberof OperateResultDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OperateResultDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof OperateResultDto
     */
    'info'?: any | null;
}
/**
 * 
 * @export
 * @interface PanoramaCameraPeripheral
 */
export interface PanoramaCameraPeripheral {
    /**
     * 
     * @type {Array<string>}
     * @memberof PanoramaCameraPeripheral
     */
    'liveAddressList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PanoramaCameraPeripheral
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof PanoramaCameraPeripheral
     */
    'robot'?: Robot;
    /**
     * 
     * @type {PeripheralType}
     * @memberof PanoramaCameraPeripheral
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PanoramaCameraPeripheral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheral
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramaCameraPeripheral
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PanoramaCameraPeripheralDto
 */
export interface PanoramaCameraPeripheralDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PanoramaCameraPeripheralDto
     */
    'liveAddressList'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof PanoramaCameraPeripheralDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PanoramaCameraPeripheralDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PanoramaCameraPeripheralDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {PeripheralType}
     * @memberof PanoramaCameraPeripheralDto
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'brand'?: string | null;
    /**
     * 
     * @type {RobotDto}
     * @memberof PanoramaCameraPeripheralDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {string}
     * @memberof PanoramaCameraPeripheralDto
     */
    'liveAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface Peripheral
 */
export interface Peripheral {
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Peripheral
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof Peripheral
     */
    'robot'?: Robot;
    /**
     * 
     * @type {PeripheralType}
     * @memberof Peripheral
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Peripheral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Peripheral
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Peripheral
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralAbility
 */
export interface PeripheralAbility {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbility
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbility
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof PeripheralAbility
     */
    'robot'?: Robot;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbility
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbility
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbility
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbility
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbility
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityCommand
 */
export interface PeripheralAbilityCommand {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommand
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommand
     */
    'peripheralAbilityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommand
     */
    'commandName'?: string | null;
    /**
     * 
     * @type {PeripheralAbility}
     * @memberof PeripheralAbilityCommand
     */
    'peripheralAbility'?: PeripheralAbility;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommand
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommand
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommand
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommand
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommand
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommand
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityCommandDto
 */
export interface PeripheralAbilityCommandDto {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandDto
     */
    'peripheralAbilityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandDto
     */
    'commandName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandDto
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {PeripheralAbilityDto}
     * @memberof PeripheralAbilityCommandDto
     */
    'peripheralAbility'?: PeripheralAbilityDto;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityCommandDtoSearchResponseDto
 */
export interface PeripheralAbilityCommandDtoSearchResponseDto {
    /**
     * 
     * @type {Array<PeripheralAbilityCommandDto>}
     * @memberof PeripheralAbilityCommandDtoSearchResponseDto
     */
    'list'?: Array<PeripheralAbilityCommandDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityCommandSaveParams
 */
export interface PeripheralAbilityCommandSaveParams {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'commandName': string;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'peripheralAbilityId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'isDefault': boolean;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityDto
 */
export interface PeripheralAbilityDto {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityDto
     */
    'robotId'?: number;
}
/**
 * 
 * @export
 * @interface PeripheralAbilityDtoSearchResponseDto
 */
export interface PeripheralAbilityDtoSearchResponseDto {
    /**
     * 
     * @type {Array<PeripheralAbilityDto>}
     * @memberof PeripheralAbilityDtoSearchResponseDto
     */
    'list'?: Array<PeripheralAbilityDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PeripheralAbilitySaveParams
 */
export interface PeripheralAbilitySaveParams {
    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilitySaveParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilitySaveParams
     */
    'robotId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilitySaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilitySaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilitySaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilitySaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralDto
 */
export interface PeripheralDto {
    /**
     * 
     * @type {number}
     * @memberof PeripheralDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {PeripheralType}
     * @memberof PeripheralDto
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'brand'?: string | null;
    /**
     * 
     * @type {RobotDto}
     * @memberof PeripheralDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PeripheralDto
     */
    'liveAddressList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralDto
     */
    'liveAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface PeripheralDtoSearchResponseDto
 */
export interface PeripheralDtoSearchResponseDto {
    /**
     * 
     * @type {Array<PeripheralDto>}
     * @memberof PeripheralDtoSearchResponseDto
     */
    'list'?: Array<PeripheralDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PeripheralHistoryDto
 */
export interface PeripheralHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralHistoryDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryDto
     */
    'peripheralId'?: number;
    /**
     * 
     * @type {PeripheralDto}
     * @memberof PeripheralHistoryDto
     */
    'peripheral'?: PeripheralDto;
}
/**
 * 
 * @export
 * @interface PeripheralHistoryDtoSearchResponseDto
 */
export interface PeripheralHistoryDtoSearchResponseDto {
    /**
     * 
     * @type {Array<PeripheralHistoryDto>}
     * @memberof PeripheralHistoryDtoSearchResponseDto
     */
    'list'?: Array<PeripheralHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface PeripheralHistoryRemoveParams
 */
export interface PeripheralHistoryRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PeripheralHistorySaveParams
 */
export interface PeripheralHistorySaveParams {
    /**
     * 
     * @type {string}
     * @memberof PeripheralHistorySaveParams
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistorySaveParams
     */
    'peripheralId': number;
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistorySaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralHistorySaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralHistorySaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface PeripheralSaveParams
 */
export interface PeripheralSaveParams {
    /**
     * 
     * @type {string}
     * @memberof PeripheralSaveParams
     */
    'name': string;
    /**
     * 
     * @type {PeripheralType}
     * @memberof PeripheralSaveParams
     */
    'type': PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof PeripheralSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralSaveParams
     */
    'liveAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralSaveParams
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeripheralSaveParams
     */
    'brand'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PeripheralSaveParams
     */
    'liveAddressList'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof PeripheralSaveParams
     */
    'robotId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PeripheralSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PeripheralSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PeripheralType = {
    默认外设: '默认外设',
    白光相机: '白光相机',
    双光谱相机: '双光谱相机',
    全景相机: '全景相机',
    云台: '云台',
    深度相机: '深度相机',
    梯控模块: '梯控模块'
} as const;

export type PeripheralType = typeof PeripheralType[keyof typeof PeripheralType];


/**
 * 
 * @export
 * @interface PositionBriefSearchItemDto
 */
export interface PositionBriefSearchItemDto {
    /**
     * 
     * @type {string}
     * @memberof PositionBriefSearchItemDto
     */
    'key'?: string | null;
    /**
     * 
     * @type {RobotPositionHistoryDto}
     * @memberof PositionBriefSearchItemDto
     */
    'firstPosition'?: RobotPositionHistoryDto;
    /**
     * 
     * @type {RobotPositionHistoryDto}
     * @memberof PositionBriefSearchItemDto
     */
    'lastPosition'?: RobotPositionHistoryDto;
}
/**
 * 
 * @export
 * @interface PositionSaveParams
 */
export interface PositionSaveParams {
    /**
     * 
     * @type {number}
     * @memberof PositionSaveParams
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof PositionSaveParams
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof PositionSaveParams
     */
    'z': number;
    /**
     * 
     * @type {string}
     * @memberof PositionSaveParams
     */
    's'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PositionSaveParams
     */
    'floorId': number;
    /**
     * 
     * @type {number}
     * @memberof PositionSaveParams
     */
    'robotId': number;
}
/**
 * 
 * @export
 * @interface PositionSearchHistoryListByKeyResponse
 */
export interface PositionSearchHistoryListByKeyResponse {
    /**
     * 
     * @type {Array<IMapPosition>}
     * @memberof PositionSearchHistoryListByKeyResponse
     */
    'list'?: Array<IMapPosition> | null;
    /**
     * 
     * @type {any}
     * @memberof PositionSearchHistoryListByKeyResponse
     */
    'map'?: any;
}
/**
 * 
 * @export
 * @interface Robot
 */
export interface Robot {
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'principalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'battery'?: number;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'robotError'?: string | null;
    /**
     * 
     * @type {User}
     * @memberof Robot
     */
    'principal'?: User;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'fireControlIpHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'fireControlIpPort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'chatIpHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'chatIpPort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'ipHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'ipPort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'buildingId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'robotCode'?: string | null;
    /**
     * 
     * @type {Array<Mission>}
     * @memberof Robot
     */
    'missions'?: Array<Mission> | null;
    /**
     * 
     * @type {RobotStatus}
     * @memberof Robot
     */
    'robotStatus'?: RobotStatus;
    /**
     * 
     * @type {Building}
     * @memberof Robot
     */
    'building'?: Building;
    /**
     * 
     * @type {Array<Peripheral>}
     * @memberof Robot
     */
    'peripherals'?: Array<Peripheral> | null;
    /**
     * 
     * @type {RobotPosition}
     * @memberof Robot
     */
    'robotPosition'?: RobotPosition;
    /**
     * 
     * @type {Array<RobotPositionHistory>}
     * @memberof Robot
     */
    'robotPositionHistories'?: Array<RobotPositionHistory> | null;
    /**
     * 
     * @type {PanoramaCameraPeripheral}
     * @memberof Robot
     */
    'panoramaCameraPeripheral'?: PanoramaCameraPeripheral;
    /**
     * 
     * @type {DeepCameraPeripheral}
     * @memberof Robot
     */
    'deepCameraPeripheral'?: DeepCameraPeripheral;
    /**
     * 
     * @type {DoubleSpectrumCameraPeripheral}
     * @memberof Robot
     */
    'doubleSpectrumCameraPeripheral'?: DoubleSpectrumCameraPeripheral;
    /**
     * 
     * @type {WhiteLightCameraPeripheral}
     * @memberof Robot
     */
    'whiteLightCameraPeripheral'?: WhiteLightCameraPeripheral;
    /**
     * 
     * @type {number}
     * @memberof Robot
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Robot
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Robot
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface RobotDto
 */
export interface RobotDto {
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'principalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'battery'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'robotError'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'fireControlIpHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'fireControlIpPort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'chatIpHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'chatIpPort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'ipHost'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'ipPort'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'buildingId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RobotDto
     */
    'robotCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDto
     */
    'missionId'?: number | null;
    /**
     * 
     * @type {MissionDto}
     * @memberof RobotDto
     */
    'mission'?: MissionDto;
    /**
     * 
     * @type {RobotStatus}
     * @memberof RobotDto
     */
    'robotStatus'?: RobotStatus;
    /**
     * 
     * @type {BuildingDto}
     * @memberof RobotDto
     */
    'building'?: BuildingDto;
    /**
     * 
     * @type {Array<PeripheralDto>}
     * @memberof RobotDto
     */
    'peripherals'?: Array<PeripheralDto> | null;
    /**
     * 
     * @type {UserDto}
     * @memberof RobotDto
     */
    'principal'?: UserDto;
    /**
     * 
     * @type {RobotPositionDto}
     * @memberof RobotDto
     */
    'robotPosition'?: RobotPositionDto;
    /**
     * 
     * @type {Array<RobotPositionHistory>}
     * @memberof RobotDto
     */
    'robotPositionHistories'?: Array<RobotPositionHistory> | null;
}
/**
 * 
 * @export
 * @interface RobotDtoSearchResponseDto
 */
export interface RobotDtoSearchResponseDto {
    /**
     * 
     * @type {Array<RobotDto>}
     * @memberof RobotDtoSearchResponseDto
     */
    'list'?: Array<RobotDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RobotDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RobotFunctionDto
 */
export interface RobotFunctionDto {
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {RobotDto}
     * @memberof RobotFunctionDto
     */
    'robot'?: RobotDto;
}
/**
 * 
 * @export
 * @interface RobotFunctionDtoSearchResponseDto
 */
export interface RobotFunctionDtoSearchResponseDto {
    /**
     * 
     * @type {Array<RobotFunctionDto>}
     * @memberof RobotFunctionDtoSearchResponseDto
     */
    'list'?: Array<RobotFunctionDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RobotFunctionRemoveParams
 */
export interface RobotFunctionRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface RobotFunctionSaveParams
 */
export interface RobotFunctionSaveParams {
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionSaveParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionSaveParams
     */
    'robotId': number;
    /**
     * 
     * @type {string}
     * @memberof RobotFunctionSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface RobotMessageDto
 */
export interface RobotMessageDto {
    /**
     * 
     * @type {number}
     * @memberof RobotMessageDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotMessageDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotMessageDto
     */
    'robotId'?: number | null;
    /**
     * 
     * @type {Robot}
     * @memberof RobotMessageDto
     */
    'robot'?: Robot;
    /**
     * 
     * @type {RobotMessageStatus}
     * @memberof RobotMessageDto
     */
    'status'?: RobotMessageStatus;
    /**
     * 
     * @type {number}
     * @memberof RobotMessageDto
     */
    'retryCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {RobotMessageFrom}
     * @memberof RobotMessageDto
     */
    'from'?: RobotMessageFrom;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageDto
     */
    'packageId'?: string | null;
}
/**
 * 
 * @export
 * @interface RobotMessageDtoSearchResponseDto
 */
export interface RobotMessageDtoSearchResponseDto {
    /**
     * 
     * @type {Array<RobotMessageDto>}
     * @memberof RobotMessageDtoSearchResponseDto
     */
    'list'?: Array<RobotMessageDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RobotMessageDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RobotMessageExportParams
 */
export interface RobotMessageExportParams {
    /**
     * 
     * @type {string}
     * @memberof RobotMessageExportParams
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageExportParams
     */
    'to'?: string;
    /**
     * 
     * @type {number}
     * @memberof RobotMessageExportParams
     */
    'robotId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RobotMessageExportParams
     */
    'packageId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RobotMessageFrom = {
    机器人: '机器人',
    平台: '平台'
} as const;

export type RobotMessageFrom = typeof RobotMessageFrom[keyof typeof RobotMessageFrom];


/**
 * 
 * @export
 * @interface RobotMessageRemoveParams
 */
export interface RobotMessageRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof RobotMessageRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RobotMessageStatus = {
    待发送: '待发送',
    等待回执: '等待回执',
    已完成: '已完成',
    已放弃: '已放弃'
} as const;

export type RobotMessageStatus = typeof RobotMessageStatus[keyof typeof RobotMessageStatus];


/**
 * 
 * @export
 * @interface RobotPosition
 */
export interface RobotPosition {
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'z'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPosition
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPosition
     */
    's'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'robotId'?: number;
    /**
     * 
     * @type {Floor}
     * @memberof RobotPosition
     */
    'floor'?: Floor;
    /**
     * 
     * @type {number}
     * @memberof RobotPosition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPosition
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPosition
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPosition
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotPosition
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface RobotPositionDto
 */
export interface RobotPositionDto {
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotPositionDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'z'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionDto
     */
    's'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {FloorDto}
     * @memberof RobotPositionDto
     */
    'floor'?: FloorDto;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionDto
     */
    'key'?: string | null;
    /**
     * 
     * @type {RobotDto}
     * @memberof RobotPositionDto
     */
    'robot'?: RobotDto;
}
/**
 * 
 * @export
 * @interface RobotPositionHistory
 */
export interface RobotPositionHistory {
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'z'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistory
     */
    's'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistory
     */
    'key'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof RobotPositionHistory
     */
    'robot'?: Robot;
    /**
     * 
     * @type {Floor}
     * @memberof RobotPositionHistory
     */
    'floor'?: Floor;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistory
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistory
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistory
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotPositionHistory
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface RobotPositionHistoryDto
 */
export interface RobotPositionHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistoryDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistoryDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistoryDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotPositionHistoryDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'z'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistoryDto
     */
    's'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'floorId'?: number;
    /**
     * 
     * @type {FloorDto}
     * @memberof RobotPositionHistoryDto
     */
    'floor'?: FloorDto;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {RobotDto}
     * @memberof RobotPositionHistoryDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {string}
     * @memberof RobotPositionHistoryDto
     */
    'key'?: string | null;
}
/**
 * 
 * @export
 * @interface RobotPositionHistoryDtoSearchResponseDto
 */
export interface RobotPositionHistoryDtoSearchResponseDto {
    /**
     * 
     * @type {Array<RobotPositionHistoryDto>}
     * @memberof RobotPositionHistoryDtoSearchResponseDto
     */
    'list'?: Array<RobotPositionHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RobotPositionHistoryDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RobotSaveParams
 */
export interface RobotSaveParams {
    /**
     * 
     * @type {string}
     * @memberof RobotSaveParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RobotSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'principalId': number;
    /**
     * 
     * @type {string}
     * @memberof RobotSaveParams
     */
    'fireControlIpHost': string;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'fireControlIpPort': number;
    /**
     * 
     * @type {string}
     * @memberof RobotSaveParams
     */
    'chatIpHost': string;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'chatIpPort': number;
    /**
     * 
     * @type {string}
     * @memberof RobotSaveParams
     */
    'ipHost': string;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'ipPort': number;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'buildingId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobotSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RobotStatus = {
    正常: '运行正常',
    异常: '运行异常'
} as const;

export type RobotStatus = typeof RobotStatus[keyof typeof RobotStatus];


/**
 * 
 * @export
 * @interface RobotStatusHistoryDto
 */
export interface RobotStatusHistoryDto {
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RobotStatusHistoryDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RobotStatusHistoryDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RobotStatusHistoryDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotStatusHistoryDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryDto
     */
    'robotId'?: number;
    /**
     * 
     * @type {RobotDto}
     * @memberof RobotStatusHistoryDto
     */
    'robot'?: RobotDto;
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryDto
     */
    'battery'?: number | null;
}
/**
 * 
 * @export
 * @interface RobotStatusHistoryDtoSearchResponseDto
 */
export interface RobotStatusHistoryDtoSearchResponseDto {
    /**
     * 
     * @type {Array<RobotStatusHistoryDto>}
     * @memberof RobotStatusHistoryDtoSearchResponseDto
     */
    'list'?: Array<RobotStatusHistoryDto> | null;
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface RobotStatusHistoryRemoveParams
 */
export interface RobotStatusHistoryRemoveParams {
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryRemoveParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface RobotStatusHistorySaveParams
 */
export interface RobotStatusHistorySaveParams {
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistorySaveParams
     */
    'battery': number;
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistorySaveParams
     */
    'robotId': number;
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistorySaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RobotStatusHistorySaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobotStatusHistorySaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SorterOrder = {
    Desc: 'Desc',
    Asc: 'Asc'
} as const;

export type SorterOrder = typeof SorterOrder[keyof typeof SorterOrder];


/**
 * 
 * @export
 * @interface Spot
 */
export interface Spot {
    /**
     * 
     * @type {number}
     * @memberof Spot
     */
    'floorId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Spot
     */
    'spotTypeId'?: number;
    /**
     * 
     * @type {SpotType}
     * @memberof Spot
     */
    'spotType'?: SpotType;
    /**
     * 
     * @type {Floor}
     * @memberof Spot
     */
    'floor'?: Floor;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'commandName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Spot
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Spot
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Spot
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface SpotDto
 */
export interface SpotDto {
    /**
     * 
     * @type {number}
     * @memberof SpotDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpotDto
     */
    'floorId'?: number | null;
    /**
     * 
     * @type {FloorDto}
     * @memberof SpotDto
     */
    'floor'?: FloorDto;
    /**
     * 
     * @type {number}
     * @memberof SpotDto
     */
    'spotTypeId'?: number;
    /**
     * 
     * @type {SpotTypeDto}
     * @memberof SpotDto
     */
    'spotType'?: SpotTypeDto;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotDto
     */
    'commandName'?: string | null;
}
/**
 * 
 * @export
 * @interface SpotDtoSearchResponseDto
 */
export interface SpotDtoSearchResponseDto {
    /**
     * 
     * @type {Array<SpotDto>}
     * @memberof SpotDtoSearchResponseDto
     */
    'list'?: Array<SpotDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SpotDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SpotSaveParams
 */
export interface SpotSaveParams {
    /**
     * 
     * @type {string}
     * @memberof SpotSaveParams
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SpotSaveParams
     */
    'floorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SpotSaveParams
     */
    'spotTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotSaveParams
     */
    'commandName': string;
    /**
     * 
     * @type {string}
     * @memberof SpotSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpotSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpotSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface SpotType
 */
export interface SpotType {
    /**
     * 
     * @type {string}
     * @memberof SpotType
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpotType
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotType
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotType
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotType
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotType
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface SpotTypeDto
 */
export interface SpotTypeDto {
    /**
     * 
     * @type {number}
     * @memberof SpotTypeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SpotTypeDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SpotTypeDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpotTypeDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpotTypeDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface SpotTypeDtoSearchResponseDto
 */
export interface SpotTypeDtoSearchResponseDto {
    /**
     * 
     * @type {Array<SpotTypeDto>}
     * @memberof SpotTypeDtoSearchResponseDto
     */
    'list'?: Array<SpotTypeDto> | null;
    /**
     * 
     * @type {number}
     * @memberof SpotTypeDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface SpotTypeSaveParams
 */
export interface SpotTypeSaveParams {
    /**
     * 
     * @type {string}
     * @memberof SpotTypeSaveParams
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SpotTypeSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'body'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'expiredTime'?: string;
    /**
     * 
     * @type {User}
     * @memberof Token
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof Token
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Token
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof User
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'userRoleId'?: number;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'userRole'?: UserRole;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof UserDto
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'userRoleId'?: number;
    /**
     * 
     * @type {UserRoleDto}
     * @memberof UserDto
     */
    'userRole'?: UserRoleDto;
}
/**
 * 
 * @export
 * @interface UserDtoSearchResponseDto
 */
export interface UserDtoSearchResponseDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UserDtoSearchResponseDto
     */
    'list'?: Array<UserDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRole
     */
    'isReceiveNotification'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRole
     */
    'userRolePermissions'?: Array<string> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserRole
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRole
     */
    'isDelete'?: boolean;
}
/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {number}
     * @memberof UserRoleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleDto
     */
    'isReceiveNotification'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleDto
     */
    'userRolePermissions'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserRoleDtoSearchResponseDto
 */
export interface UserRoleDtoSearchResponseDto {
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof UserRoleDtoSearchResponseDto
     */
    'list'?: Array<UserRoleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof UserRoleDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface UserRoleEditPermissionParams
 */
export interface UserRoleEditPermissionParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoleEditPermissionParams
     */
    'permissions'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UserRoleEditPermissionParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface UserRoleSaveParams
 */
export interface UserRoleSaveParams {
    /**
     * 
     * @type {string}
     * @memberof UserRoleSaveParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleSaveParams
     */
    'remark'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleSaveParams
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleSaveParams
     */
    'isReceiveNotification'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserRoleSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoleSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSaveParams
 */
export interface UserSaveParams {
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    'password': string;
    /**
     * 
     * @type {Gender}
     * @memberof UserSaveParams
     */
    'gender': Gender;
    /**
     * 
     * @type {string}
     * @memberof UserSaveParams
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    'isDelete': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserSaveParams
     */
    'userRoleId': number;
    /**
     * 
     * @type {number}
     * @memberof UserSaveParams
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaveParams
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface VideoRecordDto
 */
export interface VideoRecordDto {
    /**
     * 
     * @type {number}
     * @memberof VideoRecordDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VideoRecordDto
     */
    'isDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'beginTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoRecordDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VideoRecordDto
     */
    'panoramaCameraPeripheralId'?: number;
    /**
     * 
     * @type {PanoramaCameraPeripheralDto}
     * @memberof VideoRecordDto
     */
    'panoramaCameraPeripheralDto'?: PanoramaCameraPeripheralDto;
}
/**
 * 
 * @export
 * @interface VideoRecordDtoSearchResponseDto
 */
export interface VideoRecordDtoSearchResponseDto {
    /**
     * 
     * @type {Array<VideoRecordDto>}
     * @memberof VideoRecordDtoSearchResponseDto
     */
    'list'?: Array<VideoRecordDto> | null;
    /**
     * 
     * @type {number}
     * @memberof VideoRecordDtoSearchResponseDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface VideoRecordStartRecordParams
 */
export interface VideoRecordStartRecordParams {
    /**
     * 
     * @type {number}
     * @memberof VideoRecordStartRecordParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface VideoRecordStopRecordParams
 */
export interface VideoRecordStopRecordParams {
    /**
     * 
     * @type {number}
     * @memberof VideoRecordStopRecordParams
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface WhiteLightCameraPeripheral
 */
export interface WhiteLightCameraPeripheral {
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'liveAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WhiteLightCameraPeripheral
     */
    'robotId'?: number;
    /**
     * 
     * @type {Robot}
     * @memberof WhiteLightCameraPeripheral
     */
    'robot'?: Robot;
    /**
     * 
     * @type {PeripheralType}
     * @memberof WhiteLightCameraPeripheral
     */
    'type'?: PeripheralType;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'remark'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WhiteLightCameraPeripheral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'deletedTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WhiteLightCameraPeripheral
     */
    'restoredTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WhiteLightCameraPeripheral
     */
    'isDelete'?: boolean;
}

/**
 * ActionObjectApi - axios parameter creator
 * @export
 */
export const ActionObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionObjectFindGet', 'id', id)
            const localVarPath = `/ActionObject/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActionObjectSaveParams} [actionObjectSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectSavePost: async (actionObjectSaveParams?: ActionObjectSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ActionObject/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionObjectSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('actionObjectSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('actionObjectSearchGet', 'ps', ps)
            const localVarPath = `/ActionObject/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionObjectApi - functional programming interface
 * @export
 */
export const ActionObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionObjectFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionObjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionObjectFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActionObjectSaveParams} [actionObjectSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionObjectSavePost(actionObjectSaveParams?: ActionObjectSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionObjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionObjectSavePost(actionObjectSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionObjectSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionObjectDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionObjectSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionObjectApi - factory interface
 * @export
 */
export const ActionObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionObjectApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectFindGet(id: number, options?: any): AxiosPromise<ActionObjectDto> {
            return localVarFp.actionObjectFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActionObjectSaveParams} [actionObjectSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectSavePost(actionObjectSaveParams?: ActionObjectSaveParams, options?: any): AxiosPromise<ActionObjectDto> {
            return localVarFp.actionObjectSavePost(actionObjectSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionObjectSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ActionObjectDtoSearchResponseDto> {
            return localVarFp.actionObjectSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionObjectFindGet operation in ActionObjectApi.
 * @export
 * @interface ActionObjectApiActionObjectFindGetRequest
 */
export interface ActionObjectApiActionObjectFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionObjectApiActionObjectFindGet
     */
    readonly id: number
}

/**
 * Request parameters for actionObjectSavePost operation in ActionObjectApi.
 * @export
 * @interface ActionObjectApiActionObjectSavePostRequest
 */
export interface ActionObjectApiActionObjectSavePostRequest {
    /**
     * 
     * @type {ActionObjectSaveParams}
     * @memberof ActionObjectApiActionObjectSavePost
     */
    readonly actionObjectSaveParams?: ActionObjectSaveParams
}

/**
 * Request parameters for actionObjectSearchGet operation in ActionObjectApi.
 * @export
 * @interface ActionObjectApiActionObjectSearchGetRequest
 */
export interface ActionObjectApiActionObjectSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ActionObjectApiActionObjectSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * ActionObjectApi - object-oriented interface
 * @export
 * @class ActionObjectApi
 * @extends {BaseAPI}
 */
export class ActionObjectApi extends BaseAPI {
    /**
     * 
     * @param {ActionObjectApiActionObjectFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionObjectApi
     */
    public actionObjectFindGet(requestParameters: ActionObjectApiActionObjectFindGetRequest, options?: AxiosRequestConfig) {
        return ActionObjectApiFp(this.configuration).actionObjectFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionObjectApiActionObjectSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionObjectApi
     */
    public actionObjectSavePost(requestParameters: ActionObjectApiActionObjectSavePostRequest = {}, options?: AxiosRequestConfig) {
        return ActionObjectApiFp(this.configuration).actionObjectSavePost(requestParameters.actionObjectSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionObjectApiActionObjectSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionObjectApi
     */
    public actionObjectSearchGet(requestParameters: ActionObjectApiActionObjectSearchGetRequest, options?: AxiosRequestConfig) {
        return ActionObjectApiFp(this.configuration).actionObjectSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActionTargetApi - axios parameter creator
 * @export
 */
export const ActionTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionTargetFindGet', 'id', id)
            const localVarPath = `/ActionTarget/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActionTargetSaveParams} [actionTargetSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetSavePost: async (actionTargetSaveParams?: ActionTargetSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ActionTarget/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionTargetSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('actionTargetSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('actionTargetSearchGet', 'ps', ps)
            const localVarPath = `/ActionTarget/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionTargetApi - functional programming interface
 * @export
 */
export const ActionTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTargetFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTargetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTargetFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActionTargetSaveParams} [actionTargetSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTargetSavePost(actionTargetSaveParams?: ActionTargetSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTargetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTargetSavePost(actionTargetSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTargetSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTargetDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTargetSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionTargetApi - factory interface
 * @export
 */
export const ActionTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionTargetApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetFindGet(id: number, options?: any): AxiosPromise<ActionTargetDto> {
            return localVarFp.actionTargetFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActionTargetSaveParams} [actionTargetSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetSavePost(actionTargetSaveParams?: ActionTargetSaveParams, options?: any): AxiosPromise<ActionTargetDto> {
            return localVarFp.actionTargetSavePost(actionTargetSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTargetSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ActionTargetDtoSearchResponseDto> {
            return localVarFp.actionTargetSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionTargetFindGet operation in ActionTargetApi.
 * @export
 * @interface ActionTargetApiActionTargetFindGetRequest
 */
export interface ActionTargetApiActionTargetFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionTargetApiActionTargetFindGet
     */
    readonly id: number
}

/**
 * Request parameters for actionTargetSavePost operation in ActionTargetApi.
 * @export
 * @interface ActionTargetApiActionTargetSavePostRequest
 */
export interface ActionTargetApiActionTargetSavePostRequest {
    /**
     * 
     * @type {ActionTargetSaveParams}
     * @memberof ActionTargetApiActionTargetSavePost
     */
    readonly actionTargetSaveParams?: ActionTargetSaveParams
}

/**
 * Request parameters for actionTargetSearchGet operation in ActionTargetApi.
 * @export
 * @interface ActionTargetApiActionTargetSearchGetRequest
 */
export interface ActionTargetApiActionTargetSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ActionTargetApiActionTargetSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * ActionTargetApi - object-oriented interface
 * @export
 * @class ActionTargetApi
 * @extends {BaseAPI}
 */
export class ActionTargetApi extends BaseAPI {
    /**
     * 
     * @param {ActionTargetApiActionTargetFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTargetApi
     */
    public actionTargetFindGet(requestParameters: ActionTargetApiActionTargetFindGetRequest, options?: AxiosRequestConfig) {
        return ActionTargetApiFp(this.configuration).actionTargetFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionTargetApiActionTargetSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTargetApi
     */
    public actionTargetSavePost(requestParameters: ActionTargetApiActionTargetSavePostRequest = {}, options?: AxiosRequestConfig) {
        return ActionTargetApiFp(this.configuration).actionTargetSavePost(requestParameters.actionTargetSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionTargetApiActionTargetSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTargetApi
     */
    public actionTargetSearchGet(requestParameters: ActionTargetApiActionTargetSearchGetRequest, options?: AxiosRequestConfig) {
        return ActionTargetApiFp(this.configuration).actionTargetSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActionTypeApi - axios parameter creator
 * @export
 */
export const ActionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('actionTypeFindGet', 'id', id)
            const localVarPath = `/ActionType/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActionTypeSaveParams} [actionTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeSavePost: async (actionTypeSaveParams?: ActionTypeSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ActionType/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionTypeSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('actionTypeSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('actionTypeSearchGet', 'ps', ps)
            const localVarPath = `/ActionType/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionTypeApi - functional programming interface
 * @export
 */
export const ActionTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypeFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypeFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActionTypeSaveParams} [actionTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypeSavePost(actionTypeSaveParams?: ActionTypeSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypeSavePost(actionTypeSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionTypeSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionTypeDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionTypeSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActionTypeApi - factory interface
 * @export
 */
export const ActionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeFindGet(id: number, options?: any): AxiosPromise<ActionTypeDto> {
            return localVarFp.actionTypeFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActionTypeSaveParams} [actionTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeSavePost(actionTypeSaveParams?: ActionTypeSaveParams, options?: any): AxiosPromise<ActionTypeDto> {
            return localVarFp.actionTypeSavePost(actionTypeSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionTypeSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ActionTypeDtoSearchResponseDto> {
            return localVarFp.actionTypeSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for actionTypeFindGet operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypeFindGetRequest
 */
export interface ActionTypeApiActionTypeFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionTypeApiActionTypeFindGet
     */
    readonly id: number
}

/**
 * Request parameters for actionTypeSavePost operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypeSavePostRequest
 */
export interface ActionTypeApiActionTypeSavePostRequest {
    /**
     * 
     * @type {ActionTypeSaveParams}
     * @memberof ActionTypeApiActionTypeSavePost
     */
    readonly actionTypeSaveParams?: ActionTypeSaveParams
}

/**
 * Request parameters for actionTypeSearchGet operation in ActionTypeApi.
 * @export
 * @interface ActionTypeApiActionTypeSearchGetRequest
 */
export interface ActionTypeApiActionTypeSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ActionTypeApiActionTypeSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * ActionTypeApi - object-oriented interface
 * @export
 * @class ActionTypeApi
 * @extends {BaseAPI}
 */
export class ActionTypeApi extends BaseAPI {
    /**
     * 
     * @param {ActionTypeApiActionTypeFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypeFindGet(requestParameters: ActionTypeApiActionTypeFindGetRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypeFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionTypeApiActionTypeSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypeSavePost(requestParameters: ActionTypeApiActionTypeSavePostRequest = {}, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypeSavePost(requestParameters.actionTypeSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ActionTypeApiActionTypeSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionTypeApi
     */
    public actionTypeSearchGet(requestParameters: ActionTypeApiActionTypeSearchGetRequest, options?: AxiosRequestConfig) {
        return ActionTypeApiFp(this.configuration).actionTypeSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AlertApi - axios parameter creator
 * @export
 */
export const AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('alertFindGet', 'id', id)
            const localVarPath = `/Alert/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AlertRemoveParams} [alertRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertRemovePost: async (alertRemoveParams?: AlertRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Alert/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [keyword] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertSearchGet: async (pi: number, ps: number, keyword?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('alertSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('alertSearchGet', 'ps', ps)
            const localVarPath = `/Alert/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['Keyword'] = keyword;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertApi - functional programming interface
 * @export
 */
export const AlertApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AlertRemoveParams} [alertRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertRemovePost(alertRemoveParams?: AlertRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertRemovePost(alertRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [keyword] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alertSearchGet(pi: number, ps: number, keyword?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alertSearchGet(pi, ps, keyword, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlertApi - factory interface
 * @export
 */
export const AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertFindGet(id: number, options?: any): AxiosPromise<AlertDto> {
            return localVarFp.alertFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AlertRemoveParams} [alertRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertRemovePost(alertRemoveParams?: AlertRemoveParams, options?: any): AxiosPromise<AlertDto> {
            return localVarFp.alertRemovePost(alertRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [keyword] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alertSearchGet(pi: number, ps: number, keyword?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<AlertDtoSearchResponseDto> {
            return localVarFp.alertSearchGet(pi, ps, keyword, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for alertFindGet operation in AlertApi.
 * @export
 * @interface AlertApiAlertFindGetRequest
 */
export interface AlertApiAlertFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AlertApiAlertFindGet
     */
    readonly id: number
}

/**
 * Request parameters for alertRemovePost operation in AlertApi.
 * @export
 * @interface AlertApiAlertRemovePostRequest
 */
export interface AlertApiAlertRemovePostRequest {
    /**
     * 
     * @type {AlertRemoveParams}
     * @memberof AlertApiAlertRemovePost
     */
    readonly alertRemoveParams?: AlertRemoveParams
}

/**
 * Request parameters for alertSearchGet operation in AlertApi.
 * @export
 * @interface AlertApiAlertSearchGetRequest
 */
export interface AlertApiAlertSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof AlertApiAlertSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof AlertApiAlertSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof AlertApiAlertSearchGet
     */
    readonly keyword?: string

    /**
     * 
     * @type {string}
     * @memberof AlertApiAlertSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof AlertApiAlertSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof AlertApiAlertSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof AlertApiAlertSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * AlertApi - object-oriented interface
 * @export
 * @class AlertApi
 * @extends {BaseAPI}
 */
export class AlertApi extends BaseAPI {
    /**
     * 
     * @param {AlertApiAlertFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public alertFindGet(requestParameters: AlertApiAlertFindGetRequest, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).alertFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AlertApiAlertRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public alertRemovePost(requestParameters: AlertApiAlertRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).alertRemovePost(requestParameters.alertRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AlertApiAlertSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public alertSearchGet(requestParameters: AlertApiAlertSearchGetRequest, options?: AxiosRequestConfig) {
        return AlertApiFp(this.configuration).alertSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.keyword, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizeApi - axios parameter creator
 * @export
 */
export const AuthorizeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeHeartbeatGet: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authorize/Heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (body !== undefined) {
                localVarQueryParameter['body'] = body;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeLoginPost: async (authorizeLoginParams?: AuthorizeLoginParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authorize/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizeLoginParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizeApi - functional programming interface
 * @export
 */
export const AuthorizeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeHeartbeatGet(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizeHeartbeatDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeHeartbeatGet(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeLoginPost(authorizeLoginParams?: AuthorizeLoginParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeLoginPost(authorizeLoginParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizeApi - factory interface
 * @export
 */
export const AuthorizeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeHeartbeatGet(body?: string, options?: any): AxiosPromise<AuthorizeHeartbeatDto> {
            return localVarFp.authorizeHeartbeatGet(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizeLoginParams} [authorizeLoginParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeLoginPost(authorizeLoginParams?: AuthorizeLoginParams, options?: any): AxiosPromise<Token> {
            return localVarFp.authorizeLoginPost(authorizeLoginParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authorizeHeartbeatGet operation in AuthorizeApi.
 * @export
 * @interface AuthorizeApiAuthorizeHeartbeatGetRequest
 */
export interface AuthorizeApiAuthorizeHeartbeatGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeApiAuthorizeHeartbeatGet
     */
    readonly body?: string
}

/**
 * Request parameters for authorizeLoginPost operation in AuthorizeApi.
 * @export
 * @interface AuthorizeApiAuthorizeLoginPostRequest
 */
export interface AuthorizeApiAuthorizeLoginPostRequest {
    /**
     * 
     * @type {AuthorizeLoginParams}
     * @memberof AuthorizeApiAuthorizeLoginPost
     */
    readonly authorizeLoginParams?: AuthorizeLoginParams
}

/**
 * AuthorizeApi - object-oriented interface
 * @export
 * @class AuthorizeApi
 * @extends {BaseAPI}
 */
export class AuthorizeApi extends BaseAPI {
    /**
     * 
     * @param {AuthorizeApiAuthorizeHeartbeatGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizeApi
     */
    public authorizeHeartbeatGet(requestParameters: AuthorizeApiAuthorizeHeartbeatGetRequest = {}, options?: AxiosRequestConfig) {
        return AuthorizeApiFp(this.configuration).authorizeHeartbeatGet(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizeApiAuthorizeLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizeApi
     */
    public authorizeLoginPost(requestParameters: AuthorizeApiAuthorizeLoginPostRequest = {}, options?: AxiosRequestConfig) {
        return AuthorizeApiFp(this.configuration).authorizeLoginPost(requestParameters.authorizeLoginParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuildingApi - axios parameter creator
 * @export
 */
export const BuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buildingFindGet', 'id', id)
            const localVarPath = `/Building/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BuildingSaveParams} [buildingSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingSavePost: async (buildingSaveParams?: BuildingSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Building/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('buildingSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('buildingSearchGet', 'ps', ps)
            const localVarPath = `/Building/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildingFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildingFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BuildingSaveParams} [buildingSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildingSavePost(buildingSaveParams?: BuildingSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildingSavePost(buildingSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildingSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildingSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingFindGet(id: number, options?: any): AxiosPromise<BuildingDto> {
            return localVarFp.buildingFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BuildingSaveParams} [buildingSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingSavePost(buildingSaveParams?: BuildingSaveParams, options?: any): AxiosPromise<BuildingDto> {
            return localVarFp.buildingSavePost(buildingSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildingSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<BuildingDtoSearchResponseDto> {
            return localVarFp.buildingSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for buildingFindGet operation in BuildingApi.
 * @export
 * @interface BuildingApiBuildingFindGetRequest
 */
export interface BuildingApiBuildingFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiBuildingFindGet
     */
    readonly id: number
}

/**
 * Request parameters for buildingSavePost operation in BuildingApi.
 * @export
 * @interface BuildingApiBuildingSavePostRequest
 */
export interface BuildingApiBuildingSavePostRequest {
    /**
     * 
     * @type {BuildingSaveParams}
     * @memberof BuildingApiBuildingSavePost
     */
    readonly buildingSaveParams?: BuildingSaveParams
}

/**
 * Request parameters for buildingSearchGet operation in BuildingApi.
 * @export
 * @interface BuildingApiBuildingSearchGetRequest
 */
export interface BuildingApiBuildingSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof BuildingApiBuildingSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * 
     * @param {BuildingApiBuildingFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public buildingFindGet(requestParameters: BuildingApiBuildingFindGetRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).buildingFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuildingApiBuildingSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public buildingSavePost(requestParameters: BuildingApiBuildingSavePostRequest = {}, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).buildingSavePost(requestParameters.buildingSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BuildingApiBuildingSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public buildingSearchGet(requestParameters: BuildingApiBuildingSearchGetRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).buildingSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatHistoryApi - axios parameter creator
 * @export
 */
export const ChatHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistoryFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('chatHistoryFindGet', 'id', id)
            const localVarPath = `/ChatHistory/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChatHistorySaveParams} [chatHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistorySavePost: async (chatHistorySaveParams?: ChatHistorySaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ChatHistory/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chatHistorySaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [senderName] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistorySearchGet: async (pi: number, ps: number, senderName?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('chatHistorySearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('chatHistorySearchGet', 'ps', ps)
            const localVarPath = `/ChatHistory/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (senderName !== undefined) {
                localVarQueryParameter['SenderName'] = senderName;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} senderName 
         * @param {Array<File>} [files] 
         * @param {boolean} [isDelete] 
         * @param {number} [id] 
         * @param {boolean} [isUpdate] 
         * @param {boolean} [isAdd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistoryUploadAudioPost: async (senderName: string, files?: Array<File>, isDelete?: boolean, id?: number, isUpdate?: boolean, isAdd?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'senderName' is not null or undefined
            assertParamExists('chatHistoryUploadAudioPost', 'senderName', senderName)
            const localVarPath = `/ChatHistory/UploadAudio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (senderName !== undefined) { 
                localVarFormParams.append('SenderName', senderName as any);
            }
    
            if (isDelete !== undefined) { 
                localVarFormParams.append('IsDelete', isDelete as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('Id', id as any);
            }
    
            if (isUpdate !== undefined) { 
                localVarFormParams.append('IsUpdate', isUpdate as any);
            }
    
            if (isAdd !== undefined) { 
                localVarFormParams.append('IsAdd', isAdd as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatHistoryApi - functional programming interface
 * @export
 */
export const ChatHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatHistoryFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatHistoryFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChatHistorySaveParams} [chatHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatHistorySavePost(chatHistorySaveParams?: ChatHistorySaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatHistorySavePost(chatHistorySaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [senderName] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatHistorySearchGet(pi: number, ps: number, senderName?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatHistoryDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatHistorySearchGet(pi, ps, senderName, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} senderName 
         * @param {Array<File>} [files] 
         * @param {boolean} [isDelete] 
         * @param {number} [id] 
         * @param {boolean} [isUpdate] 
         * @param {boolean} [isAdd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatHistoryUploadAudioPost(senderName: string, files?: Array<File>, isDelete?: boolean, id?: number, isUpdate?: boolean, isAdd?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatHistoryUploadAudioPost(senderName, files, isDelete, id, isUpdate, isAdd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatHistoryApi - factory interface
 * @export
 */
export const ChatHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistoryFindGet(id: number, options?: any): AxiosPromise<ChatHistoryDto> {
            return localVarFp.chatHistoryFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChatHistorySaveParams} [chatHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistorySavePost(chatHistorySaveParams?: ChatHistorySaveParams, options?: any): AxiosPromise<ChatHistoryDto> {
            return localVarFp.chatHistorySavePost(chatHistorySaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [senderName] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistorySearchGet(pi: number, ps: number, senderName?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<ChatHistoryDtoSearchResponseDto> {
            return localVarFp.chatHistorySearchGet(pi, ps, senderName, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} senderName 
         * @param {Array<File>} [files] 
         * @param {boolean} [isDelete] 
         * @param {number} [id] 
         * @param {boolean} [isUpdate] 
         * @param {boolean} [isAdd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatHistoryUploadAudioPost(senderName: string, files?: Array<File>, isDelete?: boolean, id?: number, isUpdate?: boolean, isAdd?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.chatHistoryUploadAudioPost(senderName, files, isDelete, id, isUpdate, isAdd, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for chatHistoryFindGet operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiChatHistoryFindGetRequest
 */
export interface ChatHistoryApiChatHistoryFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryApiChatHistoryFindGet
     */
    readonly id: number
}

/**
 * Request parameters for chatHistorySavePost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiChatHistorySavePostRequest
 */
export interface ChatHistoryApiChatHistorySavePostRequest {
    /**
     * 
     * @type {ChatHistorySaveParams}
     * @memberof ChatHistoryApiChatHistorySavePost
     */
    readonly chatHistorySaveParams?: ChatHistorySaveParams
}

/**
 * Request parameters for chatHistorySearchGet operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiChatHistorySearchGetRequest
 */
export interface ChatHistoryApiChatHistorySearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly senderName?: string

    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryApiChatHistorySearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for chatHistoryUploadAudioPost operation in ChatHistoryApi.
 * @export
 * @interface ChatHistoryApiChatHistoryUploadAudioPostRequest
 */
export interface ChatHistoryApiChatHistoryUploadAudioPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly senderName: string

    /**
     * 
     * @type {Array<File>}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly files?: Array<File>

    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly isDelete?: boolean

    /**
     * 
     * @type {number}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly id?: number

    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly isUpdate?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ChatHistoryApiChatHistoryUploadAudioPost
     */
    readonly isAdd?: boolean
}

/**
 * ChatHistoryApi - object-oriented interface
 * @export
 * @class ChatHistoryApi
 * @extends {BaseAPI}
 */
export class ChatHistoryApi extends BaseAPI {
    /**
     * 
     * @param {ChatHistoryApiChatHistoryFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public chatHistoryFindGet(requestParameters: ChatHistoryApiChatHistoryFindGetRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).chatHistoryFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiChatHistorySavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public chatHistorySavePost(requestParameters: ChatHistoryApiChatHistorySavePostRequest = {}, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).chatHistorySavePost(requestParameters.chatHistorySaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiChatHistorySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public chatHistorySearchGet(requestParameters: ChatHistoryApiChatHistorySearchGetRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).chatHistorySearchGet(requestParameters.pi, requestParameters.ps, requestParameters.senderName, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChatHistoryApiChatHistoryUploadAudioPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatHistoryApi
     */
    public chatHistoryUploadAudioPost(requestParameters: ChatHistoryApiChatHistoryUploadAudioPostRequest, options?: AxiosRequestConfig) {
        return ChatHistoryApiFp(this.configuration).chatHistoryUploadAudioPost(requestParameters.senderName, requestParameters.files, requestParameters.isDelete, requestParameters.id, requestParameters.isUpdate, requestParameters.isAdd, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FloorApi - axios parameter creator
 * @export
 */
export const FloorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('floorFindGet', 'id', id)
            const localVarPath = `/Floor/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorListByBuildingGet: async (buildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('floorListByBuildingGet', 'buildingId', buildingId)
            const localVarPath = `/Floor/ListByBuilding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (buildingId !== undefined) {
                localVarQueryParameter['BuildingId'] = buildingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorRemoveParams} [floorRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorRemovePost: async (floorRemoveParams?: FloorRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Floor/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floorRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FloorSaveParams} [floorSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorSavePost: async (floorSaveParams?: FloorSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Floor/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(floorSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('floorSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('floorSearchGet', 'ps', ps)
            const localVarPath = `/Floor/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FloorApi - functional programming interface
 * @export
 */
export const FloorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FloorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floorFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floorFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floorListByBuildingGet(buildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FloorDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floorListByBuildingGet(buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorRemoveParams} [floorRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floorRemovePost(floorRemoveParams?: FloorRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floorRemovePost(floorRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FloorSaveParams} [floorSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floorSavePost(floorSaveParams?: FloorSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floorSavePost(floorSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async floorSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FloorDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.floorSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FloorApi - factory interface
 * @export
 */
export const FloorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FloorApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorFindGet(id: number, options?: any): AxiosPromise<FloorDto> {
            return localVarFp.floorFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorListByBuildingGet(buildingId: number, options?: any): AxiosPromise<Array<FloorDto>> {
            return localVarFp.floorListByBuildingGet(buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorRemoveParams} [floorRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorRemovePost(floorRemoveParams?: FloorRemoveParams, options?: any): AxiosPromise<FloorDto> {
            return localVarFp.floorRemovePost(floorRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FloorSaveParams} [floorSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorSavePost(floorSaveParams?: FloorSaveParams, options?: any): AxiosPromise<FloorDto> {
            return localVarFp.floorSavePost(floorSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        floorSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<FloorDtoSearchResponseDto> {
            return localVarFp.floorSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for floorFindGet operation in FloorApi.
 * @export
 * @interface FloorApiFloorFindGetRequest
 */
export interface FloorApiFloorFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FloorApiFloorFindGet
     */
    readonly id: number
}

/**
 * Request parameters for floorListByBuildingGet operation in FloorApi.
 * @export
 * @interface FloorApiFloorListByBuildingGetRequest
 */
export interface FloorApiFloorListByBuildingGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FloorApiFloorListByBuildingGet
     */
    readonly buildingId: number
}

/**
 * Request parameters for floorRemovePost operation in FloorApi.
 * @export
 * @interface FloorApiFloorRemovePostRequest
 */
export interface FloorApiFloorRemovePostRequest {
    /**
     * 
     * @type {FloorRemoveParams}
     * @memberof FloorApiFloorRemovePost
     */
    readonly floorRemoveParams?: FloorRemoveParams
}

/**
 * Request parameters for floorSavePost operation in FloorApi.
 * @export
 * @interface FloorApiFloorSavePostRequest
 */
export interface FloorApiFloorSavePostRequest {
    /**
     * 
     * @type {FloorSaveParams}
     * @memberof FloorApiFloorSavePost
     */
    readonly floorSaveParams?: FloorSaveParams
}

/**
 * Request parameters for floorSearchGet operation in FloorApi.
 * @export
 * @interface FloorApiFloorSearchGetRequest
 */
export interface FloorApiFloorSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FloorApiFloorSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof FloorApiFloorSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof FloorApiFloorSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof FloorApiFloorSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof FloorApiFloorSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof FloorApiFloorSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof FloorApiFloorSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof FloorApiFloorSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * FloorApi - object-oriented interface
 * @export
 * @class FloorApi
 * @extends {BaseAPI}
 */
export class FloorApi extends BaseAPI {
    /**
     * 
     * @param {FloorApiFloorFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public floorFindGet(requestParameters: FloorApiFloorFindGetRequest, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).floorFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorApiFloorListByBuildingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public floorListByBuildingGet(requestParameters: FloorApiFloorListByBuildingGetRequest, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).floorListByBuildingGet(requestParameters.buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorApiFloorRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public floorRemovePost(requestParameters: FloorApiFloorRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).floorRemovePost(requestParameters.floorRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorApiFloorSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public floorSavePost(requestParameters: FloorApiFloorSavePostRequest = {}, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).floorSavePost(requestParameters.floorSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FloorApiFloorSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloorApi
     */
    public floorSearchGet(requestParameters: FloorApiFloorSearchGetRequest, options?: AxiosRequestConfig) {
        return FloorApiFp(this.configuration).floorSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FtpApi - axios parameter creator
 * @export
 */
export const FtpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftpUploadImagePost: async (file?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ftp/UploadImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FtpApi - functional programming interface
 * @export
 */
export const FtpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FtpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ftpUploadImagePost(file?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ftpUploadImagePost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FtpApi - factory interface
 * @export
 */
export const FtpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FtpApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ftpUploadImagePost(file?: Array<File>, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.ftpUploadImagePost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for ftpUploadImagePost operation in FtpApi.
 * @export
 * @interface FtpApiFtpUploadImagePostRequest
 */
export interface FtpApiFtpUploadImagePostRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof FtpApiFtpUploadImagePost
     */
    readonly file?: Array<File>
}

/**
 * FtpApi - object-oriented interface
 * @export
 * @class FtpApi
 * @extends {BaseAPI}
 */
export class FtpApi extends BaseAPI {
    /**
     * 
     * @param {FtpApiFtpUploadImagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FtpApi
     */
    public ftpUploadImagePost(requestParameters: FtpApiFtpUploadImagePostRequest = {}, options?: AxiosRequestConfig) {
        return FtpApiFp(this.configuration).ftpUploadImagePost(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MapApi - axios parameter creator
 * @export
 */
export const MapApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MapCalculatePositionParams} [mapCalculatePositionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapCalculatePositionPost: async (mapCalculatePositionParams?: MapCalculatePositionParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Map/CalculatePosition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapCalculatePositionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MapCalibrateParams} [mapCalibrateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapCalibratePost: async (mapCalibrateParams?: MapCalibrateParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Map/Calibrate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapCalibrateParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mapFindGet', 'id', id)
            const localVarPath = `/Map/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MapRemoveParams} [mapRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapRemovePost: async (mapRemoveParams?: MapRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Map/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MapSaveParams} [mapSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSavePost: async (mapSaveParams?: MapSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Map/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mapSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSearchGet: async (pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('mapSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('mapSearchGet', 'ps', ps)
            const localVarPath = `/Map/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MapApi - functional programming interface
 * @export
 */
export const MapApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MapApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MapCalculatePositionParams} [mapCalculatePositionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapCalculatePositionPost(mapCalculatePositionParams?: MapCalculatePositionParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IMapPosition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapCalculatePositionPost(mapCalculatePositionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MapCalibrateParams} [mapCalibrateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapCalibratePost(mapCalibrateParams?: MapCalibrateParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapCalibratePost(mapCalibrateParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MapRemoveParams} [mapRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapRemovePost(mapRemoveParams?: MapRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapRemovePost(mapRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MapSaveParams} [mapSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapSavePost(mapSaveParams?: MapSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapSavePost(mapSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mapSearchGet(pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MapDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mapSearchGet(pi, ps, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MapApi - factory interface
 * @export
 */
export const MapApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MapApiFp(configuration)
    return {
        /**
         * 
         * @param {MapCalculatePositionParams} [mapCalculatePositionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapCalculatePositionPost(mapCalculatePositionParams?: MapCalculatePositionParams, options?: any): AxiosPromise<IMapPosition> {
            return localVarFp.mapCalculatePositionPost(mapCalculatePositionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MapCalibrateParams} [mapCalibrateParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapCalibratePost(mapCalibrateParams?: MapCalibrateParams, options?: any): AxiosPromise<MapDto> {
            return localVarFp.mapCalibratePost(mapCalibrateParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapFindGet(id: number, options?: any): AxiosPromise<MapDto> {
            return localVarFp.mapFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MapRemoveParams} [mapRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapRemovePost(mapRemoveParams?: MapRemoveParams, options?: any): AxiosPromise<MapDto> {
            return localVarFp.mapRemovePost(mapRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MapSaveParams} [mapSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSavePost(mapSaveParams?: MapSaveParams, options?: any): AxiosPromise<MapDto> {
            return localVarFp.mapSavePost(mapSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mapSearchGet(pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MapDtoSearchResponseDto> {
            return localVarFp.mapSearchGet(pi, ps, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for mapCalculatePositionPost operation in MapApi.
 * @export
 * @interface MapApiMapCalculatePositionPostRequest
 */
export interface MapApiMapCalculatePositionPostRequest {
    /**
     * 
     * @type {MapCalculatePositionParams}
     * @memberof MapApiMapCalculatePositionPost
     */
    readonly mapCalculatePositionParams?: MapCalculatePositionParams
}

/**
 * Request parameters for mapCalibratePost operation in MapApi.
 * @export
 * @interface MapApiMapCalibratePostRequest
 */
export interface MapApiMapCalibratePostRequest {
    /**
     * 
     * @type {MapCalibrateParams}
     * @memberof MapApiMapCalibratePost
     */
    readonly mapCalibrateParams?: MapCalibrateParams
}

/**
 * Request parameters for mapFindGet operation in MapApi.
 * @export
 * @interface MapApiMapFindGetRequest
 */
export interface MapApiMapFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MapApiMapFindGet
     */
    readonly id: number
}

/**
 * Request parameters for mapRemovePost operation in MapApi.
 * @export
 * @interface MapApiMapRemovePostRequest
 */
export interface MapApiMapRemovePostRequest {
    /**
     * 
     * @type {MapRemoveParams}
     * @memberof MapApiMapRemovePost
     */
    readonly mapRemoveParams?: MapRemoveParams
}

/**
 * Request parameters for mapSavePost operation in MapApi.
 * @export
 * @interface MapApiMapSavePostRequest
 */
export interface MapApiMapSavePostRequest {
    /**
     * 
     * @type {MapSaveParams}
     * @memberof MapApiMapSavePost
     */
    readonly mapSaveParams?: MapSaveParams
}

/**
 * Request parameters for mapSearchGet operation in MapApi.
 * @export
 * @interface MapApiMapSearchGetRequest
 */
export interface MapApiMapSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MapApiMapSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MapApiMapSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof MapApiMapSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MapApiMapSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MapApiMapSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MapApiMapSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * MapApi - object-oriented interface
 * @export
 * @class MapApi
 * @extends {BaseAPI}
 */
export class MapApi extends BaseAPI {
    /**
     * 
     * @param {MapApiMapCalculatePositionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapCalculatePositionPost(requestParameters: MapApiMapCalculatePositionPostRequest = {}, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapCalculatePositionPost(requestParameters.mapCalculatePositionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapApiMapCalibratePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapCalibratePost(requestParameters: MapApiMapCalibratePostRequest = {}, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapCalibratePost(requestParameters.mapCalibrateParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapApiMapFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapFindGet(requestParameters: MapApiMapFindGetRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapApiMapRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapRemovePost(requestParameters: MapApiMapRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapRemovePost(requestParameters.mapRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapApiMapSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapSavePost(requestParameters: MapApiMapSavePostRequest = {}, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapSavePost(requestParameters.mapSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MapApiMapSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MapApi
     */
    public mapSearchGet(requestParameters: MapApiMapSearchGetRequest, options?: AxiosRequestConfig) {
        return MapApiFp(this.configuration).mapSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionApi - axios parameter creator
 * @export
 */
export const MissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionFindGet', 'id', id)
            const localVarPath = `/Mission/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionForceStopAllParams} [missionForceStopAllParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionForceStopPost: async (missionForceStopAllParams?: MissionForceStopAllParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Mission/ForceStop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionForceStopAllParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionPauseParams} [missionPauseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionPausePost: async (missionPauseParams?: MissionPauseParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Mission/Pause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionPauseParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionResumeParams} [missionResumeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionResumePost: async (missionResumeParams?: MissionResumeParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Mission/Resume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionResumeParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionSearchGet: async (pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('missionSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('missionSearchGet', 'ps', ps)
            const localVarPath = `/Mission/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionStartParams} [missionStartParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionStartPost: async (missionStartParams?: MissionStartParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Mission/Start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionStartParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionApi - functional programming interface
 * @export
 */
export const MissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionForceStopAllParams} [missionForceStopAllParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionForceStopPost(missionForceStopAllParams?: MissionForceStopAllParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionForceStopPost(missionForceStopAllParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionPauseParams} [missionPauseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionPausePost(missionPauseParams?: MissionPauseParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionPausePost(missionPauseParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionResumeParams} [missionResumeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionResumePost(missionResumeParams?: MissionResumeParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionResumePost(missionResumeParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionSearchGet(pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionSearchGet(pi, ps, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionStartParams} [missionStartParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionStartPost(missionStartParams?: MissionStartParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionStartPost(missionStartParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionApi - factory interface
 * @export
 */
export const MissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionFindGet(id: number, options?: any): AxiosPromise<MissionDto> {
            return localVarFp.missionFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionForceStopAllParams} [missionForceStopAllParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionForceStopPost(missionForceStopAllParams?: MissionForceStopAllParams, options?: any): AxiosPromise<OperateResultDto> {
            return localVarFp.missionForceStopPost(missionForceStopAllParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionPauseParams} [missionPauseParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionPausePost(missionPauseParams?: MissionPauseParams, options?: any): AxiosPromise<OperateResultDto> {
            return localVarFp.missionPausePost(missionPauseParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionResumeParams} [missionResumeParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionResumePost(missionResumeParams?: MissionResumeParams, options?: any): AxiosPromise<OperateResultDto> {
            return localVarFp.missionResumePost(missionResumeParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionSearchGet(pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MissionDtoSearchResponseDto> {
            return localVarFp.missionSearchGet(pi, ps, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionStartParams} [missionStartParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionStartPost(missionStartParams?: MissionStartParams, options?: any): AxiosPromise<MissionDto> {
            return localVarFp.missionStartPost(missionStartParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for missionFindGet operation in MissionApi.
 * @export
 * @interface MissionApiMissionFindGetRequest
 */
export interface MissionApiMissionFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionApiMissionFindGet
     */
    readonly id: number
}

/**
 * Request parameters for missionForceStopPost operation in MissionApi.
 * @export
 * @interface MissionApiMissionForceStopPostRequest
 */
export interface MissionApiMissionForceStopPostRequest {
    /**
     * 
     * @type {MissionForceStopAllParams}
     * @memberof MissionApiMissionForceStopPost
     */
    readonly missionForceStopAllParams?: MissionForceStopAllParams
}

/**
 * Request parameters for missionPausePost operation in MissionApi.
 * @export
 * @interface MissionApiMissionPausePostRequest
 */
export interface MissionApiMissionPausePostRequest {
    /**
     * 
     * @type {MissionPauseParams}
     * @memberof MissionApiMissionPausePost
     */
    readonly missionPauseParams?: MissionPauseParams
}

/**
 * Request parameters for missionResumePost operation in MissionApi.
 * @export
 * @interface MissionApiMissionResumePostRequest
 */
export interface MissionApiMissionResumePostRequest {
    /**
     * 
     * @type {MissionResumeParams}
     * @memberof MissionApiMissionResumePost
     */
    readonly missionResumeParams?: MissionResumeParams
}

/**
 * Request parameters for missionSearchGet operation in MissionApi.
 * @export
 * @interface MissionApiMissionSearchGetRequest
 */
export interface MissionApiMissionSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionApiMissionSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MissionApiMissionSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof MissionApiMissionSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {string}
     * @memberof MissionApiMissionSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionApiMissionSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MissionApiMissionSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MissionApiMissionSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for missionStartPost operation in MissionApi.
 * @export
 * @interface MissionApiMissionStartPostRequest
 */
export interface MissionApiMissionStartPostRequest {
    /**
     * 
     * @type {MissionStartParams}
     * @memberof MissionApiMissionStartPost
     */
    readonly missionStartParams?: MissionStartParams
}

/**
 * MissionApi - object-oriented interface
 * @export
 * @class MissionApi
 * @extends {BaseAPI}
 */
export class MissionApi extends BaseAPI {
    /**
     * 
     * @param {MissionApiMissionFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionFindGet(requestParameters: MissionApiMissionFindGetRequest, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionApiMissionForceStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionForceStopPost(requestParameters: MissionApiMissionForceStopPostRequest = {}, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionForceStopPost(requestParameters.missionForceStopAllParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionApiMissionPausePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionPausePost(requestParameters: MissionApiMissionPausePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionPausePost(requestParameters.missionPauseParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionApiMissionResumePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionResumePost(requestParameters: MissionApiMissionResumePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionResumePost(requestParameters.missionResumeParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionApiMissionSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionSearchGet(requestParameters: MissionApiMissionSearchGetRequest, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.robotId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionApiMissionStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionStartPost(requestParameters: MissionApiMissionStartPostRequest = {}, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionStartPost(requestParameters.missionStartParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionLogApi - axios parameter creator
 * @export
 */
export const MissionLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionLogFindGet', 'id', id)
            const localVarPath = `/MissionLog/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogListByMissionGet: async (missionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionId' is not null or undefined
            assertParamExists('missionLogListByMissionGet', 'missionId', missionId)
            const localVarPath = `/MissionLog/ListByMission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (missionId !== undefined) {
                localVarQueryParameter['MissionId'] = missionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [missionId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogSearchGet: async (pi: number, ps: number, missionId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('missionLogSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('missionLogSearchGet', 'ps', ps)
            const localVarPath = `/MissionLog/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (missionId !== undefined) {
                localVarQueryParameter['MissionId'] = missionId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionLogApi - functional programming interface
 * @export
 */
export const MissionLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionLogFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionLogFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionLogListByMissionGet(missionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionLogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionLogListByMissionGet(missionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [missionId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionLogSearchGet(pi: number, ps: number, missionId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionLogDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionLogSearchGet(pi, ps, missionId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionLogApi - factory interface
 * @export
 */
export const MissionLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionLogApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogFindGet(id: number, options?: any): AxiosPromise<MissionLogDto> {
            return localVarFp.missionLogFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} missionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogListByMissionGet(missionId: number, options?: any): AxiosPromise<Array<MissionLogDto>> {
            return localVarFp.missionLogListByMissionGet(missionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [missionId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionLogSearchGet(pi: number, ps: number, missionId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MissionLogDtoSearchResponseDto> {
            return localVarFp.missionLogSearchGet(pi, ps, missionId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for missionLogFindGet operation in MissionLogApi.
 * @export
 * @interface MissionLogApiMissionLogFindGetRequest
 */
export interface MissionLogApiMissionLogFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionLogApiMissionLogFindGet
     */
    readonly id: number
}

/**
 * Request parameters for missionLogListByMissionGet operation in MissionLogApi.
 * @export
 * @interface MissionLogApiMissionLogListByMissionGetRequest
 */
export interface MissionLogApiMissionLogListByMissionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionLogApiMissionLogListByMissionGet
     */
    readonly missionId: number
}

/**
 * Request parameters for missionLogSearchGet operation in MissionLogApi.
 * @export
 * @interface MissionLogApiMissionLogSearchGetRequest
 */
export interface MissionLogApiMissionLogSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly missionId?: number

    /**
     * 
     * @type {string}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MissionLogApiMissionLogSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * MissionLogApi - object-oriented interface
 * @export
 * @class MissionLogApi
 * @extends {BaseAPI}
 */
export class MissionLogApi extends BaseAPI {
    /**
     * 
     * @param {MissionLogApiMissionLogFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionLogApi
     */
    public missionLogFindGet(requestParameters: MissionLogApiMissionLogFindGetRequest, options?: AxiosRequestConfig) {
        return MissionLogApiFp(this.configuration).missionLogFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionLogApiMissionLogListByMissionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionLogApi
     */
    public missionLogListByMissionGet(requestParameters: MissionLogApiMissionLogListByMissionGetRequest, options?: AxiosRequestConfig) {
        return MissionLogApiFp(this.configuration).missionLogListByMissionGet(requestParameters.missionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionLogApiMissionLogSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionLogApi
     */
    public missionLogSearchGet(requestParameters: MissionLogApiMissionLogSearchGetRequest, options?: AxiosRequestConfig) {
        return MissionLogApiFp(this.configuration).missionLogSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.missionId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionScheduleApi - axios parameter creator
 * @export
 */
export const MissionScheduleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionScheduleFindGet', 'id', id)
            const localVarPath = `/MissionSchedule/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [robotId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleListGet: async (robotId?: number, includeDeleted?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MissionSchedule/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionScheduleRemoveParams} [missionScheduleRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleRemovePost: async (missionScheduleRemoveParams?: MissionScheduleRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MissionSchedule/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionScheduleRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionScheduleSaveParams} [missionScheduleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleSavePost: async (missionScheduleSaveParams?: MissionScheduleSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MissionSchedule/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionScheduleSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleSearchGet: async (pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('missionScheduleSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('missionScheduleSearchGet', 'ps', ps)
            const localVarPath = `/MissionSchedule/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionScheduleApi - functional programming interface
 * @export
 */
export const MissionScheduleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionScheduleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionScheduleFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionScheduleFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [robotId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionScheduleListGet(robotId?: number, includeDeleted?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionScheduleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionScheduleListGet(robotId, includeDeleted, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionScheduleRemoveParams} [missionScheduleRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionScheduleRemovePost(missionScheduleRemoveParams?: MissionScheduleRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionScheduleRemovePost(missionScheduleRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionScheduleSaveParams} [missionScheduleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionScheduleSavePost(missionScheduleSaveParams?: MissionScheduleSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionScheduleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionScheduleSavePost(missionScheduleSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionScheduleSearchGet(pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionScheduleDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionScheduleSearchGet(pi, ps, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionScheduleApi - factory interface
 * @export
 */
export const MissionScheduleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionScheduleApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleFindGet(id: number, options?: any): AxiosPromise<MissionScheduleDto> {
            return localVarFp.missionScheduleFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [robotId] 
         * @param {boolean} [includeDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleListGet(robotId?: number, includeDeleted?: boolean, options?: any): AxiosPromise<Array<MissionScheduleDto>> {
            return localVarFp.missionScheduleListGet(robotId, includeDeleted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionScheduleRemoveParams} [missionScheduleRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleRemovePost(missionScheduleRemoveParams?: MissionScheduleRemoveParams, options?: any): AxiosPromise<MissionScheduleDto> {
            return localVarFp.missionScheduleRemovePost(missionScheduleRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionScheduleSaveParams} [missionScheduleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleSavePost(missionScheduleSaveParams?: MissionScheduleSaveParams, options?: any): AxiosPromise<MissionScheduleDto> {
            return localVarFp.missionScheduleSavePost(missionScheduleSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionScheduleSearchGet(pi: number, ps: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MissionScheduleDtoSearchResponseDto> {
            return localVarFp.missionScheduleSearchGet(pi, ps, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for missionScheduleFindGet operation in MissionScheduleApi.
 * @export
 * @interface MissionScheduleApiMissionScheduleFindGetRequest
 */
export interface MissionScheduleApiMissionScheduleFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleApiMissionScheduleFindGet
     */
    readonly id: number
}

/**
 * Request parameters for missionScheduleListGet operation in MissionScheduleApi.
 * @export
 * @interface MissionScheduleApiMissionScheduleListGetRequest
 */
export interface MissionScheduleApiMissionScheduleListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleApiMissionScheduleListGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleApiMissionScheduleListGet
     */
    readonly includeDeleted?: boolean
}

/**
 * Request parameters for missionScheduleRemovePost operation in MissionScheduleApi.
 * @export
 * @interface MissionScheduleApiMissionScheduleRemovePostRequest
 */
export interface MissionScheduleApiMissionScheduleRemovePostRequest {
    /**
     * 
     * @type {MissionScheduleRemoveParams}
     * @memberof MissionScheduleApiMissionScheduleRemovePost
     */
    readonly missionScheduleRemoveParams?: MissionScheduleRemoveParams
}

/**
 * Request parameters for missionScheduleSavePost operation in MissionScheduleApi.
 * @export
 * @interface MissionScheduleApiMissionScheduleSavePostRequest
 */
export interface MissionScheduleApiMissionScheduleSavePostRequest {
    /**
     * 
     * @type {MissionScheduleSaveParams}
     * @memberof MissionScheduleApiMissionScheduleSavePost
     */
    readonly missionScheduleSaveParams?: MissionScheduleSaveParams
}

/**
 * Request parameters for missionScheduleSearchGet operation in MissionScheduleApi.
 * @export
 * @interface MissionScheduleApiMissionScheduleSearchGetRequest
 */
export interface MissionScheduleApiMissionScheduleSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MissionScheduleApiMissionScheduleSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * MissionScheduleApi - object-oriented interface
 * @export
 * @class MissionScheduleApi
 * @extends {BaseAPI}
 */
export class MissionScheduleApi extends BaseAPI {
    /**
     * 
     * @param {MissionScheduleApiMissionScheduleFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionScheduleApi
     */
    public missionScheduleFindGet(requestParameters: MissionScheduleApiMissionScheduleFindGetRequest, options?: AxiosRequestConfig) {
        return MissionScheduleApiFp(this.configuration).missionScheduleFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionScheduleApiMissionScheduleListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionScheduleApi
     */
    public missionScheduleListGet(requestParameters: MissionScheduleApiMissionScheduleListGetRequest = {}, options?: AxiosRequestConfig) {
        return MissionScheduleApiFp(this.configuration).missionScheduleListGet(requestParameters.robotId, requestParameters.includeDeleted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionScheduleApiMissionScheduleRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionScheduleApi
     */
    public missionScheduleRemovePost(requestParameters: MissionScheduleApiMissionScheduleRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionScheduleApiFp(this.configuration).missionScheduleRemovePost(requestParameters.missionScheduleRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionScheduleApiMissionScheduleSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionScheduleApi
     */
    public missionScheduleSavePost(requestParameters: MissionScheduleApiMissionScheduleSavePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionScheduleApiFp(this.configuration).missionScheduleSavePost(requestParameters.missionScheduleSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionScheduleApiMissionScheduleSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionScheduleApi
     */
    public missionScheduleSearchGet(requestParameters: MissionScheduleApiMissionScheduleSearchGetRequest, options?: AxiosRequestConfig) {
        return MissionScheduleApiFp(this.configuration).missionScheduleSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionTemplateApi - axios parameter creator
 * @export
 */
export const MissionTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionTemplateFindGet', 'id', id)
            const localVarPath = `/MissionTemplate/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateGetParametersGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionTemplateGetParametersGet', 'id', id)
            const localVarPath = `/MissionTemplate/GetParameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionTemplateSaveParams} [missionTemplateSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateSavePost: async (missionTemplateSaveParams?: MissionTemplateSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MissionTemplate/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionTemplateSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {boolean} [includeTemp] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateSearchGet: async (pi: number, ps: number, username?: string, name?: string, includeTemp?: boolean, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('missionTemplateSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('missionTemplateSearchGet', 'ps', ps)
            const localVarPath = `/MissionTemplate/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (includeTemp !== undefined) {
                localVarQueryParameter['IncludeTemp'] = includeTemp;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionTemplateApi - functional programming interface
 * @export
 */
export const MissionTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateGetParametersGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateSaveParams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateGetParametersGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionTemplateSaveParams} [missionTemplateSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateSavePost(missionTemplateSaveParams?: MissionTemplateSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateSavePost(missionTemplateSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {boolean} [includeTemp] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateSearchGet(pi: number, ps: number, username?: string, name?: string, includeTemp?: boolean, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateSearchGet(pi, ps, username, name, includeTemp, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionTemplateApi - factory interface
 * @export
 */
export const MissionTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateFindGet(id: number, options?: any): AxiosPromise<MissionTemplateDto> {
            return localVarFp.missionTemplateFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateGetParametersGet(id: number, options?: any): AxiosPromise<MissionTemplateSaveParams> {
            return localVarFp.missionTemplateGetParametersGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionTemplateSaveParams} [missionTemplateSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateSavePost(missionTemplateSaveParams?: MissionTemplateSaveParams, options?: any): AxiosPromise<MissionTemplateDto> {
            return localVarFp.missionTemplateSavePost(missionTemplateSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {boolean} [includeTemp] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateSearchGet(pi: number, ps: number, username?: string, name?: string, includeTemp?: boolean, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MissionTemplateDtoSearchResponseDto> {
            return localVarFp.missionTemplateSearchGet(pi, ps, username, name, includeTemp, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for missionTemplateFindGet operation in MissionTemplateApi.
 * @export
 * @interface MissionTemplateApiMissionTemplateFindGetRequest
 */
export interface MissionTemplateApiMissionTemplateFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateApiMissionTemplateFindGet
     */
    readonly id: number
}

/**
 * Request parameters for missionTemplateGetParametersGet operation in MissionTemplateApi.
 * @export
 * @interface MissionTemplateApiMissionTemplateGetParametersGetRequest
 */
export interface MissionTemplateApiMissionTemplateGetParametersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateApiMissionTemplateGetParametersGet
     */
    readonly id: number
}

/**
 * Request parameters for missionTemplateSavePost operation in MissionTemplateApi.
 * @export
 * @interface MissionTemplateApiMissionTemplateSavePostRequest
 */
export interface MissionTemplateApiMissionTemplateSavePostRequest {
    /**
     * 
     * @type {MissionTemplateSaveParams}
     * @memberof MissionTemplateApiMissionTemplateSavePost
     */
    readonly missionTemplateSaveParams?: MissionTemplateSaveParams
}

/**
 * Request parameters for missionTemplateSearchGet operation in MissionTemplateApi.
 * @export
 * @interface MissionTemplateApiMissionTemplateSearchGetRequest
 */
export interface MissionTemplateApiMissionTemplateSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly includeTemp?: boolean

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateApiMissionTemplateSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * MissionTemplateApi - object-oriented interface
 * @export
 * @class MissionTemplateApi
 * @extends {BaseAPI}
 */
export class MissionTemplateApi extends BaseAPI {
    /**
     * 
     * @param {MissionTemplateApiMissionTemplateFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateApi
     */
    public missionTemplateFindGet(requestParameters: MissionTemplateApiMissionTemplateFindGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateApiFp(this.configuration).missionTemplateFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateApiMissionTemplateGetParametersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateApi
     */
    public missionTemplateGetParametersGet(requestParameters: MissionTemplateApiMissionTemplateGetParametersGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateApiFp(this.configuration).missionTemplateGetParametersGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateApiMissionTemplateSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateApi
     */
    public missionTemplateSavePost(requestParameters: MissionTemplateApiMissionTemplateSavePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionTemplateApiFp(this.configuration).missionTemplateSavePost(requestParameters.missionTemplateSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateApiMissionTemplateSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateApi
     */
    public missionTemplateSearchGet(requestParameters: MissionTemplateApiMissionTemplateSearchGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateApiFp(this.configuration).missionTemplateSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.includeTemp, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionTemplateActionApi - axios parameter creator
 * @export
 */
export const MissionTemplateActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('missionTemplateActionFindGet', 'id', id)
            const localVarPath = `/MissionTemplateAction/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} missionTemplateId 
         * @param {boolean} [includeCommands] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionListByTemplateGet: async (missionTemplateId: number, includeCommands?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionTemplateId' is not null or undefined
            assertParamExists('missionTemplateActionListByTemplateGet', 'missionTemplateId', missionTemplateId)
            const localVarPath = `/MissionTemplateAction/ListByTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (missionTemplateId !== undefined) {
                localVarQueryParameter['MissionTemplateId'] = missionTemplateId;
            }

            if (includeCommands !== undefined) {
                localVarQueryParameter['IncludeCommands'] = includeCommands;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MissionTemplateActionSaveParams} [missionTemplateActionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionSavePost: async (missionTemplateActionSaveParams?: MissionTemplateActionSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MissionTemplateAction/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionTemplateActionSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('missionTemplateActionSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('missionTemplateActionSearchGet', 'ps', ps)
            const localVarPath = `/MissionTemplateAction/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionTemplateActionApi - functional programming interface
 * @export
 */
export const MissionTemplateActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionTemplateActionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateActionFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateActionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateActionFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} missionTemplateId 
         * @param {boolean} [includeCommands] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateActionListByTemplateGet(missionTemplateId: number, includeCommands?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MissionTemplateActionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateActionListByTemplateGet(missionTemplateId, includeCommands, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MissionTemplateActionSaveParams} [missionTemplateActionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateActionSavePost(missionTemplateActionSaveParams?: MissionTemplateActionSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateActionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateActionSavePost(missionTemplateActionSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTemplateActionSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionTemplateActionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTemplateActionSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionTemplateActionApi - factory interface
 * @export
 */
export const MissionTemplateActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionTemplateActionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionFindGet(id: number, options?: any): AxiosPromise<MissionTemplateActionDto> {
            return localVarFp.missionTemplateActionFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} missionTemplateId 
         * @param {boolean} [includeCommands] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionListByTemplateGet(missionTemplateId: number, includeCommands?: boolean, options?: any): AxiosPromise<Array<MissionTemplateActionDto>> {
            return localVarFp.missionTemplateActionListByTemplateGet(missionTemplateId, includeCommands, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MissionTemplateActionSaveParams} [missionTemplateActionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionSavePost(missionTemplateActionSaveParams?: MissionTemplateActionSaveParams, options?: any): AxiosPromise<MissionTemplateActionDto> {
            return localVarFp.missionTemplateActionSavePost(missionTemplateActionSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTemplateActionSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<MissionTemplateActionDtoSearchResponseDto> {
            return localVarFp.missionTemplateActionSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for missionTemplateActionFindGet operation in MissionTemplateActionApi.
 * @export
 * @interface MissionTemplateActionApiMissionTemplateActionFindGetRequest
 */
export interface MissionTemplateActionApiMissionTemplateActionFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionApiMissionTemplateActionFindGet
     */
    readonly id: number
}

/**
 * Request parameters for missionTemplateActionListByTemplateGet operation in MissionTemplateActionApi.
 * @export
 * @interface MissionTemplateActionApiMissionTemplateActionListByTemplateGetRequest
 */
export interface MissionTemplateActionApiMissionTemplateActionListByTemplateGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionApiMissionTemplateActionListByTemplateGet
     */
    readonly missionTemplateId: number

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionApiMissionTemplateActionListByTemplateGet
     */
    readonly includeCommands?: boolean
}

/**
 * Request parameters for missionTemplateActionSavePost operation in MissionTemplateActionApi.
 * @export
 * @interface MissionTemplateActionApiMissionTemplateActionSavePostRequest
 */
export interface MissionTemplateActionApiMissionTemplateActionSavePostRequest {
    /**
     * 
     * @type {MissionTemplateActionSaveParams}
     * @memberof MissionTemplateActionApiMissionTemplateActionSavePost
     */
    readonly missionTemplateActionSaveParams?: MissionTemplateActionSaveParams
}

/**
 * Request parameters for missionTemplateActionSearchGet operation in MissionTemplateActionApi.
 * @export
 * @interface MissionTemplateActionApiMissionTemplateActionSearchGetRequest
 */
export interface MissionTemplateActionApiMissionTemplateActionSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof MissionTemplateActionApiMissionTemplateActionSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * MissionTemplateActionApi - object-oriented interface
 * @export
 * @class MissionTemplateActionApi
 * @extends {BaseAPI}
 */
export class MissionTemplateActionApi extends BaseAPI {
    /**
     * 
     * @param {MissionTemplateActionApiMissionTemplateActionFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateActionApi
     */
    public missionTemplateActionFindGet(requestParameters: MissionTemplateActionApiMissionTemplateActionFindGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateActionApiFp(this.configuration).missionTemplateActionFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateActionApiMissionTemplateActionListByTemplateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateActionApi
     */
    public missionTemplateActionListByTemplateGet(requestParameters: MissionTemplateActionApiMissionTemplateActionListByTemplateGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateActionApiFp(this.configuration).missionTemplateActionListByTemplateGet(requestParameters.missionTemplateId, requestParameters.includeCommands, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateActionApiMissionTemplateActionSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateActionApi
     */
    public missionTemplateActionSavePost(requestParameters: MissionTemplateActionApiMissionTemplateActionSavePostRequest = {}, options?: AxiosRequestConfig) {
        return MissionTemplateActionApiFp(this.configuration).missionTemplateActionSavePost(requestParameters.missionTemplateActionSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MissionTemplateActionApiMissionTemplateActionSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionTemplateActionApi
     */
    public missionTemplateActionSearchGet(requestParameters: MissionTemplateActionApiMissionTemplateActionSearchGetRequest, options?: AxiosRequestConfig) {
        return MissionTemplateActionApiFp(this.configuration).missionTemplateActionSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeripheralApi - axios parameter creator
 * @export
 */
export const PeripheralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralFindGet', 'id', id)
            const localVarPath = `/Peripheral/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralListByRobotGet: async (robotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'robotId' is not null or undefined
            assertParamExists('peripheralListByRobotGet', 'robotId', robotId)
            const localVarPath = `/Peripheral/ListByRobot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeripheralSaveParams} [peripheralSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralSavePost: async (peripheralSaveParams?: PeripheralSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Peripheral/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralSearchGet: async (pi: number, ps: number, name?: string, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('peripheralSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('peripheralSearchGet', 'ps', ps)
            const localVarPath = `/Peripheral/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralApi - functional programming interface
 * @export
 */
export const PeripheralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralListByRobotGet(robotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeripheralDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralListByRobotGet(robotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeripheralSaveParams} [peripheralSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralSavePost(peripheralSaveParams?: PeripheralSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralSavePost(peripheralSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralSearchGet(pi: number, ps: number, name?: string, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralSearchGet(pi, ps, name, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralApi - factory interface
 * @export
 */
export const PeripheralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralFindGet(id: number, options?: any): AxiosPromise<PeripheralDto> {
            return localVarFp.peripheralFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralListByRobotGet(robotId: number, options?: any): AxiosPromise<Array<PeripheralDto>> {
            return localVarFp.peripheralListByRobotGet(robotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeripheralSaveParams} [peripheralSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralSavePost(peripheralSaveParams?: PeripheralSaveParams, options?: any): AxiosPromise<PeripheralDto> {
            return localVarFp.peripheralSavePost(peripheralSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralSearchGet(pi: number, ps: number, name?: string, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<PeripheralDtoSearchResponseDto> {
            return localVarFp.peripheralSearchGet(pi, ps, name, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralFindGet operation in PeripheralApi.
 * @export
 * @interface PeripheralApiPeripheralFindGetRequest
 */
export interface PeripheralApiPeripheralFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralApiPeripheralFindGet
     */
    readonly id: number
}

/**
 * Request parameters for peripheralListByRobotGet operation in PeripheralApi.
 * @export
 * @interface PeripheralApiPeripheralListByRobotGetRequest
 */
export interface PeripheralApiPeripheralListByRobotGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralApiPeripheralListByRobotGet
     */
    readonly robotId: number
}

/**
 * Request parameters for peripheralSavePost operation in PeripheralApi.
 * @export
 * @interface PeripheralApiPeripheralSavePostRequest
 */
export interface PeripheralApiPeripheralSavePostRequest {
    /**
     * 
     * @type {PeripheralSaveParams}
     * @memberof PeripheralApiPeripheralSavePost
     */
    readonly peripheralSaveParams?: PeripheralSaveParams
}

/**
 * Request parameters for peripheralSearchGet operation in PeripheralApi.
 * @export
 * @interface PeripheralApiPeripheralSearchGetRequest
 */
export interface PeripheralApiPeripheralSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralApiPeripheralSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * PeripheralApi - object-oriented interface
 * @export
 * @class PeripheralApi
 * @extends {BaseAPI}
 */
export class PeripheralApi extends BaseAPI {
    /**
     * 
     * @param {PeripheralApiPeripheralFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralApi
     */
    public peripheralFindGet(requestParameters: PeripheralApiPeripheralFindGetRequest, options?: AxiosRequestConfig) {
        return PeripheralApiFp(this.configuration).peripheralFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralApiPeripheralListByRobotGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralApi
     */
    public peripheralListByRobotGet(requestParameters: PeripheralApiPeripheralListByRobotGetRequest, options?: AxiosRequestConfig) {
        return PeripheralApiFp(this.configuration).peripheralListByRobotGet(requestParameters.robotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralApiPeripheralSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralApi
     */
    public peripheralSavePost(requestParameters: PeripheralApiPeripheralSavePostRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralApiFp(this.configuration).peripheralSavePost(requestParameters.peripheralSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralApiPeripheralSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralApi
     */
    public peripheralSearchGet(requestParameters: PeripheralApiPeripheralSearchGetRequest, options?: AxiosRequestConfig) {
        return PeripheralApiFp(this.configuration).peripheralSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.name, requestParameters.robotId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeripheralAbilityApi - axios parameter creator
 * @export
 */
export const PeripheralAbilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAbilityFindGet', 'id', id)
            const localVarPath = `/PeripheralAbility/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeripheralAbilitySaveParams} [peripheralAbilitySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilitySavePost: async (peripheralAbilitySaveParams?: PeripheralAbilitySaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PeripheralAbility/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralAbilitySaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilitySearchGet: async (pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('peripheralAbilitySearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('peripheralAbilitySearchGet', 'ps', ps)
            const localVarPath = `/PeripheralAbility/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralAbilityApi - functional programming interface
 * @export
 */
export const PeripheralAbilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralAbilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilityFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilityFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeripheralAbilitySaveParams} [peripheralAbilitySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilitySavePost(peripheralAbilitySaveParams?: PeripheralAbilitySaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilitySavePost(peripheralAbilitySaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilitySearchGet(pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilitySearchGet(pi, ps, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralAbilityApi - factory interface
 * @export
 */
export const PeripheralAbilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralAbilityApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityFindGet(id: number, options?: any): AxiosPromise<PeripheralAbilityDto> {
            return localVarFp.peripheralAbilityFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeripheralAbilitySaveParams} [peripheralAbilitySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilitySavePost(peripheralAbilitySaveParams?: PeripheralAbilitySaveParams, options?: any): AxiosPromise<PeripheralAbilityDto> {
            return localVarFp.peripheralAbilitySavePost(peripheralAbilitySaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilitySearchGet(pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<PeripheralAbilityDtoSearchResponseDto> {
            return localVarFp.peripheralAbilitySearchGet(pi, ps, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralAbilityFindGet operation in PeripheralAbilityApi.
 * @export
 * @interface PeripheralAbilityApiPeripheralAbilityFindGetRequest
 */
export interface PeripheralAbilityApiPeripheralAbilityFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityApiPeripheralAbilityFindGet
     */
    readonly id: number
}

/**
 * Request parameters for peripheralAbilitySavePost operation in PeripheralAbilityApi.
 * @export
 * @interface PeripheralAbilityApiPeripheralAbilitySavePostRequest
 */
export interface PeripheralAbilityApiPeripheralAbilitySavePostRequest {
    /**
     * 
     * @type {PeripheralAbilitySaveParams}
     * @memberof PeripheralAbilityApiPeripheralAbilitySavePost
     */
    readonly peripheralAbilitySaveParams?: PeripheralAbilitySaveParams
}

/**
 * Request parameters for peripheralAbilitySearchGet operation in PeripheralAbilityApi.
 * @export
 * @interface PeripheralAbilityApiPeripheralAbilitySearchGetRequest
 */
export interface PeripheralAbilityApiPeripheralAbilitySearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityApiPeripheralAbilitySearchGet
     */
    readonly isDelete?: boolean
}

/**
 * PeripheralAbilityApi - object-oriented interface
 * @export
 * @class PeripheralAbilityApi
 * @extends {BaseAPI}
 */
export class PeripheralAbilityApi extends BaseAPI {
    /**
     * 
     * @param {PeripheralAbilityApiPeripheralAbilityFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityApi
     */
    public peripheralAbilityFindGet(requestParameters: PeripheralAbilityApiPeripheralAbilityFindGetRequest, options?: AxiosRequestConfig) {
        return PeripheralAbilityApiFp(this.configuration).peripheralAbilityFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralAbilityApiPeripheralAbilitySavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityApi
     */
    public peripheralAbilitySavePost(requestParameters: PeripheralAbilityApiPeripheralAbilitySavePostRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAbilityApiFp(this.configuration).peripheralAbilitySavePost(requestParameters.peripheralAbilitySaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralAbilityApiPeripheralAbilitySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityApi
     */
    public peripheralAbilitySearchGet(requestParameters: PeripheralAbilityApiPeripheralAbilitySearchGetRequest, options?: AxiosRequestConfig) {
        return PeripheralAbilityApiFp(this.configuration).peripheralAbilitySearchGet(requestParameters.pi, requestParameters.ps, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeripheralAbilityCommandApi - axios parameter creator
 * @export
 */
export const PeripheralAbilityCommandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralAbilityCommandFindGet', 'id', id)
            const localVarPath = `/PeripheralAbilityCommand/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeripheralAbilityCommandSaveParams} [peripheralAbilityCommandSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandSavePost: async (peripheralAbilityCommandSaveParams?: PeripheralAbilityCommandSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PeripheralAbilityCommand/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralAbilityCommandSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [peripheralAbilityId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandSearchGet: async (pi: number, ps: number, name?: string, peripheralAbilityId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('peripheralAbilityCommandSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('peripheralAbilityCommandSearchGet', 'ps', ps)
            const localVarPath = `/PeripheralAbilityCommand/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (peripheralAbilityId !== undefined) {
                localVarQueryParameter['PeripheralAbilityId'] = peripheralAbilityId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralAbilityCommandApi - functional programming interface
 * @export
 */
export const PeripheralAbilityCommandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralAbilityCommandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilityCommandFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityCommandDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilityCommandFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeripheralAbilityCommandSaveParams} [peripheralAbilityCommandSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilityCommandSavePost(peripheralAbilityCommandSaveParams?: PeripheralAbilityCommandSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityCommandDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilityCommandSavePost(peripheralAbilityCommandSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [peripheralAbilityId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralAbilityCommandSearchGet(pi: number, ps: number, name?: string, peripheralAbilityId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralAbilityCommandDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralAbilityCommandSearchGet(pi, ps, name, peripheralAbilityId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralAbilityCommandApi - factory interface
 * @export
 */
export const PeripheralAbilityCommandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralAbilityCommandApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandFindGet(id: number, options?: any): AxiosPromise<PeripheralAbilityCommandDto> {
            return localVarFp.peripheralAbilityCommandFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeripheralAbilityCommandSaveParams} [peripheralAbilityCommandSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandSavePost(peripheralAbilityCommandSaveParams?: PeripheralAbilityCommandSaveParams, options?: any): AxiosPromise<PeripheralAbilityCommandDto> {
            return localVarFp.peripheralAbilityCommandSavePost(peripheralAbilityCommandSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {number} [peripheralAbilityId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralAbilityCommandSearchGet(pi: number, ps: number, name?: string, peripheralAbilityId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<PeripheralAbilityCommandDtoSearchResponseDto> {
            return localVarFp.peripheralAbilityCommandSearchGet(pi, ps, name, peripheralAbilityId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralAbilityCommandFindGet operation in PeripheralAbilityCommandApi.
 * @export
 * @interface PeripheralAbilityCommandApiPeripheralAbilityCommandFindGetRequest
 */
export interface PeripheralAbilityCommandApiPeripheralAbilityCommandFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandFindGet
     */
    readonly id: number
}

/**
 * Request parameters for peripheralAbilityCommandSavePost operation in PeripheralAbilityCommandApi.
 * @export
 * @interface PeripheralAbilityCommandApiPeripheralAbilityCommandSavePostRequest
 */
export interface PeripheralAbilityCommandApiPeripheralAbilityCommandSavePostRequest {
    /**
     * 
     * @type {PeripheralAbilityCommandSaveParams}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSavePost
     */
    readonly peripheralAbilityCommandSaveParams?: PeripheralAbilityCommandSaveParams
}

/**
 * Request parameters for peripheralAbilityCommandSearchGet operation in PeripheralAbilityCommandApi.
 * @export
 * @interface PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGetRequest
 */
export interface PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly peripheralAbilityId?: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * PeripheralAbilityCommandApi - object-oriented interface
 * @export
 * @class PeripheralAbilityCommandApi
 * @extends {BaseAPI}
 */
export class PeripheralAbilityCommandApi extends BaseAPI {
    /**
     * 
     * @param {PeripheralAbilityCommandApiPeripheralAbilityCommandFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityCommandApi
     */
    public peripheralAbilityCommandFindGet(requestParameters: PeripheralAbilityCommandApiPeripheralAbilityCommandFindGetRequest, options?: AxiosRequestConfig) {
        return PeripheralAbilityCommandApiFp(this.configuration).peripheralAbilityCommandFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralAbilityCommandApiPeripheralAbilityCommandSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityCommandApi
     */
    public peripheralAbilityCommandSavePost(requestParameters: PeripheralAbilityCommandApiPeripheralAbilityCommandSavePostRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralAbilityCommandApiFp(this.configuration).peripheralAbilityCommandSavePost(requestParameters.peripheralAbilityCommandSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralAbilityCommandApi
     */
    public peripheralAbilityCommandSearchGet(requestParameters: PeripheralAbilityCommandApiPeripheralAbilityCommandSearchGetRequest, options?: AxiosRequestConfig) {
        return PeripheralAbilityCommandApiFp(this.configuration).peripheralAbilityCommandSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.name, requestParameters.peripheralAbilityId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeripheralHistoryApi - axios parameter creator
 * @export
 */
export const PeripheralHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistoryFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('peripheralHistoryFindGet', 'id', id)
            const localVarPath = `/PeripheralHistory/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeripheralHistoryRemoveParams} [peripheralHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistoryRemovePost: async (peripheralHistoryRemoveParams?: PeripheralHistoryRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PeripheralHistory/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralHistoryRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeripheralHistorySaveParams} [peripheralHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistorySavePost: async (peripheralHistorySaveParams?: PeripheralHistorySaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/PeripheralHistory/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peripheralHistorySaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [peripheralId] 
         * @param {string} [status] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistorySearchGet: async (pi: number, ps: number, peripheralId?: number, status?: string, from?: string, to?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('peripheralHistorySearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('peripheralHistorySearchGet', 'ps', ps)
            const localVarPath = `/PeripheralHistory/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (peripheralId !== undefined) {
                localVarQueryParameter['PeripheralId'] = peripheralId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeripheralHistoryApi - functional programming interface
 * @export
 */
export const PeripheralHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeripheralHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralHistoryFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralHistoryFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeripheralHistoryRemoveParams} [peripheralHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralHistoryRemovePost(peripheralHistoryRemoveParams?: PeripheralHistoryRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralHistoryRemovePost(peripheralHistoryRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeripheralHistorySaveParams} [peripheralHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralHistorySavePost(peripheralHistorySaveParams?: PeripheralHistorySaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralHistorySavePost(peripheralHistorySaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [peripheralId] 
         * @param {string} [status] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async peripheralHistorySearchGet(pi: number, ps: number, peripheralId?: number, status?: string, from?: string, to?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PeripheralHistoryDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.peripheralHistorySearchGet(pi, ps, peripheralId, status, from, to, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeripheralHistoryApi - factory interface
 * @export
 */
export const PeripheralHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeripheralHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistoryFindGet(id: number, options?: any): AxiosPromise<PeripheralHistoryDto> {
            return localVarFp.peripheralHistoryFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeripheralHistoryRemoveParams} [peripheralHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistoryRemovePost(peripheralHistoryRemoveParams?: PeripheralHistoryRemoveParams, options?: any): AxiosPromise<PeripheralHistoryDto> {
            return localVarFp.peripheralHistoryRemovePost(peripheralHistoryRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeripheralHistorySaveParams} [peripheralHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistorySavePost(peripheralHistorySaveParams?: PeripheralHistorySaveParams, options?: any): AxiosPromise<PeripheralHistoryDto> {
            return localVarFp.peripheralHistorySavePost(peripheralHistorySaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [peripheralId] 
         * @param {string} [status] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        peripheralHistorySearchGet(pi: number, ps: number, peripheralId?: number, status?: string, from?: string, to?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<PeripheralHistoryDtoSearchResponseDto> {
            return localVarFp.peripheralHistorySearchGet(pi, ps, peripheralId, status, from, to, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for peripheralHistoryFindGet operation in PeripheralHistoryApi.
 * @export
 * @interface PeripheralHistoryApiPeripheralHistoryFindGetRequest
 */
export interface PeripheralHistoryApiPeripheralHistoryFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryApiPeripheralHistoryFindGet
     */
    readonly id: number
}

/**
 * Request parameters for peripheralHistoryRemovePost operation in PeripheralHistoryApi.
 * @export
 * @interface PeripheralHistoryApiPeripheralHistoryRemovePostRequest
 */
export interface PeripheralHistoryApiPeripheralHistoryRemovePostRequest {
    /**
     * 
     * @type {PeripheralHistoryRemoveParams}
     * @memberof PeripheralHistoryApiPeripheralHistoryRemovePost
     */
    readonly peripheralHistoryRemoveParams?: PeripheralHistoryRemoveParams
}

/**
 * Request parameters for peripheralHistorySavePost operation in PeripheralHistoryApi.
 * @export
 * @interface PeripheralHistoryApiPeripheralHistorySavePostRequest
 */
export interface PeripheralHistoryApiPeripheralHistorySavePostRequest {
    /**
     * 
     * @type {PeripheralHistorySaveParams}
     * @memberof PeripheralHistoryApiPeripheralHistorySavePost
     */
    readonly peripheralHistorySaveParams?: PeripheralHistorySaveParams
}

/**
 * Request parameters for peripheralHistorySearchGet operation in PeripheralHistoryApi.
 * @export
 * @interface PeripheralHistoryApiPeripheralHistorySearchGetRequest
 */
export interface PeripheralHistoryApiPeripheralHistorySearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly peripheralId?: number

    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof PeripheralHistoryApiPeripheralHistorySearchGet
     */
    readonly isDelete?: boolean
}

/**
 * PeripheralHistoryApi - object-oriented interface
 * @export
 * @class PeripheralHistoryApi
 * @extends {BaseAPI}
 */
export class PeripheralHistoryApi extends BaseAPI {
    /**
     * 
     * @param {PeripheralHistoryApiPeripheralHistoryFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralHistoryApi
     */
    public peripheralHistoryFindGet(requestParameters: PeripheralHistoryApiPeripheralHistoryFindGetRequest, options?: AxiosRequestConfig) {
        return PeripheralHistoryApiFp(this.configuration).peripheralHistoryFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralHistoryApiPeripheralHistoryRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralHistoryApi
     */
    public peripheralHistoryRemovePost(requestParameters: PeripheralHistoryApiPeripheralHistoryRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralHistoryApiFp(this.configuration).peripheralHistoryRemovePost(requestParameters.peripheralHistoryRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralHistoryApiPeripheralHistorySavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralHistoryApi
     */
    public peripheralHistorySavePost(requestParameters: PeripheralHistoryApiPeripheralHistorySavePostRequest = {}, options?: AxiosRequestConfig) {
        return PeripheralHistoryApiFp(this.configuration).peripheralHistorySavePost(requestParameters.peripheralHistorySaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeripheralHistoryApiPeripheralHistorySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeripheralHistoryApi
     */
    public peripheralHistorySearchGet(requestParameters: PeripheralHistoryApiPeripheralHistorySearchGetRequest, options?: AxiosRequestConfig) {
        return PeripheralHistoryApiFp(this.configuration).peripheralHistorySearchGet(requestParameters.pi, requestParameters.ps, requestParameters.peripheralId, requestParameters.status, requestParameters.from, requestParameters.to, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionApi - axios parameter creator
 * @export
 */
export const PositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionBriefSearchGet: async (from: string, to: string, robotId?: number, floorId?: number, s?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('positionBriefSearchGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('positionBriefSearchGet', 'to', to)
            const localVarPath = `/Position/BriefSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (floorId !== undefined) {
                localVarQueryParameter['FloorId'] = floorId;
            }

            if (s !== undefined) {
                localVarQueryParameter['S'] = s;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionListByKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('positionListByKeyGet', 'key', key)
            const localVarPath = `/Position/ListByKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['Key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PositionSaveParams} [positionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSavePositionPost: async (positionSaveParams?: PositionSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Position/SavePosition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSearchGet: async (from: string, to: string, pi: number, ps: number, robotId?: number, floorId?: number, s?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('positionSearchGet', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('positionSearchGet', 'to', to)
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('positionSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('positionSearchGet', 'ps', ps)
            const localVarPath = `/Position/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (floorId !== undefined) {
                localVarQueryParameter['FloorId'] = floorId;
            }

            if (s !== undefined) {
                localVarQueryParameter['S'] = s;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} floorId 
         * @param {number} robotId 
         * @param {boolean} [isCalculatePosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSearchHistoryByFloorGet: async (floorId: number, robotId: number, isCalculatePosition?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'floorId' is not null or undefined
            assertParamExists('positionSearchHistoryByFloorGet', 'floorId', floorId)
            // verify required parameter 'robotId' is not null or undefined
            assertParamExists('positionSearchHistoryByFloorGet', 'robotId', robotId)
            const localVarPath = `/Position/SearchHistoryByFloor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (floorId !== undefined) {
                localVarQueryParameter['FloorId'] = floorId;
            }

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (isCalculatePosition !== undefined) {
                localVarQueryParameter['IsCalculatePosition'] = isCalculatePosition;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionApi - functional programming interface
 * @export
 */
export const PositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionBriefSearchGet(from: string, to: string, robotId?: number, floorId?: number, s?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PositionBriefSearchItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionBriefSearchGet(from, to, robotId, floorId, s, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionListByKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionSearchHistoryListByKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionListByKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PositionSaveParams} [positionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionSavePositionPost(positionSaveParams?: PositionSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotPositionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionSavePositionPost(positionSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionSearchGet(from: string, to: string, pi: number, ps: number, robotId?: number, floorId?: number, s?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotPositionHistoryDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionSearchGet(from, to, pi, ps, robotId, floorId, s, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} floorId 
         * @param {number} robotId 
         * @param {boolean} [isCalculatePosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionSearchHistoryByFloorGet(floorId: number, robotId: number, isCalculatePosition?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IMapPosition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionSearchHistoryByFloorGet(floorId, robotId, isCalculatePosition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionApi - factory interface
 * @export
 */
export const PositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionBriefSearchGet(from: string, to: string, robotId?: number, floorId?: number, s?: string, options?: any): AxiosPromise<Array<PositionBriefSearchItemDto>> {
            return localVarFp.positionBriefSearchGet(from, to, robotId, floorId, s, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionListByKeyGet(key: string, options?: any): AxiosPromise<PositionSearchHistoryListByKeyResponse> {
            return localVarFp.positionListByKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PositionSaveParams} [positionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSavePositionPost(positionSaveParams?: PositionSaveParams, options?: any): AxiosPromise<RobotPositionDto> {
            return localVarFp.positionSavePositionPost(positionSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} from 
         * @param {string} to 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {number} [floorId] 
         * @param {string} [s] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSearchGet(from: string, to: string, pi: number, ps: number, robotId?: number, floorId?: number, s?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<RobotPositionHistoryDtoSearchResponseDto> {
            return localVarFp.positionSearchGet(from, to, pi, ps, robotId, floorId, s, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} floorId 
         * @param {number} robotId 
         * @param {boolean} [isCalculatePosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionSearchHistoryByFloorGet(floorId: number, robotId: number, isCalculatePosition?: boolean, options?: any): AxiosPromise<Array<IMapPosition>> {
            return localVarFp.positionSearchHistoryByFloorGet(floorId, robotId, isCalculatePosition, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for positionBriefSearchGet operation in PositionApi.
 * @export
 * @interface PositionApiPositionBriefSearchGetRequest
 */
export interface PositionApiPositionBriefSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionBriefSearchGet
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionBriefSearchGet
     */
    readonly to: string

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionBriefSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionBriefSearchGet
     */
    readonly floorId?: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionBriefSearchGet
     */
    readonly s?: string
}

/**
 * Request parameters for positionListByKeyGet operation in PositionApi.
 * @export
 * @interface PositionApiPositionListByKeyGetRequest
 */
export interface PositionApiPositionListByKeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionListByKeyGet
     */
    readonly key: string
}

/**
 * Request parameters for positionSavePositionPost operation in PositionApi.
 * @export
 * @interface PositionApiPositionSavePositionPostRequest
 */
export interface PositionApiPositionSavePositionPostRequest {
    /**
     * 
     * @type {PositionSaveParams}
     * @memberof PositionApiPositionSavePositionPost
     */
    readonly positionSaveParams?: PositionSaveParams
}

/**
 * Request parameters for positionSearchGet operation in PositionApi.
 * @export
 * @interface PositionApiPositionSearchGetRequest
 */
export interface PositionApiPositionSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionSearchGet
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionSearchGet
     */
    readonly to: string

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchGet
     */
    readonly floorId?: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionSearchGet
     */
    readonly s?: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiPositionSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof PositionApiPositionSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof PositionApiPositionSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof PositionApiPositionSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for positionSearchHistoryByFloorGet operation in PositionApi.
 * @export
 * @interface PositionApiPositionSearchHistoryByFloorGetRequest
 */
export interface PositionApiPositionSearchHistoryByFloorGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchHistoryByFloorGet
     */
    readonly floorId: number

    /**
     * 
     * @type {number}
     * @memberof PositionApiPositionSearchHistoryByFloorGet
     */
    readonly robotId: number

    /**
     * 
     * @type {boolean}
     * @memberof PositionApiPositionSearchHistoryByFloorGet
     */
    readonly isCalculatePosition?: boolean
}

/**
 * PositionApi - object-oriented interface
 * @export
 * @class PositionApi
 * @extends {BaseAPI}
 */
export class PositionApi extends BaseAPI {
    /**
     * 
     * @param {PositionApiPositionBriefSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public positionBriefSearchGet(requestParameters: PositionApiPositionBriefSearchGetRequest, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).positionBriefSearchGet(requestParameters.from, requestParameters.to, requestParameters.robotId, requestParameters.floorId, requestParameters.s, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PositionApiPositionListByKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public positionListByKeyGet(requestParameters: PositionApiPositionListByKeyGetRequest, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).positionListByKeyGet(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PositionApiPositionSavePositionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public positionSavePositionPost(requestParameters: PositionApiPositionSavePositionPostRequest = {}, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).positionSavePositionPost(requestParameters.positionSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PositionApiPositionSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public positionSearchGet(requestParameters: PositionApiPositionSearchGetRequest, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).positionSearchGet(requestParameters.from, requestParameters.to, requestParameters.pi, requestParameters.ps, requestParameters.robotId, requestParameters.floorId, requestParameters.s, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PositionApiPositionSearchHistoryByFloorGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public positionSearchHistoryByFloorGet(requestParameters: PositionApiPositionSearchHistoryByFloorGetRequest, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).positionSearchHistoryByFloorGet(requestParameters.floorId, requestParameters.robotId, requestParameters.isCalculatePosition, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotApi - axios parameter creator
 * @export
 */
export const RobotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('robotFindGet', 'id', id)
            const localVarPath = `/Robot/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [p] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFirstGet: async (p?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Robot/First`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (p !== undefined) {
                localVarQueryParameter['p'] = p;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotGetCurrentMissionGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('robotGetCurrentMissionGet', 'id', id)
            const localVarPath = `/Robot/GetCurrentMission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotSaveParams} [robotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSavePost: async (robotSaveParams?: RobotSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Robot/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSearchGet: async (pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('robotSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('robotSearchGet', 'ps', ps)
            const localVarPath = `/Robot/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotApi - functional programming interface
 * @export
 */
export const RobotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [p] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFirstGet(p?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFirstGet(p, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotGetCurrentMissionGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotGetCurrentMissionGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotSaveParams} [robotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotSavePost(robotSaveParams?: RobotSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotSavePost(robotSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotSearchGet(pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotSearchGet(pi, ps, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotApi - factory interface
 * @export
 */
export const RobotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFindGet(id: number, options?: any): AxiosPromise<RobotDto> {
            return localVarFp.robotFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [p] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFirstGet(p?: object, options?: any): AxiosPromise<RobotDto> {
            return localVarFp.robotFirstGet(p, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotGetCurrentMissionGet(id: number, options?: any): AxiosPromise<OperateResultDto> {
            return localVarFp.robotGetCurrentMissionGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotSaveParams} [robotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSavePost(robotSaveParams?: RobotSaveParams, options?: any): AxiosPromise<RobotDto> {
            return localVarFp.robotSavePost(robotSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSearchGet(pi: number, ps: number, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<RobotDtoSearchResponseDto> {
            return localVarFp.robotSearchGet(pi, ps, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for robotFindGet operation in RobotApi.
 * @export
 * @interface RobotApiRobotFindGetRequest
 */
export interface RobotApiRobotFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotApiRobotFindGet
     */
    readonly id: number
}

/**
 * Request parameters for robotFirstGet operation in RobotApi.
 * @export
 * @interface RobotApiRobotFirstGetRequest
 */
export interface RobotApiRobotFirstGetRequest {
    /**
     * 
     * @type {object}
     * @memberof RobotApiRobotFirstGet
     */
    readonly p?: object
}

/**
 * Request parameters for robotGetCurrentMissionGet operation in RobotApi.
 * @export
 * @interface RobotApiRobotGetCurrentMissionGetRequest
 */
export interface RobotApiRobotGetCurrentMissionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotApiRobotGetCurrentMissionGet
     */
    readonly id: number
}

/**
 * Request parameters for robotSavePost operation in RobotApi.
 * @export
 * @interface RobotApiRobotSavePostRequest
 */
export interface RobotApiRobotSavePostRequest {
    /**
     * 
     * @type {RobotSaveParams}
     * @memberof RobotApiRobotSavePost
     */
    readonly robotSaveParams?: RobotSaveParams
}

/**
 * Request parameters for robotSearchGet operation in RobotApi.
 * @export
 * @interface RobotApiRobotSearchGetRequest
 */
export interface RobotApiRobotSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotApiRobotSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof RobotApiRobotSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof RobotApiRobotSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof RobotApiRobotSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof RobotApiRobotSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof RobotApiRobotSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof RobotApiRobotSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * RobotApi - object-oriented interface
 * @export
 * @class RobotApi
 * @extends {BaseAPI}
 */
export class RobotApi extends BaseAPI {
    /**
     * 
     * @param {RobotApiRobotFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public robotFindGet(requestParameters: RobotApiRobotFindGetRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).robotFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotApiRobotFirstGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public robotFirstGet(requestParameters: RobotApiRobotFirstGetRequest = {}, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).robotFirstGet(requestParameters.p, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotApiRobotGetCurrentMissionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public robotGetCurrentMissionGet(requestParameters: RobotApiRobotGetCurrentMissionGetRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).robotGetCurrentMissionGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotApiRobotSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public robotSavePost(requestParameters: RobotApiRobotSavePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).robotSavePost(requestParameters.robotSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotApiRobotSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotApi
     */
    public robotSearchGet(requestParameters: RobotApiRobotSearchGetRequest, options?: AxiosRequestConfig) {
        return RobotApiFp(this.configuration).robotSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotFunctionApi - axios parameter creator
 * @export
 */
export const RobotFunctionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('robotFunctionFindGet', 'id', id)
            const localVarPath = `/RobotFunction/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionListByRobotGet: async (robotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'robotId' is not null or undefined
            assertParamExists('robotFunctionListByRobotGet', 'robotId', robotId)
            const localVarPath = `/RobotFunction/ListByRobot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotFunctionRemoveParams} [robotFunctionRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionRemovePost: async (robotFunctionRemoveParams?: RobotFunctionRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotFunction/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotFunctionRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotFunctionSaveParams} [robotFunctionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionSavePost: async (robotFunctionSaveParams?: RobotFunctionSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotFunction/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotFunctionSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('robotFunctionSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('robotFunctionSearchGet', 'ps', ps)
            const localVarPath = `/RobotFunction/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotFunctionApi - functional programming interface
 * @export
 */
export const RobotFunctionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotFunctionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFunctionFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFunctionFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFunctionListByRobotGet(robotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RobotFunctionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFunctionListByRobotGet(robotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotFunctionRemoveParams} [robotFunctionRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFunctionRemovePost(robotFunctionRemoveParams?: RobotFunctionRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFunctionRemovePost(robotFunctionRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotFunctionSaveParams} [robotFunctionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFunctionSavePost(robotFunctionSaveParams?: RobotFunctionSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotFunctionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFunctionSavePost(robotFunctionSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotFunctionSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotFunctionDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotFunctionSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotFunctionApi - factory interface
 * @export
 */
export const RobotFunctionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotFunctionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionFindGet(id: number, options?: any): AxiosPromise<RobotFunctionDto> {
            return localVarFp.robotFunctionFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} robotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionListByRobotGet(robotId: number, options?: any): AxiosPromise<Array<RobotFunctionDto>> {
            return localVarFp.robotFunctionListByRobotGet(robotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotFunctionRemoveParams} [robotFunctionRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionRemovePost(robotFunctionRemoveParams?: RobotFunctionRemoveParams, options?: any): AxiosPromise<RobotFunctionDto> {
            return localVarFp.robotFunctionRemovePost(robotFunctionRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotFunctionSaveParams} [robotFunctionSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionSavePost(robotFunctionSaveParams?: RobotFunctionSaveParams, options?: any): AxiosPromise<RobotFunctionDto> {
            return localVarFp.robotFunctionSavePost(robotFunctionSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotFunctionSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<RobotFunctionDtoSearchResponseDto> {
            return localVarFp.robotFunctionSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for robotFunctionFindGet operation in RobotFunctionApi.
 * @export
 * @interface RobotFunctionApiRobotFunctionFindGetRequest
 */
export interface RobotFunctionApiRobotFunctionFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionApiRobotFunctionFindGet
     */
    readonly id: number
}

/**
 * Request parameters for robotFunctionListByRobotGet operation in RobotFunctionApi.
 * @export
 * @interface RobotFunctionApiRobotFunctionListByRobotGetRequest
 */
export interface RobotFunctionApiRobotFunctionListByRobotGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionApiRobotFunctionListByRobotGet
     */
    readonly robotId: number
}

/**
 * Request parameters for robotFunctionRemovePost operation in RobotFunctionApi.
 * @export
 * @interface RobotFunctionApiRobotFunctionRemovePostRequest
 */
export interface RobotFunctionApiRobotFunctionRemovePostRequest {
    /**
     * 
     * @type {RobotFunctionRemoveParams}
     * @memberof RobotFunctionApiRobotFunctionRemovePost
     */
    readonly robotFunctionRemoveParams?: RobotFunctionRemoveParams
}

/**
 * Request parameters for robotFunctionSavePost operation in RobotFunctionApi.
 * @export
 * @interface RobotFunctionApiRobotFunctionSavePostRequest
 */
export interface RobotFunctionApiRobotFunctionSavePostRequest {
    /**
     * 
     * @type {RobotFunctionSaveParams}
     * @memberof RobotFunctionApiRobotFunctionSavePost
     */
    readonly robotFunctionSaveParams?: RobotFunctionSaveParams
}

/**
 * Request parameters for robotFunctionSearchGet operation in RobotFunctionApi.
 * @export
 * @interface RobotFunctionApiRobotFunctionSearchGetRequest
 */
export interface RobotFunctionApiRobotFunctionSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof RobotFunctionApiRobotFunctionSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * RobotFunctionApi - object-oriented interface
 * @export
 * @class RobotFunctionApi
 * @extends {BaseAPI}
 */
export class RobotFunctionApi extends BaseAPI {
    /**
     * 
     * @param {RobotFunctionApiRobotFunctionFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotFunctionApi
     */
    public robotFunctionFindGet(requestParameters: RobotFunctionApiRobotFunctionFindGetRequest, options?: AxiosRequestConfig) {
        return RobotFunctionApiFp(this.configuration).robotFunctionFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotFunctionApiRobotFunctionListByRobotGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotFunctionApi
     */
    public robotFunctionListByRobotGet(requestParameters: RobotFunctionApiRobotFunctionListByRobotGetRequest, options?: AxiosRequestConfig) {
        return RobotFunctionApiFp(this.configuration).robotFunctionListByRobotGet(requestParameters.robotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotFunctionApiRobotFunctionRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotFunctionApi
     */
    public robotFunctionRemovePost(requestParameters: RobotFunctionApiRobotFunctionRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotFunctionApiFp(this.configuration).robotFunctionRemovePost(requestParameters.robotFunctionRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotFunctionApiRobotFunctionSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotFunctionApi
     */
    public robotFunctionSavePost(requestParameters: RobotFunctionApiRobotFunctionSavePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotFunctionApiFp(this.configuration).robotFunctionSavePost(requestParameters.robotFunctionSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotFunctionApiRobotFunctionSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotFunctionApi
     */
    public robotFunctionSearchGet(requestParameters: RobotFunctionApiRobotFunctionSearchGetRequest, options?: AxiosRequestConfig) {
        return RobotFunctionApiFp(this.configuration).robotFunctionSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotMessageApi - axios parameter creator
 * @export
 */
export const RobotMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RobotMessageExportParams} [robotMessageExportParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageExportPost: async (robotMessageExportParams?: RobotMessageExportParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotMessage/Export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotMessageExportParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('robotMessageFindGet', 'id', id)
            const localVarPath = `/RobotMessage/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotMessageRemoveParams} [robotMessageRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageRemovePost: async (robotMessageRemoveParams?: RobotMessageRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotMessage/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotMessageRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [packageId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageSearchGet: async (pi: number, ps: number, robotId?: number, packageId?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('robotMessageSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('robotMessageSearchGet', 'ps', ps)
            const localVarPath = `/RobotMessage/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['PackageId'] = packageId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotMessageApi - functional programming interface
 * @export
 */
export const RobotMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RobotMessageExportParams} [robotMessageExportParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotMessageExportPost(robotMessageExportParams?: RobotMessageExportParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OperateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotMessageExportPost(robotMessageExportParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotMessageFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotMessageFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotMessageRemoveParams} [robotMessageRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotMessageRemovePost(robotMessageRemoveParams?: RobotMessageRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotMessageRemovePost(robotMessageRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [packageId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotMessageSearchGet(pi: number, ps: number, robotId?: number, packageId?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotMessageDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotMessageSearchGet(pi, ps, robotId, packageId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotMessageApi - factory interface
 * @export
 */
export const RobotMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {RobotMessageExportParams} [robotMessageExportParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageExportPost(robotMessageExportParams?: RobotMessageExportParams, options?: any): AxiosPromise<OperateResultDto> {
            return localVarFp.robotMessageExportPost(robotMessageExportParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageFindGet(id: number, options?: any): AxiosPromise<RobotMessageDto> {
            return localVarFp.robotMessageFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotMessageRemoveParams} [robotMessageRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageRemovePost(robotMessageRemoveParams?: RobotMessageRemoveParams, options?: any): AxiosPromise<RobotMessageDto> {
            return localVarFp.robotMessageRemovePost(robotMessageRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [packageId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotMessageSearchGet(pi: number, ps: number, robotId?: number, packageId?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<RobotMessageDtoSearchResponseDto> {
            return localVarFp.robotMessageSearchGet(pi, ps, robotId, packageId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for robotMessageExportPost operation in RobotMessageApi.
 * @export
 * @interface RobotMessageApiRobotMessageExportPostRequest
 */
export interface RobotMessageApiRobotMessageExportPostRequest {
    /**
     * 
     * @type {RobotMessageExportParams}
     * @memberof RobotMessageApiRobotMessageExportPost
     */
    readonly robotMessageExportParams?: RobotMessageExportParams
}

/**
 * Request parameters for robotMessageFindGet operation in RobotMessageApi.
 * @export
 * @interface RobotMessageApiRobotMessageFindGetRequest
 */
export interface RobotMessageApiRobotMessageFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotMessageApiRobotMessageFindGet
     */
    readonly id: number
}

/**
 * Request parameters for robotMessageRemovePost operation in RobotMessageApi.
 * @export
 * @interface RobotMessageApiRobotMessageRemovePostRequest
 */
export interface RobotMessageApiRobotMessageRemovePostRequest {
    /**
     * 
     * @type {RobotMessageRemoveParams}
     * @memberof RobotMessageApiRobotMessageRemovePost
     */
    readonly robotMessageRemoveParams?: RobotMessageRemoveParams
}

/**
 * Request parameters for robotMessageSearchGet operation in RobotMessageApi.
 * @export
 * @interface RobotMessageApiRobotMessageSearchGetRequest
 */
export interface RobotMessageApiRobotMessageSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {string}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly packageId?: string

    /**
     * 
     * @type {string}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof RobotMessageApiRobotMessageSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * RobotMessageApi - object-oriented interface
 * @export
 * @class RobotMessageApi
 * @extends {BaseAPI}
 */
export class RobotMessageApi extends BaseAPI {
    /**
     * 
     * @param {RobotMessageApiRobotMessageExportPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotMessageApi
     */
    public robotMessageExportPost(requestParameters: RobotMessageApiRobotMessageExportPostRequest = {}, options?: AxiosRequestConfig) {
        return RobotMessageApiFp(this.configuration).robotMessageExportPost(requestParameters.robotMessageExportParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotMessageApiRobotMessageFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotMessageApi
     */
    public robotMessageFindGet(requestParameters: RobotMessageApiRobotMessageFindGetRequest, options?: AxiosRequestConfig) {
        return RobotMessageApiFp(this.configuration).robotMessageFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotMessageApiRobotMessageRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotMessageApi
     */
    public robotMessageRemovePost(requestParameters: RobotMessageApiRobotMessageRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotMessageApiFp(this.configuration).robotMessageRemovePost(requestParameters.robotMessageRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotMessageApiRobotMessageSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotMessageApi
     */
    public robotMessageSearchGet(requestParameters: RobotMessageApiRobotMessageSearchGetRequest, options?: AxiosRequestConfig) {
        return RobotMessageApiFp(this.configuration).robotMessageSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.robotId, requestParameters.packageId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotSocketApi - axios parameter creator
 * @export
 */
export const RobotSocketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotSocket/ListClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListRobotMessageClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotSocket/ListRobotMessageClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListUpperClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotSocket/ListUpperClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotSocketApi - functional programming interface
 * @export
 */
export const RobotSocketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotSocketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotSocketListClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotSocketListClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotSocketListRobotMessageClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotSocketListRobotMessageClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotSocketListUpperClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotSocketListUpperClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotSocketApi - factory interface
 * @export
 */
export const RobotSocketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotSocketApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListClientsGet(options?: any): AxiosPromise<Array<number>> {
            return localVarFp.robotSocketListClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListRobotMessageClientsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.robotSocketListRobotMessageClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotSocketListUpperClientsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.robotSocketListUpperClientsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RobotSocketApi - object-oriented interface
 * @export
 * @class RobotSocketApi
 * @extends {BaseAPI}
 */
export class RobotSocketApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotSocketApi
     */
    public robotSocketListClientsGet(options?: AxiosRequestConfig) {
        return RobotSocketApiFp(this.configuration).robotSocketListClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotSocketApi
     */
    public robotSocketListRobotMessageClientsGet(options?: AxiosRequestConfig) {
        return RobotSocketApiFp(this.configuration).robotSocketListRobotMessageClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotSocketApi
     */
    public robotSocketListUpperClientsGet(options?: AxiosRequestConfig) {
        return RobotSocketApiFp(this.configuration).robotSocketListUpperClientsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RobotStatusHistoryApi - axios parameter creator
 * @export
 */
export const RobotStatusHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistoryFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('robotStatusHistoryFindGet', 'id', id)
            const localVarPath = `/RobotStatusHistory/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotStatusHistoryRemoveParams} [robotStatusHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistoryRemovePost: async (robotStatusHistoryRemoveParams?: RobotStatusHistoryRemoveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotStatusHistory/Remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotStatusHistoryRemoveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RobotStatusHistorySaveParams} [robotStatusHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistorySavePost: async (robotStatusHistorySaveParams?: RobotStatusHistorySaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/RobotStatusHistory/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(robotStatusHistorySaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistorySearchGet: async (pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('robotStatusHistorySearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('robotStatusHistorySearchGet', 'ps', ps)
            const localVarPath = `/RobotStatusHistory/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RobotStatusHistoryApi - functional programming interface
 * @export
 */
export const RobotStatusHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RobotStatusHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotStatusHistoryFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotStatusHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotStatusHistoryFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotStatusHistoryRemoveParams} [robotStatusHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotStatusHistoryRemovePost(robotStatusHistoryRemoveParams?: RobotStatusHistoryRemoveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotStatusHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotStatusHistoryRemovePost(robotStatusHistoryRemoveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RobotStatusHistorySaveParams} [robotStatusHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotStatusHistorySavePost(robotStatusHistorySaveParams?: RobotStatusHistorySaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotStatusHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotStatusHistorySavePost(robotStatusHistorySaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async robotStatusHistorySearchGet(pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobotStatusHistoryDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.robotStatusHistorySearchGet(pi, ps, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RobotStatusHistoryApi - factory interface
 * @export
 */
export const RobotStatusHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RobotStatusHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistoryFindGet(id: number, options?: any): AxiosPromise<RobotStatusHistoryDto> {
            return localVarFp.robotStatusHistoryFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotStatusHistoryRemoveParams} [robotStatusHistoryRemoveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistoryRemovePost(robotStatusHistoryRemoveParams?: RobotStatusHistoryRemoveParams, options?: any): AxiosPromise<RobotStatusHistoryDto> {
            return localVarFp.robotStatusHistoryRemovePost(robotStatusHistoryRemoveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RobotStatusHistorySaveParams} [robotStatusHistorySaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistorySavePost(robotStatusHistorySaveParams?: RobotStatusHistorySaveParams, options?: any): AxiosPromise<RobotStatusHistoryDto> {
            return localVarFp.robotStatusHistorySavePost(robotStatusHistorySaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        robotStatusHistorySearchGet(pi: number, ps: number, robotId?: number, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<RobotStatusHistoryDtoSearchResponseDto> {
            return localVarFp.robotStatusHistorySearchGet(pi, ps, robotId, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for robotStatusHistoryFindGet operation in RobotStatusHistoryApi.
 * @export
 * @interface RobotStatusHistoryApiRobotStatusHistoryFindGetRequest
 */
export interface RobotStatusHistoryApiRobotStatusHistoryFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryApiRobotStatusHistoryFindGet
     */
    readonly id: number
}

/**
 * Request parameters for robotStatusHistoryRemovePost operation in RobotStatusHistoryApi.
 * @export
 * @interface RobotStatusHistoryApiRobotStatusHistoryRemovePostRequest
 */
export interface RobotStatusHistoryApiRobotStatusHistoryRemovePostRequest {
    /**
     * 
     * @type {RobotStatusHistoryRemoveParams}
     * @memberof RobotStatusHistoryApiRobotStatusHistoryRemovePost
     */
    readonly robotStatusHistoryRemoveParams?: RobotStatusHistoryRemoveParams
}

/**
 * Request parameters for robotStatusHistorySavePost operation in RobotStatusHistoryApi.
 * @export
 * @interface RobotStatusHistoryApiRobotStatusHistorySavePostRequest
 */
export interface RobotStatusHistoryApiRobotStatusHistorySavePostRequest {
    /**
     * 
     * @type {RobotStatusHistorySaveParams}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySavePost
     */
    readonly robotStatusHistorySaveParams?: RobotStatusHistorySaveParams
}

/**
 * Request parameters for robotStatusHistorySearchGet operation in RobotStatusHistoryApi.
 * @export
 * @interface RobotStatusHistoryApiRobotStatusHistorySearchGetRequest
 */
export interface RobotStatusHistoryApiRobotStatusHistorySearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {string}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof RobotStatusHistoryApiRobotStatusHistorySearchGet
     */
    readonly isDelete?: boolean
}

/**
 * RobotStatusHistoryApi - object-oriented interface
 * @export
 * @class RobotStatusHistoryApi
 * @extends {BaseAPI}
 */
export class RobotStatusHistoryApi extends BaseAPI {
    /**
     * 
     * @param {RobotStatusHistoryApiRobotStatusHistoryFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotStatusHistoryApi
     */
    public robotStatusHistoryFindGet(requestParameters: RobotStatusHistoryApiRobotStatusHistoryFindGetRequest, options?: AxiosRequestConfig) {
        return RobotStatusHistoryApiFp(this.configuration).robotStatusHistoryFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotStatusHistoryApiRobotStatusHistoryRemovePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotStatusHistoryApi
     */
    public robotStatusHistoryRemovePost(requestParameters: RobotStatusHistoryApiRobotStatusHistoryRemovePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotStatusHistoryApiFp(this.configuration).robotStatusHistoryRemovePost(requestParameters.robotStatusHistoryRemoveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotStatusHistoryApiRobotStatusHistorySavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotStatusHistoryApi
     */
    public robotStatusHistorySavePost(requestParameters: RobotStatusHistoryApiRobotStatusHistorySavePostRequest = {}, options?: AxiosRequestConfig) {
        return RobotStatusHistoryApiFp(this.configuration).robotStatusHistorySavePost(requestParameters.robotStatusHistorySaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RobotStatusHistoryApiRobotStatusHistorySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RobotStatusHistoryApi
     */
    public robotStatusHistorySearchGet(requestParameters: RobotStatusHistoryApiRobotStatusHistorySearchGetRequest, options?: AxiosRequestConfig) {
        return RobotStatusHistoryApiFp(this.configuration).robotStatusHistorySearchGet(requestParameters.pi, requestParameters.ps, requestParameters.robotId, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpotApi - axios parameter creator
 * @export
 */
export const SpotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('spotFindGet', 'id', id)
            const localVarPath = `/Spot/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpotSaveParams} [spotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotSavePost: async (spotSaveParams?: SpotSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Spot/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('spotSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('spotSearchGet', 'ps', ps)
            const localVarPath = `/Spot/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotApi - functional programming interface
 * @export
 */
export const SpotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SpotSaveParams} [spotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotSavePost(spotSaveParams?: SpotSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotSavePost(spotSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotApi - factory interface
 * @export
 */
export const SpotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindGet(id: number, options?: any): AxiosPromise<SpotDto> {
            return localVarFp.spotFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotSaveParams} [spotSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotSavePost(spotSaveParams?: SpotSaveParams, options?: any): AxiosPromise<SpotDto> {
            return localVarFp.spotSavePost(spotSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<SpotDtoSearchResponseDto> {
            return localVarFp.spotSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for spotFindGet operation in SpotApi.
 * @export
 * @interface SpotApiSpotFindGetRequest
 */
export interface SpotApiSpotFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SpotApiSpotFindGet
     */
    readonly id: number
}

/**
 * Request parameters for spotSavePost operation in SpotApi.
 * @export
 * @interface SpotApiSpotSavePostRequest
 */
export interface SpotApiSpotSavePostRequest {
    /**
     * 
     * @type {SpotSaveParams}
     * @memberof SpotApiSpotSavePost
     */
    readonly spotSaveParams?: SpotSaveParams
}

/**
 * Request parameters for spotSearchGet operation in SpotApi.
 * @export
 * @interface SpotApiSpotSearchGetRequest
 */
export interface SpotApiSpotSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SpotApiSpotSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof SpotApiSpotSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof SpotApiSpotSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof SpotApiSpotSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof SpotApiSpotSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof SpotApiSpotSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof SpotApiSpotSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof SpotApiSpotSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * SpotApi - object-oriented interface
 * @export
 * @class SpotApi
 * @extends {BaseAPI}
 */
export class SpotApi extends BaseAPI {
    /**
     * 
     * @param {SpotApiSpotFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public spotFindGet(requestParameters: SpotApiSpotFindGetRequest, options?: AxiosRequestConfig) {
        return SpotApiFp(this.configuration).spotFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotApiSpotSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public spotSavePost(requestParameters: SpotApiSpotSavePostRequest = {}, options?: AxiosRequestConfig) {
        return SpotApiFp(this.configuration).spotSavePost(requestParameters.spotSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotApiSpotSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public spotSearchGet(requestParameters: SpotApiSpotSearchGetRequest, options?: AxiosRequestConfig) {
        return SpotApiFp(this.configuration).spotSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpotTypeApi - axios parameter creator
 * @export
 */
export const SpotTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('spotTypeFindGet', 'id', id)
            const localVarPath = `/SpotType/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SpotTypeSaveParams} [spotTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeSavePost: async (spotTypeSaveParams?: SpotTypeSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SpotType/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(spotTypeSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('spotTypeSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('spotTypeSearchGet', 'ps', ps)
            const localVarPath = `/SpotType/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotTypeApi - functional programming interface
 * @export
 */
export const SpotTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotTypeFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotTypeFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SpotTypeSaveParams} [spotTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotTypeSavePost(spotTypeSaveParams?: SpotTypeSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotTypeSavePost(spotTypeSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotTypeSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotTypeDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotTypeSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotTypeApi - factory interface
 * @export
 */
export const SpotTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeFindGet(id: number, options?: any): AxiosPromise<SpotTypeDto> {
            return localVarFp.spotTypeFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SpotTypeSaveParams} [spotTypeSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeSavePost(spotTypeSaveParams?: SpotTypeSaveParams, options?: any): AxiosPromise<SpotTypeDto> {
            return localVarFp.spotTypeSavePost(spotTypeSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotTypeSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<SpotTypeDtoSearchResponseDto> {
            return localVarFp.spotTypeSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for spotTypeFindGet operation in SpotTypeApi.
 * @export
 * @interface SpotTypeApiSpotTypeFindGetRequest
 */
export interface SpotTypeApiSpotTypeFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SpotTypeApiSpotTypeFindGet
     */
    readonly id: number
}

/**
 * Request parameters for spotTypeSavePost operation in SpotTypeApi.
 * @export
 * @interface SpotTypeApiSpotTypeSavePostRequest
 */
export interface SpotTypeApiSpotTypeSavePostRequest {
    /**
     * 
     * @type {SpotTypeSaveParams}
     * @memberof SpotTypeApiSpotTypeSavePost
     */
    readonly spotTypeSaveParams?: SpotTypeSaveParams
}

/**
 * Request parameters for spotTypeSearchGet operation in SpotTypeApi.
 * @export
 * @interface SpotTypeApiSpotTypeSearchGetRequest
 */
export interface SpotTypeApiSpotTypeSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof SpotTypeApiSpotTypeSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * SpotTypeApi - object-oriented interface
 * @export
 * @class SpotTypeApi
 * @extends {BaseAPI}
 */
export class SpotTypeApi extends BaseAPI {
    /**
     * 
     * @param {SpotTypeApiSpotTypeFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotTypeApi
     */
    public spotTypeFindGet(requestParameters: SpotTypeApiSpotTypeFindGetRequest, options?: AxiosRequestConfig) {
        return SpotTypeApiFp(this.configuration).spotTypeFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotTypeApiSpotTypeSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotTypeApi
     */
    public spotTypeSavePost(requestParameters: SpotTypeApiSpotTypeSavePostRequest = {}, options?: AxiosRequestConfig) {
        return SpotTypeApiFp(this.configuration).spotTypeSavePost(requestParameters.spotTypeSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpotTypeApiSpotTypeSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotTypeApi
     */
    public spotTypeSearchGet(requestParameters: SpotTypeApiSpotTypeSearchGetRequest, options?: AxiosRequestConfig) {
        return SpotTypeApiFp(this.configuration).spotTypeSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testExecGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test/Exec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSendMailGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test/SendMail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStartRecordingPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test/StartRecording`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStopRecordingPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test/StopRecording`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Test/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testExecGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testExecGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testSendMailGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testSendMailGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStartRecordingPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStartRecordingPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testStopRecordingPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testStopRecordingPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testExecGet(options?: any): AxiosPromise<string> {
            return localVarFp.testExecGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testSendMailGet(options?: any): AxiosPromise<string> {
            return localVarFp.testSendMailGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStartRecordingPost(options?: any): AxiosPromise<void> {
            return localVarFp.testStartRecordingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testStopRecordingPost(options?: any): AxiosPromise<void> {
            return localVarFp.testStopRecordingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTestGet(options?: any): AxiosPromise<string> {
            return localVarFp.testTestGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testExecGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testExecGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testSendMailGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testSendMailGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testStartRecordingPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testStartRecordingPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testStopRecordingPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testStopRecordingPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public testTestGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).testTestGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimeApi - axios parameter creator
 * @export
 */
export const TimeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeApi - functional programming interface
 * @export
 */
export const TimeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeApi - factory interface
 * @export
 */
export const TimeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeGet(options?: any): AxiosPromise<void> {
            return localVarFp.timeGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeApi - object-oriented interface
 * @export
 * @class TimeApi
 * @extends {BaseAPI}
 */
export class TimeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeApi
     */
    public timeGet(options?: AxiosRequestConfig) {
        return TimeApiFp(this.configuration).timeGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userFindGet', 'id', id)
            const localVarPath = `/User/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavePost: async (userSaveParams?: UserSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('userSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('userSearchGet', 'ps', ps)
            const localVarPath = `/User/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSavePost(userSaveParams?: UserSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSavePost(userSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userFindGet(id: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserSaveParams} [userSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSavePost(userSaveParams?: UserSaveParams, options?: any): AxiosPromise<UserDto> {
            return localVarFp.userSavePost(userSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<UserDtoSearchResponseDto> {
            return localVarFp.userSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userFindGet operation in UserApi.
 * @export
 * @interface UserApiUserFindGetRequest
 */
export interface UserApiUserFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserFindGet
     */
    readonly id: number
}

/**
 * Request parameters for userSavePost operation in UserApi.
 * @export
 * @interface UserApiUserSavePostRequest
 */
export interface UserApiUserSavePostRequest {
    /**
     * 
     * @type {UserSaveParams}
     * @memberof UserApiUserSavePost
     */
    readonly userSaveParams?: UserSaveParams
}

/**
 * Request parameters for userSearchGet operation in UserApi.
 * @export
 * @interface UserApiUserSearchGetRequest
 */
export interface UserApiUserSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiUserSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof UserApiUserSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof UserApiUserSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiUserSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof UserApiUserSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof UserApiUserSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {UserApiUserFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userFindGet(requestParameters: UserApiUserFindGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSavePost(requestParameters: UserApiUserSavePostRequest = {}, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSavePost(requestParameters.userSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserApiUserSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSearchGet(requestParameters: UserApiUserSearchGetRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRoleApi - axios parameter creator
 * @export
 */
export const UserRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UserRoleEditPermissionParams} [userRoleEditPermissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleEditPermissionPost: async (userRoleEditPermissionParams?: UserRoleEditPermissionParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserRole/EditPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleEditPermissionParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleFindGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userRoleFindGet', 'id', id)
            const localVarPath = `/UserRole/Find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserRoleSaveParams} [userRoleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleSavePost: async (userRoleSaveParams?: UserRoleSaveParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/UserRole/Save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleSaveParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleSearchGet: async (pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('userRoleSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('userRoleSearchGet', 'ps', ps)
            const localVarPath = `/UserRole/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['Username'] = username;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRoleApi - functional programming interface
 * @export
 */
export const UserRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UserRoleEditPermissionParams} [userRoleEditPermissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleEditPermissionPost(userRoleEditPermissionParams?: UserRoleEditPermissionParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleEditPermissionPost(userRoleEditPermissionParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleFindGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleFindGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserRoleSaveParams} [userRoleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleSavePost(userRoleSaveParams?: UserRoleSaveParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleSavePost(userRoleSaveParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRoleSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoleDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRoleSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRoleApi - factory interface
 * @export
 */
export const UserRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {UserRoleEditPermissionParams} [userRoleEditPermissionParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleEditPermissionPost(userRoleEditPermissionParams?: UserRoleEditPermissionParams, options?: any): AxiosPromise<UserRoleDto> {
            return localVarFp.userRoleEditPermissionPost(userRoleEditPermissionParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleFindGet(id: number, options?: any): AxiosPromise<UserRoleDto> {
            return localVarFp.userRoleFindGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserRoleSaveParams} [userRoleSaveParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleSavePost(userRoleSaveParams?: UserRoleSaveParams, options?: any): AxiosPromise<UserRoleDto> {
            return localVarFp.userRoleSavePost(userRoleSaveParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {string} [username] 
         * @param {string} [name] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRoleSearchGet(pi: number, ps: number, username?: string, name?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<UserRoleDtoSearchResponseDto> {
            return localVarFp.userRoleSearchGet(pi, ps, username, name, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userRoleEditPermissionPost operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleEditPermissionPostRequest
 */
export interface UserRoleApiUserRoleEditPermissionPostRequest {
    /**
     * 
     * @type {UserRoleEditPermissionParams}
     * @memberof UserRoleApiUserRoleEditPermissionPost
     */
    readonly userRoleEditPermissionParams?: UserRoleEditPermissionParams
}

/**
 * Request parameters for userRoleFindGet operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleFindGetRequest
 */
export interface UserRoleApiUserRoleFindGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleFindGet
     */
    readonly id: number
}

/**
 * Request parameters for userRoleSavePost operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleSavePostRequest
 */
export interface UserRoleApiUserRoleSavePostRequest {
    /**
     * 
     * @type {UserRoleSaveParams}
     * @memberof UserRoleApiUserRoleSavePost
     */
    readonly userRoleSaveParams?: UserRoleSaveParams
}

/**
 * Request parameters for userRoleSearchGet operation in UserRoleApi.
 * @export
 * @interface UserRoleApiUserRoleSearchGetRequest
 */
export interface UserRoleApiUserRoleSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof UserRoleApiUserRoleSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * UserRoleApi - object-oriented interface
 * @export
 * @class UserRoleApi
 * @extends {BaseAPI}
 */
export class UserRoleApi extends BaseAPI {
    /**
     * 
     * @param {UserRoleApiUserRoleEditPermissionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleEditPermissionPost(requestParameters: UserRoleApiUserRoleEditPermissionPostRequest = {}, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleEditPermissionPost(requestParameters.userRoleEditPermissionParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRoleApiUserRoleFindGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleFindGet(requestParameters: UserRoleApiUserRoleFindGetRequest, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleFindGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRoleApiUserRoleSavePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleSavePost(requestParameters: UserRoleApiUserRoleSavePostRequest = {}, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleSavePost(requestParameters.userRoleSaveParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserRoleApiUserRoleSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public userRoleSearchGet(requestParameters: UserRoleApiUserRoleSearchGetRequest, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).userRoleSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.username, requestParameters.name, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VideoApi - axios parameter creator
 * @export
 */
export const VideoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} robotId 
         * @param {PeripheralType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoGetLiveHlsUrlsGet: async (robotId: number, type: PeripheralType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'robotId' is not null or undefined
            assertParamExists('videoGetLiveHlsUrlsGet', 'robotId', robotId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('videoGetLiveHlsUrlsGet', 'type', type)
            const localVarPath = `/Video/GetLiveHlsUrls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [beginTime] 
         * @param {string} [endTime] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoSearchGet: async (pi: number, ps: number, robotId?: number, beginTime?: string, endTime?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pi' is not null or undefined
            assertParamExists('videoSearchGet', 'pi', pi)
            // verify required parameter 'ps' is not null or undefined
            assertParamExists('videoSearchGet', 'ps', ps)
            const localVarPath = `/Video/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (robotId !== undefined) {
                localVarQueryParameter['RobotId'] = robotId;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['BeginTime'] = (beginTime as any instanceof Date) ?
                    (beginTime as any).toISOString() :
                    beginTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['EndTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pi !== undefined) {
                localVarQueryParameter['Pi'] = pi;
            }

            if (ps !== undefined) {
                localVarQueryParameter['Ps'] = ps;
            }

            if (sorterKey !== undefined) {
                localVarQueryParameter['SorterKey'] = sorterKey;
            }

            if (includeDeleted !== undefined) {
                localVarQueryParameter['IncludeDeleted'] = includeDeleted;
            }

            if (sorterOrder !== undefined) {
                localVarQueryParameter['SorterOrder'] = sorterOrder;
            }

            if (isDelete !== undefined) {
                localVarQueryParameter['IsDelete'] = isDelete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VideoRecordStartRecordParams} [videoRecordStartRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoStartRecordingPost: async (videoRecordStartRecordParams?: VideoRecordStartRecordParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Video/StartRecording`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoRecordStartRecordParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VideoRecordStopRecordParams} [videoRecordStopRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoStopRecordingPost: async (videoRecordStopRecordParams?: VideoRecordStopRecordParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Video/StopRecording`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoRecordStopRecordParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoApi - functional programming interface
 * @export
 */
export const VideoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} robotId 
         * @param {PeripheralType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoGetLiveHlsUrlsGet(robotId: number, type: PeripheralType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoGetLiveHlsUrlsGet(robotId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [beginTime] 
         * @param {string} [endTime] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoSearchGet(pi: number, ps: number, robotId?: number, beginTime?: string, endTime?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoRecordDtoSearchResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoSearchGet(pi, ps, robotId, beginTime, endTime, sorterKey, includeDeleted, sorterOrder, isDelete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VideoRecordStartRecordParams} [videoRecordStartRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoStartRecordingPost(videoRecordStartRecordParams?: VideoRecordStartRecordParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoStartRecordingPost(videoRecordStartRecordParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VideoRecordStopRecordParams} [videoRecordStopRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoStopRecordingPost(videoRecordStopRecordParams?: VideoRecordStopRecordParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoStopRecordingPost(videoRecordStopRecordParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoApi - factory interface
 * @export
 */
export const VideoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoApiFp(configuration)
    return {
        /**
         * 
         * @param {number} robotId 
         * @param {PeripheralType} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoGetLiveHlsUrlsGet(robotId: number, type: PeripheralType, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.videoGetLiveHlsUrlsGet(robotId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} pi 
         * @param {number} ps 
         * @param {number} [robotId] 
         * @param {string} [beginTime] 
         * @param {string} [endTime] 
         * @param {string} [sorterKey] 
         * @param {boolean} [includeDeleted] 
         * @param {SorterOrder} [sorterOrder] 
         * @param {boolean} [isDelete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoSearchGet(pi: number, ps: number, robotId?: number, beginTime?: string, endTime?: string, sorterKey?: string, includeDeleted?: boolean, sorterOrder?: SorterOrder, isDelete?: boolean, options?: any): AxiosPromise<VideoRecordDtoSearchResponseDto> {
            return localVarFp.videoSearchGet(pi, ps, robotId, beginTime, endTime, sorterKey, includeDeleted, sorterOrder, isDelete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VideoRecordStartRecordParams} [videoRecordStartRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoStartRecordingPost(videoRecordStartRecordParams?: VideoRecordStartRecordParams, options?: any): AxiosPromise<void> {
            return localVarFp.videoStartRecordingPost(videoRecordStartRecordParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VideoRecordStopRecordParams} [videoRecordStopRecordParams] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoStopRecordingPost(videoRecordStopRecordParams?: VideoRecordStopRecordParams, options?: any): AxiosPromise<void> {
            return localVarFp.videoStopRecordingPost(videoRecordStopRecordParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for videoGetLiveHlsUrlsGet operation in VideoApi.
 * @export
 * @interface VideoApiVideoGetLiveHlsUrlsGetRequest
 */
export interface VideoApiVideoGetLiveHlsUrlsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoApiVideoGetLiveHlsUrlsGet
     */
    readonly robotId: number

    /**
     * 
     * @type {PeripheralType}
     * @memberof VideoApiVideoGetLiveHlsUrlsGet
     */
    readonly type: PeripheralType
}

/**
 * Request parameters for videoSearchGet operation in VideoApi.
 * @export
 * @interface VideoApiVideoSearchGetRequest
 */
export interface VideoApiVideoSearchGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VideoApiVideoSearchGet
     */
    readonly pi: number

    /**
     * 
     * @type {number}
     * @memberof VideoApiVideoSearchGet
     */
    readonly ps: number

    /**
     * 
     * @type {number}
     * @memberof VideoApiVideoSearchGet
     */
    readonly robotId?: number

    /**
     * 
     * @type {string}
     * @memberof VideoApiVideoSearchGet
     */
    readonly beginTime?: string

    /**
     * 
     * @type {string}
     * @memberof VideoApiVideoSearchGet
     */
    readonly endTime?: string

    /**
     * 
     * @type {string}
     * @memberof VideoApiVideoSearchGet
     */
    readonly sorterKey?: string

    /**
     * 
     * @type {boolean}
     * @memberof VideoApiVideoSearchGet
     */
    readonly includeDeleted?: boolean

    /**
     * 
     * @type {SorterOrder}
     * @memberof VideoApiVideoSearchGet
     */
    readonly sorterOrder?: SorterOrder

    /**
     * 
     * @type {boolean}
     * @memberof VideoApiVideoSearchGet
     */
    readonly isDelete?: boolean
}

/**
 * Request parameters for videoStartRecordingPost operation in VideoApi.
 * @export
 * @interface VideoApiVideoStartRecordingPostRequest
 */
export interface VideoApiVideoStartRecordingPostRequest {
    /**
     * 
     * @type {VideoRecordStartRecordParams}
     * @memberof VideoApiVideoStartRecordingPost
     */
    readonly videoRecordStartRecordParams?: VideoRecordStartRecordParams
}

/**
 * Request parameters for videoStopRecordingPost operation in VideoApi.
 * @export
 * @interface VideoApiVideoStopRecordingPostRequest
 */
export interface VideoApiVideoStopRecordingPostRequest {
    /**
     * 
     * @type {VideoRecordStopRecordParams}
     * @memberof VideoApiVideoStopRecordingPost
     */
    readonly videoRecordStopRecordParams?: VideoRecordStopRecordParams
}

/**
 * VideoApi - object-oriented interface
 * @export
 * @class VideoApi
 * @extends {BaseAPI}
 */
export class VideoApi extends BaseAPI {
    /**
     * 
     * @param {VideoApiVideoGetLiveHlsUrlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoGetLiveHlsUrlsGet(requestParameters: VideoApiVideoGetLiveHlsUrlsGetRequest, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).videoGetLiveHlsUrlsGet(requestParameters.robotId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoApiVideoSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoSearchGet(requestParameters: VideoApiVideoSearchGetRequest, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).videoSearchGet(requestParameters.pi, requestParameters.ps, requestParameters.robotId, requestParameters.beginTime, requestParameters.endTime, requestParameters.sorterKey, requestParameters.includeDeleted, requestParameters.sorterOrder, requestParameters.isDelete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoApiVideoStartRecordingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoStartRecordingPost(requestParameters: VideoApiVideoStartRecordingPostRequest = {}, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).videoStartRecordingPost(requestParameters.videoRecordStartRecordParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VideoApiVideoStopRecordingPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoApi
     */
    public videoStopRecordingPost(requestParameters: VideoApiVideoStopRecordingPostRequest = {}, options?: AxiosRequestConfig) {
        return VideoApiFp(this.configuration).videoStopRecordingPost(requestParameters.videoRecordStopRecordParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebSocketApi - axios parameter creator
 * @export
 */
export const WebSocketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSocketListClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WebSocket/ListClients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ws`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebSocketApi - functional programming interface
 * @export
 */
export const WebSocketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebSocketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debugGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.debugGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webSocketListClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webSocketListClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async wsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.wsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebSocketApi - factory interface
 * @export
 */
export const WebSocketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebSocketApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugGet(options?: any): AxiosPromise<void> {
            return localVarFp.debugGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSocketListClientsGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.webSocketListClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        wsGet(options?: any): AxiosPromise<void> {
            return localVarFp.wsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebSocketApi - object-oriented interface
 * @export
 * @class WebSocketApi
 * @extends {BaseAPI}
 */
export class WebSocketApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebSocketApi
     */
    public debugGet(options?: AxiosRequestConfig) {
        return WebSocketApiFp(this.configuration).debugGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebSocketApi
     */
    public webSocketListClientsGet(options?: AxiosRequestConfig) {
        return WebSocketApiFp(this.configuration).webSocketListClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebSocketApi
     */
    public wsGet(options?: AxiosRequestConfig) {
        return WebSocketApiFp(this.configuration).wsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


