import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionTemplateApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import MissionTemplateTable from '../../components/MissionTemplate/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import MissionTemplateEditForm from '../../components/MissionTemplate/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {ReloadOutlined} from '@ant-design/icons';
export function MissionTemplateIndexPage() {
  const searchHook = useOpenApiFpRequest(MissionTemplateApi, MissionTemplateApi.prototype.missionTemplateSearchGet);
  const isAddMissionTemplate = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  function refresh() {
    antdConfig.search.submit();
  }
  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <Title level={4} style={{marginBottom: 0}}>
            任务模板
          </Title>
          <div
            style={{marginLeft: 4, marginRight: 4, cursor: 'pointer'}}
            onClick={() => {
              refresh();
            }}>
            <ReloadOutlined spin={searchHook.loading} style={{color: '#666', width: 16, height: 16}} />
          </div>
          <Typography.Text type={'secondary'}>编辑号任务模板后, 给机器人下发指令可以直接选择模板下发</Typography.Text>
        </Flex>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddMissionTemplate.setTrue}>
              新建任务模板
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <MissionTemplateTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入任务模板'} visible={isAddMissionTemplate.value} maskClosable={false} closable={false} footer={false} width={'90%'}>
        <MissionTemplateEditForm
          onCancel={isAddMissionTemplate.setFalse}
          onSuccess={res => {
            isAddMissionTemplate.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
