import React, {useEffect} from 'react';
import {useMount} from 'ahooks';
import useCheckAuth from '../hooks/useCheckAuth';
import useAuth from '../utils/AuthContext';
import {Route} from 'react-router-dom';
import '../styles/appLayout.less';
import LayoutSideBar from '../components/Layout/LayoutSideBar';
import ProfileIndexPage from './profile';
import {UserIndexPage} from './user';
import {UserRoleIndexPage} from './userRole';
import {FloorIndexPage} from './floor';
import {BuildingIndexPage} from './building';
import {ActionTypeIndexPage} from './action/actionType';
import {ActionObjectIndexPage} from './action/actionObject';
import {ActionTargetIndexPage} from './action/actionTarget';
import {notification} from 'antd';
import {AudioChatIndexPage} from './audioChat';
import VideoIndexPage from './video';
import {RobotIndexPage} from './robot';
import {PeripheralIndexPage} from './peripheral';
import {SpotIndexPage} from './spot';
import {SpotTypeIndexPage} from './spotType';
import {PeripheralAbilityIndexPage} from './peripheralAbility';
import {PeripheralAbilityCommandIndexPage} from './peripheralAbilityCommand';
import {MissionTemplateIndexPage} from './missionTemplate';
import {MissionIndexPage} from './mission';
import {DevPage} from './dev';
import {Config} from '../config';
import {MapIndexPage} from './map';
import {OverviewIndexPage} from './overview';
import {PeripheralOverviewPage} from './peripheral/overview';
import {RobotFunctionIndexPage} from './robotFunction';
import {AlertIndexPage} from './alert';
import {RobotMessageIndexPage} from './robotMessage';
import {DebugIndexPage} from './debug';
import {UserRolePermissionType} from '../components/UserRole/UserRolePermissionType';
import {User} from '../scaffold';

interface MessageUnit {
  Name: string;
  Addr?: string;
}
export default function AppLayout(): any {
  const checkAuth = useCheckAuth();
  const auth = useAuth();
  function containsPermission(type: UserRolePermissionType) {
    return (auth.currentUser as User).userRole?.userRolePermissions?.includes(UserRolePermissionType[type]);
  }

  useEffect(() => {
    const ws = new WebSocket(`ws://${Config.wsHost}:${Config.wsPort}/ws`);

    ws.onopen = () => {
      console.log('opened');
    };
    ws.onmessage = msg => {
      console.log(msg);
      const unit: MessageUnit = JSON.parse(msg.data);
      if (unit.Name === 'Voice_talk') {
        notification.info({
          message: '您收到了一条新的音频信息',
          description: '点击播放',
          onClick: () => {
            const audio = new Audio(unit.Addr);
            audio.play();
          },
        });
      }
      if (unit.Name === 'MissionFinishError') {
        notification.error({
          message: (unit as any).Title,
          description: (unit as any).Message,
        });
      }
    };
    ws.onclose = () => {
      console.log('closed');
    };
    return () => {
      ws.close();
    };
  }, []);
  useMount(() => {
    return checkAuth.check();
  });
  return (
    <div>
      {checkAuth.isChecking && <div>加载中</div>}
      {!checkAuth.isChecking && auth.currentUser && (
        <div className={'AppLayout'}>
          <LayoutSideBar />
          <div className={'AppLayoutMain'}>
            <Route exact path={'/'} component={MissionIndexPage} />
            <Route exact path={'/mission'} component={MissionIndexPage} />
            <Route exact path={'/alert'} component={AlertIndexPage} />
            <Route exact path={'/profile'} component={ProfileIndexPage} />
            <Route exact path={'/overview'} component={OverviewIndexPage} />
            {containsPermission(UserRolePermissionType.外设管理) && (
              <>
                <Route exact path={'/peripheralOverview'} component={PeripheralOverviewPage} />
              </>
            )}
            <Route exact path={'/missionTemplate'} component={MissionTemplateIndexPage} />
            <Route exact path={'/dev'} component={DevPage} />
            {containsPermission(UserRolePermissionType.日志查询) && (
              <>
                <Route exact path={'/debug'} component={DebugIndexPage} />
                <Route exact path={'/robotMessage'} component={RobotMessageIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.用户管理) && (
              <>
                <Route exact path={'/basic/user'} component={UserIndexPage} />
                <Route exact path={'/basic/userRole'} component={UserRoleIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.地点管理) && (
              <>
                <Route exact path={'/area/building'} component={BuildingIndexPage} />
                <Route exact path={'/area/floor'} component={FloorIndexPage} />
                <Route exact path={'/area/map'} component={MapIndexPage} />
                <Route exact path={'/area/spotType'} component={SpotTypeIndexPage} />
                <Route exact path={'/area/spot'} component={SpotIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.任务基础数据管理) && (
              <>
                <Route exact path={'/basic/action/type'} component={ActionTypeIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.外设管理) && (
              <>
                <Route exact path={'/basic/peripheral'} component={PeripheralIndexPage} />
                <Route exact path={'/basic/peripheralAbility'} component={PeripheralAbilityIndexPage} />
                <Route exact path={'/basic/peripheralAbilityCommand'} component={PeripheralAbilityCommandIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.机器人管理) && (
              <>
                <Route exact path={'/basic/robot'} component={RobotIndexPage} />
                <Route exact path={'/basic/robotFunction'} component={RobotFunctionIndexPage} />
              </>
            )}
            {containsPermission(UserRolePermissionType.音频对讲) && <Route exact path={'/audioChat'} component={AudioChatIndexPage} />}
            {containsPermission(UserRolePermissionType.音频视频) && <Route exact path={'/video'} component={VideoIndexPage} />}
          </div>
        </div>
      )}
    </div>
  );
}
