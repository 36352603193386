import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, MissionApi, MissionApiFactory, MissionDto, MissionStartParams, RobotApi} from '../../scaffold';
import RobotSelector from '../Robot/Selector';
import MissionTemplateRadio from '../MissionTemplate/Radio';
import Flex from '../Shared/Flex';

const MissionEditForm = (props: {onSuccess?: (res: MissionDto) => any; robotId?: number; onCancel?: () => any}) => {
  const [form] = Form.useForm<MissionStartParams>();
  const currentMissionHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotGetCurrentMissionGet);
  const updateHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionStartPost);
  useEffect(() => {
    form.setFieldsValue({
      robotId: props.robotId,
    });
    props.robotId &&
      currentMissionHook.requestSync({
        id: props.robotId,
      });
  }, [props.robotId]);
  function onSubmit(data: MissionStartParams) {
    let confirmResult = !currentMissionHook.data?.success;
    if (!confirmResult) {
      confirmResult = confirm('任务执行中！若下发新任务，将中止当前任务！');
    }
    confirmResult &&
      updateHook
        .request({missionStartParams: data})
        .then(res => {
          message.success('操作成功');
          props.onSuccess && props.onSuccess(res);
        })
        .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<MissionStartParams> form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="机器人" name="robotId" hidden={!!props.robotId} rules={[{required: true, message: '请选择机器人'}]}>
          <RobotSelector />
        </Form.Item>
        <Form.Item label="任务模板" name="missionTemplateId" rules={[{required: true, message: '请选择一个任务模板'}]}>
          <MissionTemplateRadio />
        </Form.Item>
        <Flex direction={'row'} justify={'space-between'} className={'text-right m-t-16'}>
          <Space>
            <Button.Group>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit({
                    robotId: props.robotId,
                    missionTemplateId: 33,
                  });
                }}
                style={{width: 120}}
                loading={updateHook.loading}
                disabled={updateHook.loading}>
                回充电桩
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit({
                    robotId: props.robotId,
                    missionTemplateId: 34,
                  });
                }}
                style={{width: 120}}
                loading={updateHook.loading}
                disabled={updateHook.loading}>
                人脸识别开启
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit({
                    robotId: props.robotId,
                    missionTemplateId: 35,
                  });
                }}
                style={{width: 120}}
                loading={updateHook.loading}
                disabled={updateHook.loading}>
                人脸识别关闭
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit({
                    robotId: props.robotId,
                    missionTemplateId: 36,
                  });
                }}
                style={{width: 120}}
                loading={updateHook.loading}
                disabled={updateHook.loading}>
                目标追踪开启
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit({
                    robotId: props.robotId,
                    missionTemplateId: 37,
                  });
                }}
                style={{width: 120}}
                loading={updateHook.loading}
                disabled={updateHook.loading}>
                目标追踪关闭
              </Button>
            </Button.Group>
          </Space>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </Flex>
      </Form>
    </div>
  );
};
export default MissionEditForm;
