import React, {useEffect} from 'react';
import {Descriptions, Steps, Typography} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi, MissionLogApi} from '../../scaffold';
import {RunningTag} from '../Robot/RunningTag';

export function MissionLogSteps(props: {id?: number; title?: string}) {
  const listLogHook = useOpenApiFpRequest(MissionLogApi, MissionLogApi.prototype.missionLogListByMissionGet);
  useEffect(() => {
    props.id &&
      listLogHook.requestSync({
        missionId: props.id,
      });
  }, [props.id]);
  return (
    <div>
      <Steps current={listLogHook.data?.length} direction={'vertical'} progressDot={true}>
        {listLogHook.data?.map(i => (
          <Steps.Step
            title={i.title}
            key={i.id}
            description={
              <Typography.Text>
                {i.descriptions} {i.user && ', 操作人: ' + i.user.name}
              </Typography.Text>
            }
            subTitle={i.createdTime}
          />
        ))}
      </Steps>
    </div>
  );
}
