import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, ActionTypeApi, ActionTypeApiFactory, ActionTypeDto, ActionTypeSaveParams} from '../../scaffold';
import UserRoleSelector from '../UserRole/UserRoleSelector';
import UserSelector from '../User/Selector';
import ActionObjectSelector from '../ActionObject/Selector';

const ActionTypeEditForm = (props: {onSuccess?: (res: ActionTypeDto) => any; actionType?: ActionTypeDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<ActionTypeSaveParams>();
  const updateHook = useOpenApiFpRequest(ActionTypeApi, ActionTypeApi.prototype.actionTypeSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.actionType?.id,
      name: props.actionType?.name ?? undefined,
      isDelete: props.actionType?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.actionType]);

  function onSubmit(data: ActionTypeSaveParams) {
    updateHook
      .request({actionTypeSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<ActionTypeSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="名称" name="name" rules={[{required: true, message: '请输入名称'}]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default ActionTypeEditForm;
