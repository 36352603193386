import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {SpotApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import SpotTable from '../../components/Spot/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import SpotEditForm from '../../components/Spot/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function SpotIndexPage() {
  const searchHook = useOpenApiFpRequest(SpotApi, SpotApi.prototype.spotSearchGet);
  const isAddSpot = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            终点管理
          </Title>
          <Typography.Text type={'secondary'}>您可以在此处编辑、删除、新建机器人指令操作的终点, 如: 101 号会议室, 102 号会议室 等</Typography.Text>
        </div>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddSpot.setTrue}>
              添加新终点
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <SpotTable antDTable={antdConfig} tableProps={{size: 'small'}} />
      </Skeleton>
      <Modal title={'录入终点'} visible={isAddSpot.value} maskClosable={false} closable={false} footer={false}>
        <SpotEditForm
          onCancel={isAddSpot.setFalse}
          onSuccess={res => {
            isAddSpot.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
