import React from 'react';
import {AlertDto, AlertLevel} from '../../scaffold';
import {BsFillInfoCircleFill, AiFillWarning, VscError} from 'react-icons/all';
export function AlertIcon(props: {alert?: AlertDto}) {
  if (props.alert?.level === AlertLevel.提醒) {
    return <BsFillInfoCircleFill size={20} color={'#1890ff'} />;
  } else if (props.alert?.level === AlertLevel.警告) {
    return <AiFillWarning size={20} color={'#f56a00'} />;
  } else if (props.alert?.level === AlertLevel.严重) {
    return <VscError size={20} color={'#cf1322'} />;
  }
  return <></>;
}
