import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, FloorDto, FloorApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Button, Image, message, Space, Table, TableProps, Tag, Typography} from 'antd';
import FloorUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import FloorEditMapBtn from './EditMapBtn';
import FloorCalibrationMapBtn from './FloorCalibrationMapBtn';

export default function FloorTable(props: {antDTable: Result<FloorDto>; tableProps?: TableProps<any>}) {
  const {tableProps, search, loading} = props.antDTable;
  const removeHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorRemovePost);

  return (
    <Table<FloorDto> {...tableProps} {...props.tableProps} rowKey={'id'} size={'small'} bordered>
      <Table.Column<FloorDto> width={80} title="楼层名" dataIndex={'name'} align={'center'} render={txt => <Typography.Text strong>{txt}</Typography.Text>} />
      <Table.Column<FloorDto>
        title="楼层对应代码"
        width={120}
        dataIndex={'commandName'}
        align={'center'}
        render={txt => <Typography.Text strong>{txt}</Typography.Text>}
      />
      <Table.Column<FloorDto>
        width={120}
        title="包含地点"
        dataIndex={'spots'}
        align={'center'}
        render={(txt, row) => <Typography.Text strong>{row.spots?.map(i => i.name).join(', ')}</Typography.Text>}
      />
      <Table.Column<FloorDto> width={80} title="所在楼栋" align={'center'} dataIndex={['building', 'name']} />
      <Table.Column<FloorDto> width={120} title="录入时间" align={'center'} dataIndex={'createdTime'} />
      <Table.Column<FloorDto> width={200} title="地图" align={'center'} render={(txt, row) => row.map?.url && <Image src={row.map?.url} />} />
      <Table.Column<FloorDto>
        title="校准状态"
        align={'center'}
        dataIndex={['map', 'isCalibrated']}
        render={txt => <Tag color={txt ? 'green' : 'red'}>{txt ? '已校准' : '未校准'}</Tag>}
      />
      <Table.Column<FloorDto> title="备注" width={200} dataIndex={'remark'} />
      <Table.Column<FloorDto>
        title="操作"
        width={80}
        render={(text, row) => {
          return (
            <Space>
              <FloorUpdateInfoBtn floor={row} type={'link'} size={'small'} onSuccess={search.submit} />
              <FloorEditMapBtn floor={row} type={'link'} size={'small'} onSuccess={search.submit} />
              <FloorCalibrationMapBtn floor={row} type={'link'} size={'small'} onSuccess={search.submit} />
              <Button
                type={'link'}
                danger={true}
                onClick={() => {
                  const res = confirm('您确认是否删除此楼层');
                  if (res && row.id) {
                    removeHook.requestSync({
                      floorRemoveParams: {id: row.id},
                    });
                  }
                }}>
                删除
              </Button>
            </Space>
          );
        }}
      />
    </Table>
  );
}
