import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {
  Gender,
  PeripheralAbilityCommandApi,
  PeripheralAbilityCommandApiFactory,
  PeripheralAbilityCommandDto,
  PeripheralAbilityCommandSaveParams,
} from '../../scaffold';
import PeripheralAbilitySelector from '../PeripheralAbility/Selector';

const PeripheralAbilityCommandEditForm = (props: {
  onSuccess?: (res: PeripheralAbilityCommandDto) => any;
  peripheralAbilityCommand?: PeripheralAbilityCommandDto;
  onCancel?: () => any;
}) => {
  const [form] = Form.useForm<PeripheralAbilityCommandSaveParams>();
  const updateHook = useOpenApiFpRequest(PeripheralAbilityCommandApi, PeripheralAbilityCommandApi.prototype.peripheralAbilityCommandSavePost);

  function reset() {
    form.setFieldsValue({
      peripheralAbilityId: props.peripheralAbilityCommand?.peripheralAbilityId,
      id: props.peripheralAbilityCommand?.id,
      name: props.peripheralAbilityCommand?.name ?? undefined,
      commandName: props.peripheralAbilityCommand?.commandName ?? undefined,
      isDelete: props.peripheralAbilityCommand?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.peripheralAbilityCommand]);

  function onSubmit(data: PeripheralAbilityCommandSaveParams) {
    updateHook
      .request({peripheralAbilityCommandSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<PeripheralAbilityCommandSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="外设功能" name="peripheralAbilityId" rules={[{required: true, message: '请选择外设功能'}]}>
          <PeripheralAbilitySelector />
        </Form.Item>
        <Form.Item label="状态名称" name="name" rules={[{required: true, message: '请输入状态名称'}]}>
          <Input placeholder="请输入状态名称" />
        </Form.Item>
        <Form.Item label="对应代码" name="commandName" rules={[{required: true, message: '请输入对应代码'}]}>
          <Input placeholder="请输入对应代码" />
        </Form.Item>
        <Form.Item label="设置为外设默认状态" name="isDefault" rules={[{required: true, message: '请选择是否是外设默认状态'}]}>
          <Switch />
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default PeripheralAbilityCommandEditForm;
