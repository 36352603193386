import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, ActionTypeDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table} from 'antd';
import ActionTypeUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function ActionTypeTable(props: {antDTable: Result<ActionTypeDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<ActionTypeDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<ActionTypeDto> title="ID" dataIndex="id" sorter />
      {/*<Table.Column<ActionTypeDto> title="主体" dataIndex={['actionObject', 'name']} sorter />*/}
      <Table.Column<ActionTypeDto> title="名称" dataIndex={'name'} sorter />
      <Table.Column<ActionTypeDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
      <Table.Column<ActionTypeDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <ActionTypeUpdateInfoBtn actionType={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
