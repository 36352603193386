import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, PeripheralAbilityDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table} from 'antd';
import PeripheralAbilityUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function PeripheralAbilityTable(props: {antDTable: Result<PeripheralAbilityDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<PeripheralAbilityDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<PeripheralAbilityDto> title="ID" dataIndex="id" sorter />
      <Table.Column<PeripheralAbilityDto> title="名称" dataIndex={'name'} sorter />
      <Table.Column<PeripheralAbilityDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
      <Table.Column<PeripheralAbilityDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <PeripheralAbilityUpdateInfoBtn peripheralAbility={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
