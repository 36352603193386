export const Config = {
  // basePath: 'http://spring.oxiracetam.yijiahe.com/',
  // basePath: 'http://802.geestack.com:5400',
  basePath: 'http://192.168.50.72:5400',
  // wsHost: '802.geestack.com',
  wsHost: '192.168.50.72',
  staticPrefix: 'http://192.168.50.72:5400/',
  wsPort: 5400,
  version: '1.0.0',
};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  Config.basePath = 'http://localhost:5400';
  Config.wsHost = 'localhost';
  Config.staticPrefix = 'http://localhost:5400/';
} else {
  // production code
  Config.basePath = 'http://47.100.32.125:5400';
  Config.wsHost = '47.100.32.125';
  Config.staticPrefix = 'http://47.100.32.125:5400/';
}
