import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, SpotTypeDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table, Typography} from 'antd';
import SpotTypeUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function SpotTypeTable(props: {antDTable: Result<SpotTypeDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<SpotTypeDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<SpotTypeDto> title="名称" dataIndex={'name'} sorter render={txt => <Typography.Text strong>{txt}</Typography.Text>} />
      <Table.Column<SpotTypeDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <SpotTypeUpdateInfoBtn spotType={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
