import {Card, Tabs} from 'antd';
import {MissionBasicDescriptions} from './BasicDescriptions';
import {MissionLogSteps} from './LogSteps';
import {MissionTemplateDetail} from '../MissionTemplate/Detail';
import React, {useMemo} from 'react';
import {MissionDto} from '../../scaffold';

export function MissionDetail(props: {mission?: MissionDto}) {
  const mission = useMemo(() => {
    return props.mission;
  }, [props.mission]);
  return (
    <Tabs>
      <Tabs.TabPane key="任务信息" tabKey="任务信息" tab="任务信息">
        <Card bodyStyle={{padding: 0}}>
          <MissionBasicDescriptions id={mission?.id} />
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane key="任务日志" tabKey="任务日志" tab="任务日志">
        <Card>
          <MissionLogSteps id={mission?.id} />
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane key="任务内容" tabKey="任务内容" tab="任务内容">
        <MissionTemplateDetail id={mission?.missionTemplateId} />
      </Tabs.TabPane>
    </Tabs>
  );
}
