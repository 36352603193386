import Flex from '../Shared/Flex';
import {Tag, Typography} from 'antd';
import React, {useMemo} from 'react';
import {RobotDto, RobotStatus, SpotTypeDto} from '../../scaffold';
const colorList = ['#1890ff', '#87d068', '#e16a00'];

export function SpotTypeTag(props: {spotType?: SpotTypeDto}) {
  const color = colorList[(props.spotType?.id ?? 0) % colorList.length];
  console.log(color);
  return <Tag color={color}>{props.spotType?.name}</Tag>;
}
