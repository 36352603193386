import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {UserRoleApi, UserRoleDto, UserRoleEditPermissionParams} from '../../scaffold';
import {UserRolePermissionCheckbox} from './UserRolePermissionCheckbox';

const UserRolePermissionEditForm = (props: {onSuccess?: (res: UserRoleDto) => any; userRole?: UserRoleDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<UserRoleEditPermissionParams>();
  const updateHook = useOpenApiFpRequest(UserRoleApi, UserRoleApi.prototype.userRoleEditPermissionPost);

  function reset() {
    form.setFieldsValue({
      id: props.userRole?.id,
      permissions: props.userRole?.userRolePermissions,
    });
  }

  useEffect(() => {
    reset();
  }, [props.userRole]);

  function onSubmit(data: UserRoleEditPermissionParams) {
    updateHook
      .request({userRoleEditPermissionParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<UserRoleEditPermissionParams> layout="vertical" size={'large'} form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="权限列表" name="permissions">
          <UserRolePermissionCheckbox />
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default UserRolePermissionEditForm;
