import React, {useMemo, useState} from 'react';
import {Layer, Stage, Image as KonvaImage, Circle, Group, Label, Text, Tag} from 'react-konva';
export interface IMapPointerValue {
  x: number;
  y: number;
}
export function MapPointer(props: {url?: string; height?: number; onChange?: (v: IMapPointerValue) => any}) {
  const [scale, setScale] = useState<number>(0.6);
  const [offsetX, setOffsetX] = useState<number>();
  const [offsetY, setOffsetY] = useState<number>();
  const [layerOffsetX, setLayerOffsetX] = useState<number>(0);
  const [layerOffsetY, setLayerOffsetY] = useState<number>(0);

  const image = useMemo(() => {
    const img = new Image();
    img.src = props.url ?? '';
    return img;
  }, [props.url]);
  return (
    <Stage
      width={window.innerWidth}
      height={props.height ?? 400}
      style={{overflow: 'auto'}}
      onWheel={e => {
        if (e.evt.altKey) {
          if (e.evt.deltaY > 0) {
            console.log('down');
            setScale(scale * 0.92);
          }
          if (e.evt.deltaY < 0) {
            console.log('up');
            setScale(scale * 1.08);
          }
        }
      }}>
      <Layer>
        <Group
          draggable
          onDragEnd={e => {
            setLayerOffsetX(e.target.x());
            setLayerOffsetY(e.target.y());
          }}>
          <KonvaImage
            onClick={e => {
              console.log((e.evt.offsetX - layerOffsetX) / scale, (e.evt.offsetY - layerOffsetY) / scale);
              const x = (e.evt.offsetX - layerOffsetX) / scale;
              const y = (e.evt.offsetY - layerOffsetY) / scale;
              setOffsetX(x);
              setOffsetY(y);
              props.onChange &&
                props.onChange({
                  x,
                  y,
                });
            }}
            scaleX={scale}
            scaleY={scale}
            image={image}></KonvaImage>
          <Circle x={(offsetX ?? 0) * scale} y={(offsetY ?? 0) * scale} scaleX={scale} scaleY={scale} radius={10} fill="red" width={24} />
        </Group>
      </Layer>
    </Stage>
  );
}
