import React, {useEffect, useState} from 'react';
import {Button, Form, Image, Input, message, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {InboxOutlined} from '@ant-design/icons';
import FloorSelector from '../Floor/Selector';
import Dragger, {DraggerProps} from 'antd/lib/upload/Dragger';
import {Config} from '../../config';
import {UploadFile} from 'antd/lib/upload/interface';
import Flex from '../Shared/Flex';
import {MapApi, MapDto, MapSaveParams} from '../../scaffold';

const MapEditForm = (props: {onSuccess?: (res: MapDto) => any; map?: MapDto; onCancel?: () => any; floorId?: number}) => {
  const [form] = Form.useForm<MapSaveParams>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const updateHook = useOpenApiFpRequest(MapApi, MapApi.prototype.mapSavePost);
  const [url, setUrl] = useState<string>();

  function reset() {
    form.setFieldsValue({
      id: props.map?.id,
      url: props.map?.url ?? undefined,
      floorId: props.map?.floorId,
      remark: props.map?.remark ?? undefined,
    });
    setUrl(props.map?.url ?? undefined);
    if (props.floorId) {
      form.setFieldsValue({
        floorId: props.floorId,
      });
    }
  }

  useEffect(() => {
    reset();
  }, [props.map]);

  function onSubmit(data: MapSaveParams) {
    console.log(data);
    updateHook
      .request({mapSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<MapSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="楼层" name="floorId" hidden={!!props?.floorId}>
          <FloorSelector placeholder="请选择对应楼层" />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item label="地图文件" name="url">
              <Flex direction={'column'}>
                <Image height={500} key={url} src={url} />
                <Dragger
                  style={{flex: 1}}
                  name="file"
                  multiple={false}
                  action={`${Config.basePath}/ftp/uploadImage`}
                  onChange={info => {
                    const {status} = info.file;
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                      message.success(`${info.file.name} 上传成功`);
                    } else if (status === 'error') {
                      message.error(`${info.file.name} 上传失败`);
                    }
                    let fileList = [...info.fileList];

                    // 1. Limit the number of uploaded files
                    // Only to show two recent uploaded files, and old ones will be replaced by the new
                    fileList = fileList.slice(-1);

                    // 2. Read from response and show file link
                    fileList = fileList.map(file => {
                      if (file.response) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                        form.setFieldsValue({
                          url: file.response.url,
                        });
                      }
                      return file;
                    });

                    setFileList(fileList.concat([]));
                  }}
                  beforeUpload={file => {
                    if (file.type === 'image/jpeg' || file.type === 'image/png') {
                      return true;
                    } else {
                      message.error('请上传 jpeg/png 格式的照片');
                      return false;
                    }
                  }}
                  fileList={fileList}>
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或者拖拽上传</p>
                    <p className="ant-upload-hint">请上传 png / jpeg 格式的地图文件</p>
                  </div>
                </Dragger>
              </Flex>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label="坐标是否转置" name="isTranspose">
          <Switch />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>

        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default MapEditForm;
