import React, {useEffect, useMemo, useState} from 'react';
import './styles/app.less';
import 'normalize.css/normalize.css';
import 'snack-helper/dist/snack-helper.css';
import './assets/iconfont/iconfont.css';
import LoginPage from './pages/login/login';
import {AuthProvider, Alert, useAuthContext} from 'geestack-toolbox';
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom';
import {useMount} from 'react-use';
import AxiosProvider from './Http/AxiosProvider';
import {Button, ConfigProvider, message, Space} from 'antd';
import AppLayout from './pages/_appLayout';
import {Config} from './config';
import zhCN from 'antd/lib/locale/zh_CN';
import {onRequestFulfilled, onResponseReject} from './utils/HttpInstance';

const App: React.FunctionComponent<any> = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <AuthProvider>
        <AxiosProvider
          onResponseReject={onResponseReject}
          onRequestFulfilled={onRequestFulfilled}
          axiosConfig={{
            timeout: 15 * 60 * 1000,
            baseURL: Config.basePath,
          }}
          defaultErrorHandle={error => message.error(error.message)}>
          <BrowserRouter>
            <Switch>
              <Route path={'/login'} component={LoginPage} />
              <Route path={'/'} component={AppLayout} />
            </Switch>
          </BrowserRouter>
        </AxiosProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
