import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {ActionTypeApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import ActionTypeTable from '../../components/ActionType/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import ActionTypeEditForm from '../../components/ActionType/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function ActionTypeIndexPage() {
  const searchHook = useOpenApiFpRequest(ActionTypeApi, ActionTypeApi.prototype.actionTypeSearchGet);
  const isAddActionType = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          操作类型管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddActionType.setTrue}>
              录入操作类型
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <ActionTypeTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入操作类型'} visible={isAddActionType.value} maskClosable={false} closable={false} footer={false}>
        <ActionTypeEditForm
          onCancel={isAddActionType.setFalse}
          onSuccess={res => {
            isAddActionType.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
