import React from 'react';
import {Checkbox, Col, Row} from 'antd';
import {CheckboxGroupProps} from 'antd/es/checkbox';
import {UserRolePermissionType} from './UserRolePermissionType';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';

export type IProps = CheckboxGroupProps;
export function UserRolePermissionCheckbox(props: IProps) {
  const list = [
    UserRolePermissionType.下发任务,
    UserRolePermissionType.任务控制,
    UserRolePermissionType.任务查看,
    UserRolePermissionType.历史任务查询,
    UserRolePermissionType.日志查询,
    UserRolePermissionType.历史外接设备状态查询,
    UserRolePermissionType.外接设备状态查询,
    UserRolePermissionType.用户管理,
    UserRolePermissionType.地点管理,
    UserRolePermissionType.外设管理,
    UserRolePermissionType.任务基础数据管理,
    UserRolePermissionType.音频对讲,
    UserRolePermissionType.音频视频,
    UserRolePermissionType.机器人管理,
  ];
  return (
    <Checkbox.Group style={{width: '100%'}} {...props}>
      <Row>
        {list.map(i => (
          <Col key={UserRolePermissionType[i]} span={6}>
            <Checkbox value={UserRolePermissionType[i]}>{UserRolePermissionType[i]}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
}
