import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, PeripheralAbilityApi, PeripheralAbilityApiFactory, PeripheralAbilityDto, PeripheralAbilitySaveParams} from '../../scaffold';
import RobotSelector from '../Robot/Selector';

const PeripheralAbilityEditForm = (props: {onSuccess?: (res: PeripheralAbilityDto) => any; peripheralAbility?: PeripheralAbilityDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<PeripheralAbilitySaveParams>();
  const updateHook = useOpenApiFpRequest(PeripheralAbilityApi, PeripheralAbilityApi.prototype.peripheralAbilitySavePost);

  function reset() {
    form.setFieldsValue({
      id: props.peripheralAbility?.id,
      name: props.peripheralAbility?.name ?? undefined,
      robotId: props.peripheralAbility?.robotId ?? undefined,
      isDelete: props.peripheralAbility?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.peripheralAbility]);

  function onSubmit(data: PeripheralAbilitySaveParams) {
    updateHook
      .request({peripheralAbilitySaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<PeripheralAbilitySaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="名称" name="name" rules={[{required: true, message: '请输入名称'}]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item label="机器人" name="robotId" rules={[{required: true, message: '请选择机器人'}]}>
          <RobotSelector placeholder="请选择机器人" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default PeripheralAbilityEditForm;
