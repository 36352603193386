import React, {useEffect, useState} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi, PositionApi, PositionBriefSearchItemDto} from '../../scaffold';
import {useAntdTable, useDebounceEffect} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import RobotPositionHistoryTable from './Table';
import moment, {Moment} from 'moment';
import {Button, Card, Col, DatePicker, Form, Input, Modal, Row, Steps, Tabs, TimePicker, Typography} from 'antd';
import FloorSelector from '../Floor/Selector';
import {MissionBasicDescriptions} from '../Mission/BasicDescriptions';
import {MissionLogSteps} from '../Mission/LogSteps';
import {MissionTemplateDetail} from '../MissionTemplate/Detail';
import Flex from '../Shared/Flex';
import {RobotMapTrace} from '../Robot/MapTrace';
import {RobotMapTraceHistory} from '../Robot/MapTraceHistory';
export function RobotPositionHistoryTableCtrl(props: {robotId?: number}) {
  const searchHook = useOpenApiFpRequest(PositionApi, PositionApi.prototype.positionSearchGet);
  const listHook = useOpenApiFpRequest(PositionApi, PositionApi.prototype.positionBriefSearchGet);
  const [date, setDate] = useState<moment.Moment>(moment());
  const [selected, setSelected] = useState<PositionBriefSearchItemDto>();
  const [s, setS] = useState<string>();
  const [floorId, setFloorId] = useState<number>();
  const [timeRange, setTimeRange] = useState<any>([moment().startOf('date'), moment().endOf('day')]);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        from: date.format('YYYY-MM-DD ') + timeRange[0]?.format('HH:mm:ss'),
        to: date.format('YYYY-MM-DD ') + timeRange[1]?.format('HH:mm:ss'),
        robotId: props.robotId,
        s,
        floorId,
      }),
    {
      ...DefaultAntdTableConfig,
      onSuccess: () => {
        console.log('success');
      },
    },
  );
  useDebounceEffect(
    () => {
      props.robotId && antdConfig.search.submit();
      props.robotId &&
        listHook.requestSync({
          from: date.format('YYYY-MM-DD ') + timeRange[0]?.format('HH:mm:ss'),
          to: date.format('YYYY-MM-DD ') + timeRange[1]?.format('HH:mm:ss'),
          robotId: props.robotId,
          s,
          floorId,
        });
    },
    [props.robotId, timeRange, date, s, floorId],
    {
      wait: 200,
    },
  );
  return (
    <>
      <Form layout={'inline'} style={{marginBottom: 8}}>
        <Form.Item label={'日期'}>
          <DatePicker allowClear={false} value={date} onChange={v => v && setDate(v)} />
        </Form.Item>
        <Form.Item label={'时间'}>
          <TimePicker.RangePicker allowClear={false} value={timeRange} onChange={v => setTimeRange(v)} />
        </Form.Item>
        <Form.Item label={'楼层'}>
          <FloorSelector
            allowClear={true}
            placeholder={'请选择楼层以查询'}
            value={floorId as any}
            onChange={v => setFloorId(v ? Number(v) : undefined)}
            style={{width: 200}}
          />
        </Form.Item>
        <Form.Item label={'地点'}>
          <Input value={s} onChange={v => setS(v.target.value)} placeholder={'请输入地点名以查询'} />
        </Form.Item>
      </Form>
      <Row gutter={8}>
        <Col span={16}>
          <RobotPositionHistoryTable antDTable={antdConfig} />
        </Col>
        <Col span={8}>
          <Card>
            <Steps progressDot={true} direction={'vertical'}>
              {listHook.data?.map(i => (
                <Steps.Step
                  key={i.key}
                  title={
                    <Flex align={'center'}>
                      <Typography.Text>{i.firstPosition?.floor?.name}</Typography.Text>
                      <Button
                        type="link"
                        onClick={() => {
                          setSelected(i);
                        }}>
                        查看轨迹
                      </Button>
                    </Flex>
                  }
                  description={
                    <Typography.Text>
                      {moment(i.firstPosition?.createdTime).format('MM月DD日 HH时mm分')} ~ {moment(i.lastPosition?.createdTime).format('MM月DD日 HH时mm分')}
                    </Typography.Text>
                  }
                />
              ))}
            </Steps>
          </Card>
        </Col>
      </Row>
      <Modal destroyOnClose={true} visible={!!selected} onCancel={() => setSelected(undefined)} title={'轨迹'} width={'80%'} style={{top: 32}} footer={null}>
        <RobotMapTraceHistory height={600} positionKey={selected?.key ?? undefined} />
      </Modal>
    </>
  );
}
