import React, {useEffect, useMemo, useRef, useState} from 'react';
import Title from 'antd/lib/typography/Title';
import {Avatar, Button, Comment, List, Modal, notification, Progress, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import Flex from '../../components/Shared/Flex';
import VoiceImage from '../../assets/voice.png';
import VoicePlayingImage from '../../assets/playing.gif';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {AudioOutlined} from '@ant-design/icons';
import {ChatHistoryApi, ChatHistoryDto, SorterOrder} from '../../scaffold';
import {UseBoolean, useBoolean, useNumber} from 'react-hanger';
import {useMediaRecorder} from '../../hooks/useMediaRecorder';
import {useMount, useUpdate} from 'ahooks';
import useAuth from '../../utils/AuthContext';
import {useAxios} from '../../Http/AxiosProvider';
import UserAvatar from '../../components/User/UserAvatar';
import {Config} from '../../config';
interface MessageUnit {
  Name: string;
  Addr?: string;
}

export function AudioChatIndexPage() {
  const searchHook = useOpenApiFpRequest(ChatHistoryApi, ChatHistoryApi.prototype.chatHistorySearchGet);
  const [ws, setWs] = useState<WebSocket>();
  const pageIndex = useNumber(1);
  const pageSize = useNumber(10);
  const isCancel = useBoolean(false);
  const isRecording = useBoolean(false);
  const update = useUpdate();
  const [playingAudioId, setPlayingAudioId] = useState<number>();
  const mediaRecorder = useMediaRecorder();
  const auth = useAuth();
  const axios = useAxios();
  const isCancelRef = useRef<UseBoolean>();
  const uploadAudioHook = useOpenApiFpRequest(ChatHistoryApi, ChatHistoryApi.prototype.chatHistoryUploadAudioPost);

  isCancelRef.current = isCancel;
  function refresh() {
    searchHook.requestSync({
      pi: pageIndex.value,
      ps: pageSize.value,
      sorterOrder: SorterOrder.Desc,
      sorterKey: 'createdTime',
    });
  }

  useMount(async () => {
    await mediaRecorder.requestPermission((blob: Blob, audioURL: string) => {
      if (!isCancelRef.current?.value) {
        const audio = new Audio(audioURL);
        audio.play();
        const formData = new FormData();
        formData.set('senderName', auth.currentUser?.name ?? '未知发送人');
        formData.append('files', blob);
        axios.instance.post('/chatHistory/uploadAudio', formData);
        setTimeout(() => {
          refresh();
        }, 600);
      }
    });
  });
  useEffect(() => {
    refresh();
  }, [pageIndex, pageSize]);
  useEffect(() => {
    const ws = new WebSocket(`ws://${Config.wsHost}:${Config.wsPort}/ws`);

    ws.onopen = () => {
      console.log('opened');
    };
    ws.onmessage = msg => {
      console.log(msg);
      const unit: MessageUnit = JSON.parse(msg.data);
      if (unit.Name === 'Voice_talk') {
        const audio = new Audio(unit.Addr);
        audio.play();
        refresh();
      }
    };
    ws.onclose = () => {
      console.log('closed');
    };
    return () => {
      ws.close();
    };
  }, []);
  const list = useMemo(() => {
    return searchHook.data?.list?.reverse() ?? [];
  }, [searchHook.data]);

  return (
    <LayoutPage
      style={{background: '#f3f3f3'}}
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            实时对讲
          </Title>
          <Typography.Text type={'secondary'}>点击右下角麦克风图标进行对话</Typography.Text>
        </div>
      }>
      <List<ChatHistoryDto>
        className="comment-list"
        itemLayout="horizontal"
        style={{marginBottom: 32, display: 'flex', flexDirection: 'column-reverse'}}
        dataSource={list}
        renderItem={item => (
          <Flex>
            <Comment
              author={item.senderName}
              avatar={
                <UserAvatar
                  user={{
                    id: item.senderName?.includes('机器人') ? 1 : 0,
                    name: item.senderName,
                  }}
                />
              }
              content={
                <Button
                  style={{
                    padding: 12,
                    borderRadius: 10,
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                  onClick={() => {
                    setPlayingAudioId(item.id);
                    const audio = new Audio(item.chatAddress ?? '');
                    audio.play();
                    audio.onended = () => {
                      setPlayingAudioId(undefined);
                    };
                    audio.onerror = () => {
                      setPlayingAudioId(undefined);
                    };
                  }}>
                  <Flex>
                    <img style={{width: 25}} src={item.id === playingAudioId ? VoicePlayingImage : VoiceImage} alt={'voice'} />
                    <Typography.Text style={{marginLeft: 8}} type={'secondary'}>
                      {item.id === playingAudioId ? '播放中...' : '点击播放'}
                    </Typography.Text>
                  </Flex>
                </Button>
              }
              datetime={item.createdTime}
            />
          </Flex>
        )}
      />
      <Flex justify={'flex-end'} style={{position: 'fixed', bottom: 50, left: 100, right: 100, pointerEvents: 'none'}}>
        <Button
          type="primary"
          shape="circle"
          style={{width: 80, height: 80, boxShadow: '0px 0px 40px 10px rgba(22,22,22,0.4)', pointerEvents: 'auto'}}
          icon={<AudioOutlined style={{fontSize: 28}} />}
          onClick={async () => {
            (() => {
              mediaRecorder.start(50);
            })();
            isRecording.setTrue();
          }}
        />
      </Flex>
      <Modal
        maskClosable={false}
        visible={isRecording.value}
        onCancel={() => {
          mediaRecorder.stop();
          console.log('取消');
          isCancel.setTrue();
          isRecording.setFalse();
          update();
        }}
        footer={null}>
        <Flex direction={'column'} align={'center'} justify={'center'} style={{padding: 20}}>
          <Progress
            style={{margin: 'auto'}}
            width={200}
            type="dashboard"
            percent={mediaRecorder.volume.value}
            size="small"
            format={() => <AudioOutlined style={{fontSize: 50}} />}
          />
          <Button
            type="link"
            size={'large'}
            onClick={() => {
              mediaRecorder.stop();
              isRecording.setFalse();
              isCancel.setFalse();
            }}>
            发送
          </Button>
          {/*<Typography.Text style={{fontSize: 24}}>{mediaRecorder.volume.value}</Typography.Text>*/}
          {/*<Typography.Text style={{fontSize: 24}}>录制中</Typography.Text>*/}
        </Flex>
      </Modal>
    </LayoutPage>
  );
}
