import {Image as KonvaImage, Rect} from 'react-konva';
import {Circle} from 'react-konva';
import {Html} from 'react-konva-utils';
import React, {useMemo} from 'react';
import LocateImage from '../../assets/locate.png';
import {useNumber} from 'react-hanger';
import {useInterval} from 'ahooks';

export function ShiningMapDot(props: {x: number; y: number; scale: number; color?: string}) {
  const radius = useNumber(20);

  const locateImageSize = 30;
  const locateImage = useMemo(() => {
    const img = new Image();
    img.src = LocateImage;
    return img;
  }, []);
  useInterval(() => {
    if (radius.value > 100) {
      radius.value = 20;
    }
    radius.setValue(radius.value + 1);
  }, 20);
  return (
    <>
      <KonvaImage
        width={locateImageSize}
        height={locateImageSize}
        x={(props.x - locateImageSize / 2) * props.scale}
        y={(props.y - locateImageSize / 2) * props.scale}
        scaleX={props.scale}
        scaleY={props.scale}
        image={locateImage}
      />
      <Circle
        x={props.x * props.scale}
        y={props.y * props.scale}
        scaleX={props.scale}
        scaleY={props.scale}
        radius={radius.value}
        opacity={2.2 / radius.value}
        fill={props.color ?? '#2196F3'}
        width={24}
      />
    </>
  );
}
