import React, {useEffect, useRef, useState} from 'react';
import {FloorDto, MapApi, MapCalibrateParams, MapDto, MapSaveParams} from '../../scaffold';
import {Button, Form, Input, InputNumber, message, Modal, Slider, Space, Typography} from 'antd';
import {UploadFile} from 'antd/lib/upload/interface';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Layer, Stage, Image as KonvaImage, Circle, Group, Label, Text, Tag} from 'react-konva';
import ImageMapper, {ImageMapperProps} from 'react-img-mapper';
import {IMapPointerValue, MapPointer} from '../Map/MapPointer';
import {useBoolean} from 'react-hanger';

export function MapCalibrationForm(props: {onSuccess?: (res: MapDto) => any; map?: MapDto; onCancel?: () => any; floorId?: number}) {
  const [form] = Form.useForm<MapCalibrateParams>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const calibrateHook = useOpenApiFpRequest(MapApi, MapApi.prototype.mapCalibratePost);
  const [url, setUrl] = useState<string>();
  const [firstPoint, setFirstPoint] = useState<IMapPointerValue>();
  const isConfigFirst = useBoolean(false);
  const isConfigSecond = useBoolean(false);
  const [secondPoint, setSecondPoint] = useState<IMapPointerValue>();
  const [pointCache, setPointCache] = useState<IMapPointerValue>();

  const imageRef = useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;

  function reset() {
    setUrl(props.map?.url ?? undefined);
  }

  useEffect(() => {
    reset();
  }, [props.map]);

  function onSubmit(data: MapCalibrateParams) {
    props.map?.id &&
      calibrateHook
        .request({
          mapCalibrateParams: {
            ...data,
            point1X: Number(firstPoint?.x),
            point1Y: Number(firstPoint?.y),
            point2X: Number(secondPoint?.x),
            point2Y: Number(secondPoint?.y),
            id: props.map?.id,
          },
        })
        .then(res => {
          message.success('操作成功');
          reset();
          props.onSuccess && props.onSuccess(res);
        })
        .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<MapCalibrateParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label={'请选择第一个校准点'}>
          <Button
            onClick={() => {
              isConfigFirst.setTrue();
            }}>
            点击设置校准点
          </Button>
          {firstPoint && (
            <Typography.Text>
              已选择坐标点: {firstPoint?.x.toFixed(4)}, {firstPoint?.y.toFixed(4)}
            </Typography.Text>
          )}
        </Form.Item>
        <Form.Item label={'请输入对应的坐标'} hidden>
          <Input.Group compact>
            <Form.Item name={'point1X'} noStyle rules={[{required: true, message: '请输入机器人 X 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 X 坐标" />
            </Form.Item>
            <Form.Item name={'point1Y'} noStyle rules={[{required: true, message: '请输入机器人 Y 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 Y 坐标" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label={'请输入对应的机器人坐标'}>
          <Input.Group compact>
            <Form.Item name={'point1RobotX'} noStyle rules={[{required: true, message: '请输入机器人 X 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 X 坐标" />
            </Form.Item>
            <Form.Item name={'point1RobotY'} noStyle rules={[{required: true, message: '请输入机器人 Y 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 Y 坐标" />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item label={'请输入对应的坐标'} hidden>
          <Input.Group compact>
            <Form.Item name={'point2X'} noStyle rules={[{required: true, message: '请输入 X 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入 X 坐标" />
            </Form.Item>
            <Form.Item name={'point2Y'} noStyle rules={[{required: true, message: '请输入 Y 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入 Y 坐标" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label={'请选择第二个校准点'}>
          <Button
            onClick={() => {
              isConfigSecond.setTrue();
            }}>
            点击设置校准点
          </Button>
          {secondPoint && (
            <Typography.Text>
              已选择坐标点: {secondPoint?.x.toFixed(4)}, {secondPoint?.y.toFixed(4)}
            </Typography.Text>
          )}
        </Form.Item>
        <Form.Item label={'请输入对应的机器人坐标'}>
          <Input.Group compact>
            <Form.Item name={['point2RobotX']} noStyle rules={[{required: true, message: '请输入机器人 X 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 X 坐标" />
            </Form.Item>
            <Form.Item name={['point2RobotY']} noStyle rules={[{required: true, message: '请输入机器人 Y 坐标'}]}>
              <Input style={{width: '40%'}} placeholder="请输入机器人 Y 坐标" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={calibrateHook.loading} disabled={calibrateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>

      <Modal
        visible={isConfigFirst.value}
        onCancel={isConfigFirst.setFalse}
        destroyOnClose={true}
        style={{top: 20}}
        footer={
          <Button
            type={'primary'}
            onClick={() => {
              setFirstPoint(pointCache);
              form.setFieldsValue({
                point1X: pointCache?.x,
                point1Y: pointCache?.y,
              });
              setPointCache(undefined);

              isConfigFirst.setFalse();
            }}>
            确认选择
          </Button>
        }
        width={'80%'}>
        <MapPointer
          height={innerHeight * 0.8}
          url={url}
          onChange={v => {
            setPointCache(v);
          }}
        />
      </Modal>
      <Modal
        visible={isConfigSecond.value}
        onCancel={isConfigSecond.setFalse}
        destroyOnClose={true}
        style={{top: 20}}
        footer={
          <Button
            type={'primary'}
            onClick={() => {
              setSecondPoint(pointCache);
              form.setFieldsValue({
                point2X: pointCache?.x,
                point2Y: pointCache?.y,
              });
              setPointCache(undefined);
              isConfigSecond.setFalse();
            }}>
            确认选择
          </Button>
        }
        width={'80%'}>
        <MapPointer
          height={innerHeight * 0.8}
          url={url}
          onChange={v => {
            setPointCache(v);
          }}
        />
      </Modal>
    </div>
  );
}
