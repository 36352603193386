import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, SpotDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table, TableProps, Tag, Typography} from 'antd';
import SpotUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {SpotTypeTag} from '../SpotType/SpotTypeTag';

export default function SpotTable(props: {antDTable: Result<SpotDto>; tableProps?: TableProps<SpotDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<SpotDto> {...tableProps} {...props.tableProps} rowKey={'id'} bordered>
      <Table.Column<SpotDto>
        title="终点"
        width={220}
        dataIndex={'name'}
        sorter
        align={'center'}
        render={(txt, row) => (
          <Typography.Text strong>
            {row.floor?.building?.name} / {row.floor?.name} / {row.name}
          </Typography.Text>
        )}
      />
      <Table.Column<SpotDto>
        title="机器人指令代码"
        width={220}
        dataIndex={'name'}
        sorter
        align={'center'}
        render={(txt, row) => (
          <Typography.Text strong>
            {row.floor?.commandName}
            {row.commandName}
          </Typography.Text>
        )}
      />
      <Table.Column<SpotDto>
        width={120}
        title="类型"
        dataIndex={['spotType', 'name']}
        sorter
        align={'center'}
        render={(txt, row) => <SpotTypeTag spotType={row.spotType} />}
      />
      <Table.Column<SpotDto> width={200} title="创建时间" dataIndex={'createdTime'} sorter align={'center'} />
      <Table.Column<SpotDto> title="备注" dataIndex={'remark'} sorter />
      {/*<Table.Column<SpotDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />*/}
      <Table.Column<SpotDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <SpotUpdateInfoBtn spot={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
