import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, PeripheralDto, VideoApi, VideoRecordDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table, Tag} from 'antd';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import moment from 'moment';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {config} from 'ace-builds';
import {Config} from '../../config';

export default function VideoRecordTable(props: {antDTable: Result<VideoRecordDto>}) {
  const {tableProps, search, loading} = props.antDTable;
  const stopHook = useOpenApiFpRequest(VideoApi, VideoApi.prototype.videoStopRecordingPost);

  return (
    <Table<VideoRecordDto> {...tableProps} size={'small'} rowKey={'id'} bordered>
      <Table.Column<VideoRecordDto> title="ID" dataIndex="id" sorter width={60} />
      <Table.Column<VideoRecordDto> title="开始时间" dataIndex={'beginTime'} sorter width={160} />
      <Table.Column<VideoRecordDto> title="结束时间" dataIndex={'endTime'} sorter width={160} />
      <Table.Column<VideoRecordDto>
        title="时长"
        width={160}
        render={(_, item) => {
          let seconds = moment().diff(item.beginTime, 'seconds');
          if (item.endTime) seconds = moment(item.endTime).diff(item.beginTime, 'seconds');
          const h = seconds / 3600;
          const m = (seconds / 60) % 60;
          const s = seconds % 60;
          return `${h.toFixed()} 小时 ${m.toFixed()} 分 ${s.toFixed()} 秒`;
        }}
      />
      <Table.Column<VideoRecordDto>
        title="状态"
        width={80}
        render={(_, item) => (item.endTime ? <Tag color={'green'}>录制结束</Tag> : <Tag color={'blue'}>录制中</Tag>)}
      />
      <Table.Column<VideoRecordDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              {!row.endTime && (
                <a
                  onClick={() =>
                    stopHook
                      .request({videoRecordStopRecordParams: {id: row.id ?? 0}})
                      .then(props.antDTable.refresh)
                      .catch(CommonApiErrorHandler)
                  }
                  aria-disabled={stopHook.loading}>
                  停止
                </a>
              )}
              {row.endTime && (
                <>
                  <a
                    onClick={() => {
                      window.open(`${Config.staticPrefix}${row.path}/0.mp4`);
                    }}
                    aria-disabled={stopHook.loading}>
                    0
                  </a>
                  <a
                    onClick={() => {
                      window.open(`${Config.staticPrefix}${row.path}/1.mp4`);
                    }}
                    aria-disabled={stopHook.loading}>
                    1
                  </a>
                  <a
                    onClick={() => {
                      window.open(`${Config.staticPrefix}${row.path}/2.mp4`);
                    }}
                    aria-disabled={stopHook.loading}>
                    2
                  </a>
                  <a
                    onClick={() => {
                      window.open(`${Config.staticPrefix}${row.path}/3.mp4`);
                    }}
                    aria-disabled={stopHook.loading}>
                    3
                  </a>
                </>
              )}
            </Space>
          );
        }}
      />
    </Table>
  );
}
