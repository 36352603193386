import React from 'react';
import Flex from '../components/Shared/Flex';
import {Typography} from 'antd';

export function DevPage() {
  return (
    <Flex align={'center'} justify={'center'} style={{height: '100%'}}>
      <Typography.Title>开发中</Typography.Title>
    </Flex>
  );
}
