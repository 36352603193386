import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Select, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, RobotApi, RobotApiFactory, RobotDto, RobotSaveParams} from '../../scaffold';
import UserSelector from '../User/Selector';
import BuildingSelector from '../Building/Selector';

const RobotEditForm = (props: {onSuccess?: (res: RobotDto) => any; robot?: RobotDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<RobotSaveParams>();
  const updateHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.robot?.id,
      name: props.robot?.name ?? undefined,
      principalId: props.robot?.principalId,
      fireControlIpHost: props.robot?.fireControlIpHost ?? undefined,
      fireControlIpPort: props.robot?.fireControlIpPort ?? undefined,
      chatIpHost: props.robot?.chatIpHost ?? undefined,
      chatIpPort: props.robot?.chatIpPort ?? undefined,
      ipHost: props.robot?.ipHost ?? undefined,
      ipPort: props.robot?.ipPort ?? undefined,
      remark: props.robot?.remark ?? undefined,
      buildingId: props.robot?.buildingId ?? undefined,
      isDelete: props.robot?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.robot]);

  function onSubmit(data: RobotSaveParams) {
    updateHook
      .request({robotSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<RobotSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="名称" name="name" rules={[{required: true, message: '请输入楼层名'}]}>
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item label="负责人" name="principalId">
          <UserSelector placeholder="请选择机器人负责人" />
        </Form.Item>
        <Form.Item label="楼栋" name="buildingId">
          <BuildingSelector placeholder="请选择所在楼栋" />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="消防 Socket 客户端 IP">
          <Input.Group compact>
            <Form.Item name={'fireControlIpHost'} noStyle rules={[{required: true, message: '请输入消防 Socket 客户端 IP 地址'}]}>
              <Input style={{width: '60%'}} placeholder="IP 地址, 如: 192.168.50.32" />
            </Form.Item>
            <Form.Item name={['fireControlIpPort']} noStyle rules={[{required: true, message: '请输入消防 Socket 客户端 IP 端口'}]}>
              <Input style={{width: '40%'}} placeholder="端口, 如: 21452" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="语音 Socket 客户端 IP">
          <Input.Group compact>
            <Form.Item name={'chatIpHost'} noStyle rules={[{required: true, message: '请输入语音 Socket 客户端 IP 地址'}]}>
              <Input style={{width: '60%'}} placeholder="IP 地址, 如: 192.168.50.32" />
            </Form.Item>
            <Form.Item name={['chatIpPort']} noStyle rules={[{required: true, message: '请输入语音 Socket 客户端 IP 端口'}]}>
              <Input style={{width: '40%'}} placeholder="端口, 如: 21452" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="本体 Socket 客户端 IP">
          <Input.Group compact>
            <Form.Item name={'ipHost'} noStyle rules={[{required: true, message: '请输入本体 Socket 客户端 IP 地址'}]}>
              <Input style={{width: '60%'}} placeholder="IP 地址, 如: 192.168.50.32" />
            </Form.Item>
            <Form.Item name={['ipPort']} noStyle rules={[{required: true, message: '请输入本体 Socket 客户端 IP 端口'}]}>
              <Input style={{width: '40%'}} placeholder="端口, 如: 21452" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default RobotEditForm;
