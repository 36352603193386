import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, UserRoleDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table} from 'antd';
import UserRoleUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import UserRoleEditPermissionBtn from './EditPermissionBtn';

export default function UserRoleTable(props: {antDTable: Result<UserRoleDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<UserRoleDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<UserRoleDto> title="ID" dataIndex="id" sorter />
      <Table.Column<UserRoleDto> title="角色名" dataIndex={'name'} sorter />
      <Table.Column<UserRoleDto> title="备注" dataIndex={'remark'} sorter />
      <Table.Column<UserRoleDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
      <Table.Column<UserRoleDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <UserRoleUpdateInfoBtn userRole={row} type={'link'} size={'small'} onSuccess={search.submit} />
              <UserRoleEditPermissionBtn userRole={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
