import React, {useEffect, useMemo, useState} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {PeripheralApi, PeripheralHistoryApi, PositionBriefSearchItemDto, SorterOrder} from '../../scaffold';
import {useAntdTable, useDebounceEffect} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import PeripheralHistoryTable from './Table';
import moment from 'moment';
import {Card, Col, DatePicker, Form, Input, message, Modal, Row, Spin, Tabs, TimePicker} from 'antd';
import {RobotMapTraceHistory} from '../Robot/MapTraceHistory';

export function PeripheralHistoryTableCtrl(props: {robotId?: number}) {
  const searchHook = useOpenApiFpRequest(PeripheralHistoryApi, PeripheralHistoryApi.prototype.peripheralHistorySearchGet);
  const listPeripheralsHook = useOpenApiFpRequest(PeripheralApi, PeripheralApi.prototype.peripheralListByRobotGet);
  const [date, setDate] = useState<moment.Moment>(moment());
  const [peripheralId, setPeripheralId] = useState<number>();
  const [selected, setSelected] = useState<PositionBriefSearchItemDto>();
  const [timeRange, setTimeRange] = useState<any>([moment().startOf('date'), moment().endOf('day')]);
  const [status, setStatus] = useState<string>();
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        from: date.format('YYYY-MM-DD ') + timeRange[0]?.format('HH:mm:ss'),
        to: date.format('YYYY-MM-DD ') + timeRange[1]?.format('HH:mm:ss'),
        peripheralId,
        status,
        sorterOrder: SorterOrder.Asc,
        sorterKey: 'createdTime',
      }),
    {
      ...DefaultAntdTableConfig,
      onSuccess: () => {
        console.log('success');
      },
    },
  );

  useEffect(() => {
    props.robotId &&
      listPeripheralsHook
        .request({
          robotId: props.robotId,
        })
        .then(r => {
          if (r.length > 0) {
            setPeripheralId(r[0].id);
          }
        })
        .catch(e => message.error(e));
  }, [props.robotId]);

  useDebounceEffect(
    () => {
      peripheralId && antdConfig.search.submit();
    },
    [peripheralId, timeRange, date],
    {wait: 200},
  );

  const steps = useMemo(() => {
    return [];
  }, [searchHook.data]);

  return (
    <>
      <Tabs defaultActiveKey="1" tabPosition="left" onChange={v => setPeripheralId(Number(v))}>
        {listPeripheralsHook.data?.map(i => (
          <Tabs.TabPane tab={i.name} key={i.id}>
            <Form layout={'inline'} style={{marginBottom: 8}}>
              <Form.Item label={'日期'}>
                <DatePicker allowClear={false} value={date} onChange={v => v && setDate(v)} />
              </Form.Item>
              <Form.Item label={'时间'}>
                <TimePicker.RangePicker allowClear={false} value={timeRange} onChange={v => setTimeRange(v)} />
              </Form.Item>
              <Form.Item label={'状态'}>
                <Input placeholder={'请输入状态'} value={status} onChange={v => setStatus(v.target.value)} />
              </Form.Item>
            </Form>
            <Row gutter={8} style={{marginTop: 16}}>
              <Col span={16}>
                <Spin spinning={listPeripheralsHook.loading}>
                  <PeripheralHistoryTable tableProps={{loading: listPeripheralsHook.loading}} antDTable={antdConfig} />
                </Spin>
              </Col>
              {/*<Col span={8}>*/}
              {/*  <Card>*/}
              {/*    <Steps progressDot={true} direction={'vertical'}>*/}
              {/*      {listHook.data?.map(i => (*/}
              {/*        <Steps.Step*/}
              {/*          key={i.key}*/}
              {/*          title={*/}
              {/*            <Flex align={'center'}>*/}
              {/*              <Typography.Text>{i.firstPosition?.floor?.name}</Typography.Text>*/}
              {/*              <Button*/}
              {/*                type="link"*/}
              {/*                onClick={() => {*/}
              {/*                  setSelected(i);*/}
              {/*                }}>*/}
              {/*                查看轨迹*/}
              {/*              </Button>*/}
              {/*            </Flex>*/}
              {/*          }*/}
              {/*          description={*/}
              {/*            <Typography.Text>*/}
              {/*              {moment(i.firstPosition?.createdTime).format('MM月DD日 HH时mm分')} ~ {moment(i.lastPosition?.createdTime).format('MM月DD日 HH时mm分')}*/}
              {/*            </Typography.Text>*/}
              {/*          }*/}
              {/*        />*/}
              {/*      ))}*/}
              {/*    </Steps>*/}
              {/*  </Card>*/}
              {/*</Col>*/}
            </Row>
          </Tabs.TabPane>
        ))}
      </Tabs>

      <Modal destroyOnClose={true} visible={!!selected} onCancel={() => setSelected(undefined)} title={'轨迹'} width={'80%'} style={{top: 32}} footer={null}>
        <RobotMapTraceHistory height={600} positionKey={selected?.key ?? undefined} />
      </Modal>
    </>
  );
}
