import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, MissionTemplateDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table, Typography} from 'antd';
import MissionTemplateUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import UserAvatar from '../User/UserAvatar';
import Flex from '../Shared/Flex';
import {RiBuildingLine, SiRobotframework} from 'react-icons/all';
import RobotImage from '../../assets/robot-head.png';
export default function MissionTemplateTable(props: {antDTable: Result<MissionTemplateDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<MissionTemplateDto> {...tableProps} rowKey={'id'} bordered size={'large'}>
      <Table.Column<MissionTemplateDto> title="ID" dataIndex="id" sorter />
      <Table.Column<MissionTemplateDto> title="名称" dataIndex={'name'} sorter />
      <Table.Column<MissionTemplateDto>
        title="机器人"
        dataIndex={['robot', 'name']}
        sorter
        render={(txt, row) =>
          row.creator && (
            <Flex align={'center'}>
              <img src={RobotImage} alt={'robot'} style={{height: 30}} />
              {/*<SiRobotframework size={20} />*/}
              <Typography.Text style={{marginLeft: 8}}>{txt}</Typography.Text>
            </Flex>
          )
        }
      />
      <Table.Column<MissionTemplateDto>
        title="楼栋"
        dataIndex={['building', 'name']}
        sorter
        render={(txt, row) =>
          row.creator && (
            <Flex align={'center'}>
              <RiBuildingLine size={20} />
              <Typography.Text style={{marginLeft: 8}}>{txt}</Typography.Text>
            </Flex>
          )
        }
      />
      <Table.Column<MissionTemplateDto>
        title="创建者"
        dataIndex={['creator', 'name']}
        sorter
        render={(txt, row) =>
          row.creator && (
            <Flex align={'center'}>
              <UserAvatar user={row.creator} />
              <Typography.Text style={{marginLeft: 8}}>{txt}</Typography.Text>
            </Flex>
          )
        }
      />
      <Table.Column<MissionTemplateDto> title="执行次数" dataIndex={['repeatCount']} render={txt => txt + ' 次'} sorter />
      <Table.Column<MissionTemplateDto> title="完成后是否回桩" dataIndex={['autoAppendRecharge']} render={txt => (txt ? '回桩' : '停留原地')} sorter />
      <Table.Column<MissionTemplateDto> title="创建时间" dataIndex={['createdTime']} sorter />
      <Table.Column<MissionTemplateDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <MissionTemplateUpdateInfoBtn
                missionTemplate={row}
                type={'link'}
                size={'small'}
                onSuccess={() => {
                  search.submit();
                }}
              />
            </Space>
          );
        }}
      />
    </Table>
  );
}
