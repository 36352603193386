import React from 'react';
import {Divider, Radio, RadioGroupProps, Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {Gender, PeripheralType, MissionTemplateApi} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {useMount} from 'ahooks';
import {RadioProps} from 'antd/lib/radio';

export default function MissionTemplateRadio(props: RadioProps) {
  const searchHook = useOpenApiFpRequest(MissionTemplateApi, MissionTemplateApi.prototype.missionTemplateSearchGet);
  useMount(() => {
    searchHook.requestSync({pi: 1, ps: 999});
  });
  return (
    <Radio.Group {...props}>
      {searchHook?.data?.list?.map(i => (
        <Radio key={i.id} value={i.id}>
          {i.name}
        </Radio>
      ))}
    </Radio.Group>
  );
}
