import React, {useEffect} from 'react';
import {Button, Modal, Form, Space, message} from 'antd';
import {useBoolean} from 'react-hanger';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {
  ActionTypeSaveParams,
  PeripheralAbilityApi,
  PeripheralAbilityCommandApi,
  PeripheralAbilityCommandDto,
  PeripheralAbilityDto,
  SorterOrder,
} from '../../scaffold';
import PeripheralAbilityCommandRadio from './Radio';
export type PeripheralAbilityCommandConfigureValueType = Array<{
  name: string;
  commandId: number;
}>;
export function PeripheralAbilityCommandConfigureButton(props: {
  onChange?: (value?: PeripheralAbilityCommandConfigureValueType) => any;
  value?: PeripheralAbilityCommandConfigureValueType;
}) {
  const isShow = useBoolean(false);
  const [form] = Form.useForm();

  const abilitiesHook = useOpenApiFpRequest(PeripheralAbilityApi, PeripheralAbilityApi.prototype.peripheralAbilitySearchGet);
  const commandSearchHook = useOpenApiFpRequest(PeripheralAbilityCommandApi, PeripheralAbilityCommandApi.prototype.peripheralAbilityCommandSearchGet);
  useEffect(() => {
    commandSearchHook.requestSync({pi: 1, ps: 999, sorterKey: 'name', sorterOrder: SorterOrder.Asc});

    abilitiesHook
      .request({
        pi: 1,
        ps: 999,
      })
      .then(r => {
        console.log(r);
      })
      .catch(e => message.error(e.message));
  }, []);
  return (
    <div>
      <Button onClick={() => isShow.setTrue()}>配置外设状态</Button>
      <Modal visible={isShow.value} title="配置外设状态" footer={null} onCancel={isShow.setFalse}>
        <Form
          form={form}
          layout={'vertical'}
          onSubmitCapture={() => {
            console.log(form.getFieldsValue());
          }}>
          {abilitiesHook.data?.list?.map(i => {
            const defaultCommand = commandSearchHook.data?.list?.find(ii => ii.peripheralAbilityId === i.id && ii.isDefault);
            const valueCommand = props.value?.find(ii => ii.name === i.name);
            return (
              <Form.Item
                initialValue={valueCommand?.commandId ?? defaultCommand?.id}
                key={i.id}
                label={i.name}
                name={i.name ?? undefined}
                rules={[{required: true, message: `请选择${i.name}状态`}]}>
                <PeripheralAbilityCommandRadio peripheralAbilityId={i.id} />
              </Form.Item>
            );
          })}
        </Form>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                const value = form.getFieldsValue();
                const res: PeripheralAbilityCommandConfigureValueType = [];
                for (const valueKey in value) {
                  if (value.hasOwnProperty(valueKey)) {
                    res.push({
                      name: valueKey,
                      commandId: value[valueKey],
                    });
                  }
                }
                props.onChange && props.onChange(res);
                isShow.setFalse();
              }}
              style={{width: 120}}>
              确定
            </Button>
            <Button style={{width: 120}} onClick={isShow.setFalse}>
              取消
            </Button>
          </Space>
        </div>
      </Modal>
    </div>
  );
}
