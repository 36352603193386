import React from 'react';
import {Button, ButtonProps, Modal} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {MissionTemplateDto} from '../../scaffold';
import MissionTemplateEditForm from './EditForm';

interface IUpdateInfoBtnProps extends ButtonProps {
  missionTemplate: MissionTemplateDto;
  onSuccess?: (missionTemplate: MissionTemplateDto) => any;
}

export default function MissionTemplateUpdateInfoBtn(props: IUpdateInfoBtnProps) {
  const {missionTemplate, ...btnProps} = props;
  const isDialog = useBoolean(false);

  return (
    <div>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        编辑
      </Button>
      <Modal title={'编辑信息'} visible={isDialog.value} maskClosable={false} closable={false} footer={false} width={'90%'}>
        <MissionTemplateEditForm
          missionTemplate={props.missionTemplate}
          onCancel={isDialog.setFalse}
          onSuccess={res => {
            props.onSuccess && props.onSuccess(res);
            isDialog.setFalse();
          }}
        />
      </Modal>
    </div>
  );
}
