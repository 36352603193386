import React, {useEffect, useState} from 'react';
import {Button, Card, Col, DatePicker, Divider, Radio, Row, Space} from 'antd';
import Title from 'antd/lib/typography/Title';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import LayoutPage from '../../components/Layout/LayoutPage';

import ReactAwesomePlayer from 'react-awesome-player';
import Flex from '../../components/Shared/Flex';
import VideoRecordTable from '../../components/Video/RecordTable';
import {useAntdTable} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import moment from 'moment';
import RobotSelector from '../../components/Robot/Selector';
import Text from 'antd/lib/typography/Text';
import {useRobotId} from '../../hooks/useRobotId';
import {PeripheralType, VideoApi} from '../../scaffold';

export default function VideoIndexPage() {
  const startHook = useOpenApiFpRequest(VideoApi, VideoApi.prototype.videoStartRecordingPost);
  const getUrlsHook = useOpenApiFpRequest(VideoApi, VideoApi.prototype.videoGetLiveHlsUrlsGet);
  const searchHook = useOpenApiFpRequest(VideoApi, VideoApi.prototype.videoSearchGet);
  const [beginTime, setBeginTime] = useState(moment().add(-30, 'd'));
  const [endTime, setEndTime] = useState(moment());
  const [robotId, setRobotId] = useRobotId();
  const [peripheralType, setPeripheralType] = useState(PeripheralType.全景相机);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        beginTime: beginTime.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
        endTime: endTime.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
      }),
    {...DefaultAntdTableConfig, refreshDeps: [beginTime, endTime, robotId]},
  );

  useEffect(() => {
    if (robotId) {
      getUrlsHook.requestSync({robotId, type: peripheralType});
    }
  }, [robotId, peripheralType]);

  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          视频音频
        </Title>
      }>
      <Row gutter={20}>
        <Col span={12}>
          <Card title={'选择摄像机'} className={'m-b-16'}>
            <Space>
              <Text type={'secondary'} strong>
                机器人:
              </Text>
              <RobotSelector value={robotId} onChange={setRobotId} style={{width: 200}} placeholder={'请选择机器人'} />
              <Divider type={'vertical'} />
              <Text type={'secondary'} strong>
                相机:
              </Text>
              <Radio.Group value={peripheralType} onChange={e => setPeripheralType(e.target.value)}>
                <Radio value={PeripheralType.全景相机}>{PeripheralType.全景相机}</Radio>
                <Radio value={PeripheralType.双光谱相机}>{PeripheralType.双光谱相机}</Radio>
                <Radio value={PeripheralType.白光相机}>{PeripheralType.白光相机}</Radio>
                <Radio value={PeripheralType.深度相机}>{PeripheralType.深度相机}</Radio>
              </Radio.Group>
            </Space>
          </Card>
          <Card title={'视频直播'} className={'m-b-16'} loading={getUrlsHook.loading}>
            {!getUrlsHook.data && (
              <Flex justify={'center'} align={'center'} style={{height: 300, background: '#e5e5e5'}}>
                <Text>请选择机器人与相机</Text>
              </Flex>
            )}
            {getUrlsHook.data && (
              <Row gutter={10}>
                {getUrlsHook.data.map(url => (
                  <Col key={url} span={peripheralType === PeripheralType.全景相机 ? 12 : 24} style={{marginBottom: 10}}>
                    <ReactAwesomePlayer
                      key={url}
                      options={{
                        sources: [
                          {
                            src: url,
                          },
                        ],
                      }}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title={'视频录制'} className={'m-b-16'}>
            <Flex justify={'space-between'} className={'m-b-16'}>
              <Space>
                <strong>时间范围:</strong>
                <DatePicker value={beginTime} onChange={setBeginTime as any} />
                <span>至</span>
                <DatePicker value={endTime} onChange={setEndTime as any} />
              </Space>
              <Button type={'primary'} onClick={() => startHook.requestSync({videoRecordStartRecordParams: {id: robotId || 0}})} loading={startHook.loading}>
                开始录制
              </Button>
            </Flex>
            <VideoRecordTable antDTable={antdConfig} />
          </Card>
        </Col>
      </Row>
    </LayoutPage>
  );
}
