import React, {useEffect, useState} from 'react';
import Flex from '../../components/Shared/Flex';
import Title from 'antd/lib/typography/Title';
import LayoutPage from '../../components/Layout/LayoutPage';
import {Card, Form, Image as AntImage, message, Typography} from 'antd';
import RobotSelector from '../../components/Robot/Selector';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {FloorApi, MapApi, MissionStatus, RobotApi} from '../../scaffold';
import {useMount} from 'ahooks';
import {RobotPositionDescription} from '../../components/Robot/PositionDescription';
import {RobotMapTrace} from '../../components/Robot/MapTrace';
import {useRobotId} from '../../hooks/useRobotId';
import {ReloadOutlined} from '@ant-design/icons';
export function OverviewIndexPage() {
  const firstRobotHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFirstGet);
  const robotFindHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFindGet);
  const floorFindHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorFindGet);
  const [currentRobotId, setCurrentRobotId] = useRobotId();
  const [refreshKey, setRefreshKey] = useState<string>(Math.random().toString());

  function refresh() {
    firstRobotHook.setData(undefined);
    robotFindHook.setData(undefined);
    if (currentRobotId) {
      robotFindHook
        .request({
          id: currentRobotId,
        })
        .then(r => {
          r.robotPosition?.floorId &&
            floorFindHook
              .request({
                id: r.robotPosition?.floorId,
              })
              .then(r => {
                const img = new Image();
                img.src = r.map?.url ?? '';
              })
              .catch(e => message.error(e));
        })
        .catch(e => message.error(e));
    }
  }
  useEffect(() => {
    if (currentRobotId) {
      refresh();
    }
  }, [currentRobotId]);
  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <div>
            <Title level={4} style={{marginBottom: 0}}>
              实时位置
            </Title>
            <Typography.Text type={'secondary'}>你可以在此处实时监控机器人位置</Typography.Text>
          </div>
          <div
            style={{marginLeft: 4, cursor: 'pointer'}}
            onClick={() => {
              refresh();
              setRefreshKey(Math.random().toString());
            }}>
            <ReloadOutlined spin={robotFindHook.loading || floorFindHook.loading} style={{color: '#666', width: 16, height: 16}} />
          </div>
          <Form style={{marginLeft: 32, zoom: 0.8}}>
            <Form.Item label={'机器人'}>
              <RobotSelector
                style={{minWidth: 180}}
                value={currentRobotId}
                onChange={setCurrentRobotId}
                placeholder={'请选择一个机器人'}
                allowClear={false}
                defaultActiveFirstOption={true}
              />
            </Form.Item>
          </Form>
          <Flex direction={'row'} style={{marginLeft: 12, zoom: 0.8}}>
            <Flex direction={'row'}>
              <RobotPositionDescription direction={'row'} robot={robotFindHook.data} />
            </Flex>
          </Flex>
        </Flex>
      }>
      <Flex direction="row" style={{padding: 0}}>
        <div>
          {floorFindHook.data?.map?.url && (
            <RobotMapTrace refreshKey={refreshKey} robotId={currentRobotId} height={window.innerHeight} url={floorFindHook.data?.map?.url} />
          )}
        </div>
      </Flex>
    </LayoutPage>
  );
}
