import React from 'react';
import {Divider, Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {Gender, BuildingApi} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {useMount} from 'ahooks';

export default function BuildingSelector(props: SelectProps<string>) {
  const searchHook = useOpenApiFpRequest(BuildingApi, BuildingApi.prototype.buildingSearchGet);
  useMount(() => {
    searchHook.requestSync({pi: 1, ps: 999});
  });
  return (
    <Select {...props} showSearch={true} filterOption={(word, option) => option?.title.includes(word)}>
      {searchHook?.data?.list?.map(i => (
        <Select.Option key={i.id} value={i.id!} title={`${i.name}|${i.remark}`}>
          {i.name}
          <Divider type="vertical" />
          {i.remark}
        </Select.Option>
      ))}
    </Select>
  );
}
