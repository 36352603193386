import React, {useMemo} from 'react';
import {Space} from 'antd';
import logo from '../../assets/logo.png';
import {useLocation} from 'react-use';
import {Route, useHistory} from 'react-router-dom';
import MassaSelectableItem from '../Shared/MassaSelectableItem';
import {useQuery} from '../../hooks/useQuery';
import {
  AiOutlineAlert,
  AiOutlineSchedule,
  AiOutlineSetting,
  AiOutlineVideoCamera,
  FaTasks,
  GoLocation,
  MdPhoneInTalk,
  MdSensors,
  VscDebugConsole,
} from 'react-icons/all';
import {useAuthContext} from 'geestack-toolbox';
import {UserDto} from '../../scaffold';
import {UserRolePermissionType} from '../UserRole/UserRolePermissionType';
import {RobotIndexPage} from '../../pages/robot';
import {RobotFunctionIndexPage} from '../../pages/robotFunction';
import {PeripheralIndexPage} from '../../pages/peripheral';
import {PeripheralAbilityIndexPage} from '../../pages/peripheralAbility';
import {PeripheralAbilityCommandIndexPage} from '../../pages/peripheralAbilityCommand';
import {ActionTypeIndexPage} from '../../pages/action/actionType';
import {BuildingIndexPage} from '../../pages/building';
import {FloorIndexPage} from '../../pages/floor';
import {MapIndexPage} from '../../pages/map';
import {SpotTypeIndexPage} from '../../pages/spotType';
import {SpotIndexPage} from '../../pages/spot';

function LayoutSideBarItem(props: {iconName?: string; icon?: any; title: string; activeItem: string; onClick?: () => any}) {
  return (
    <MassaSelectableItem className={'AppLayoutSideBarItem'} isSelected={props.activeItem.includes(props.title)} onClick={props.onClick}>
      {props.icon ? props.icon : <i className={'massa ' + props.iconName} />}
      <span>{props.title}</span>
    </MassaSelectableItem>
  );
}

function LayoutSideBarSubItem(props: {title: string; subTitle: string; activeItem: string; onClick?: () => any}) {
  if (!props.activeItem.includes(props.title)) return <div />;
  return (
    <MassaSelectableItem
      className={'AppLayoutSideBarItem LayoutSideBarSubItem'}
      style={{background: 'white'}}
      isSelected={props.activeItem.includes(props.title + '-' + props.subTitle)}
      onClick={props.onClick}>
      <span>{props.subTitle}</span>
    </MassaSelectableItem>
  );
}

export default function LayoutSideBar() {
  const location = useLocation();
  const auth = useAuthContext();
  const history = useHistory();
  const query = useQuery();
  function containsPermission(type: UserRolePermissionType) {
    return (auth.currentUser as UserDto).userRole?.userRolePermissions?.includes(UserRolePermissionType[type]);
  }

  const activeItem = useMemo(() => {
    if (location.pathname?.includes('/profile')) return '我的账户';
    if (location.pathname?.includes('/debug')) return '实时日志';
    if (location.pathname?.includes('/audioChat')) return '音频对讲';
    if (location.pathname?.includes('/video')) return '音频视频';
    if (location.pathname?.includes('/alert')) return '异常记录';
    if (location.pathname?.includes('/robotMessage')) return '通信日志';
    if (location.pathname?.includes('/peripheralOverview')) return '外设状态';
    if (location.pathname?.includes('/overview')) return '实时位置';
    if (location.pathname?.includes('/missionTemplate')) return '任务模板';
    if (location.pathname?.includes('/mission')) return '任务计划';

    if (location.pathname?.includes('/basic')) {
      if (location.pathname?.includes('/basic/userRole')) return '基础数据-角色管理';
      if (location.pathname?.includes('/basic/user')) return '基础数据-用户管理';
      if (location.pathname?.includes('/basic/robotFunction')) return '基础数据-机器人服务管理';
      if (location.pathname?.includes('/basic/robot')) return '基础数据-机器人管理';
      if (location.pathname?.includes('/basic/peripheralAbilityCommand')) return '基础数据-外设功能状态';
      if (location.pathname?.includes('/basic/peripheralAbility')) return '基础数据-外设功能';
      if (location.pathname?.includes('/basic/peripheral')) return '基础数据-外设管理';
      if (location.pathname?.includes('/basic/action/type')) return '基础数据-操作类型';
      if (location.pathname?.includes('/basic/action/object')) return '基础数据-操作主体';
      if (location.pathname?.includes('/basic/action/target')) return '基础数据-操作目标';
      return '基础数据';
    }
    if (location.pathname?.includes('/area')) {
      if (location.pathname?.includes('/area/building')) return '地点管理-楼栋管理';
      if (location.pathname?.includes('/area/floor')) return '地点管理-楼层管理';
      if (location.pathname?.includes('/area/map')) return '地点管理-地图管理';
      if (location.pathname?.includes('/area/spotType')) return '地点管理-终点类型管理';
      if (location.pathname?.includes('/area/spot')) return '地点管理-终点管理';
      return '地点管理';
    }
    if (location.pathname?.includes('/')) return '任务计划';
    return '';
  }, [location, query]);

  return (
    <div className={'AppLayoutSideBar'}>
      <Space className={'AppLayoutSideBarLogo'}>
        {/*<img src={logo} alt={'logo'} />*/}
        <div>
          <h2>上海神添</h2>
          <small>机器人自动巡检平台</small>
        </div>
      </Space>
      <div className={'AppLayoutSideBarItems'}>
        <LayoutSideBarItem
          icon={<GoLocation size={20} style={{marginRight: 16}} />}
          title={'实时位置'}
          activeItem={activeItem}
          onClick={() => history.push('/overview')}
        />
        <LayoutSideBarItem
          icon={<AiOutlineSchedule size={20} style={{marginRight: 16}} />}
          title={'任务计划'}
          activeItem={activeItem}
          onClick={() => history.push('/mission')}
        />
        {containsPermission(UserRolePermissionType.外接设备状态查询) && (
          <LayoutSideBarItem
            icon={<MdSensors size={20} style={{marginRight: 16}} />}
            title={'外设状态'}
            activeItem={activeItem}
            onClick={() => history.push('/peripheralOverview')}
          />
        )}

        <LayoutSideBarItem
          icon={<FaTasks size={20} style={{marginRight: 16}} />}
          title={'任务模板'}
          activeItem={activeItem}
          onClick={() => history.push('/missionTemplate')}
        />
        {containsPermission(UserRolePermissionType.音频视频) && (
          <LayoutSideBarItem
            icon={<AiOutlineVideoCamera size={20} style={{marginRight: 16}} />}
            title={'音频视频'}
            activeItem={activeItem}
            onClick={() => history.push('/video')}
          />
        )}
        {containsPermission(UserRolePermissionType.音频对讲) && (
          <LayoutSideBarItem
            icon={<MdPhoneInTalk size={20} style={{marginRight: 16}} />}
            title={'音频对讲'}
            activeItem={activeItem}
            onClick={() => history.push('/audioChat')}
          />
        )}
        {containsPermission(UserRolePermissionType.日志查询) && (
          <>
            <LayoutSideBarItem
              icon={<AiOutlineAlert size={20} style={{marginRight: 16}} />}
              title={'异常记录'}
              activeItem={activeItem}
              onClick={() => history.push('/alert')}
            />
            <LayoutSideBarItem
              icon={<VscDebugConsole size={20} style={{marginRight: 16}} />}
              title={'通信日志'}
              activeItem={activeItem}
              onClick={() => history.push('/robotMessage')}
            />
            <LayoutSideBarItem
              icon={<VscDebugConsole size={20} style={{marginRight: 16}} />}
              title={'实时日志'}
              activeItem={activeItem}
              onClick={() => history.push('/debug')}
            />
          </>
        )}

        <LayoutSideBarItem
          icon={<AiOutlineSetting size={20} style={{marginRight: 16}} />}
          title={'系统菜单'}
          activeItem={activeItem}
          onClick={() => history.push('/basic/user')}
        />
        {containsPermission(UserRolePermissionType.用户管理) && (
          <>
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'用户管理'} activeItem={activeItem} onClick={() => history.push('/basic/user')} />
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'角色管理'} activeItem={activeItem} onClick={() => history.push('/basic/userRole')} />
          </>
        )}
        {containsPermission(UserRolePermissionType.机器人管理) && (
          <>
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'机器人管理'} activeItem={activeItem} onClick={() => history.push('/basic/robot')} />
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'机器人服务管理'} activeItem={activeItem} onClick={() => history.push('/basic/robotFunction')} />
          </>
        )}
        {containsPermission(UserRolePermissionType.外设管理) && (
          <>
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'外设管理'} activeItem={activeItem} onClick={() => history.push('/basic/peripheral')} />
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'外设功能'} activeItem={activeItem} onClick={() => history.push('/basic/peripheralAbility')} />
            <LayoutSideBarSubItem
              title={'基础数据'}
              subTitle={'外设功能状态'}
              activeItem={activeItem}
              onClick={() => history.push('/basic/peripheralAbilityCommand')}
            />
          </>
        )}
        {containsPermission(UserRolePermissionType.任务基础数据管理) && (
          <>
            <LayoutSideBarSubItem title={'基础数据'} subTitle={'操作类型'} activeItem={activeItem} onClick={() => history.push('/basic/action/type')} />
          </>
        )}
        {containsPermission(UserRolePermissionType.地点管理) && (
          <>
            <LayoutSideBarItem iconName={'massa-user'} title={'地点管理'} activeItem={activeItem} onClick={() => history.push('/area/floor')} />
            <LayoutSideBarSubItem title={'地点管理'} subTitle={'楼层管理'} activeItem={activeItem} onClick={() => history.push('/area/floor')} />
            <LayoutSideBarSubItem title={'地点管理'} subTitle={'终点管理'} activeItem={activeItem} onClick={() => history.push('/area/spot')} />
            <LayoutSideBarSubItem title={'地点管理'} subTitle={'终点类型管理'} activeItem={activeItem} onClick={() => history.push('/area/spotType')} />
            <LayoutSideBarSubItem title={'地点管理'} subTitle={'楼栋管理'} activeItem={activeItem} onClick={() => history.push('/area/building')} />
          </>
        )}
      </div>
    </div>
  );
}
