import React, {useEffect} from 'react';
import {Descriptions} from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi} from '../../scaffold';
import {RunningTag} from '../Robot/RunningTag';
export function MissionBasicDescriptions(props: {id?: number; title?: string}) {
  const findHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionFindGet);
  useEffect(() => {
    props.id &&
      findHook.requestSync({
        id: props.id,
      });
  }, [props.id]);
  return (
    <div>
      <Descriptions bordered title={props.title}>
        <DescriptionsItem label="任务 ID">{findHook.data?.id}</DescriptionsItem>
        <DescriptionsItem label="下发任务时间">{findHook.data?.createdTime}</DescriptionsItem>
        <DescriptionsItem label="任务当前状态">{findHook.data?.status}</DescriptionsItem>
        <DescriptionsItem label="最后汇报时间">{findHook.data?.lastReportTime}</DescriptionsItem>
      </Descriptions>
    </div>
  );
}
