import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {PeripheralAbilityApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import PeripheralAbilityTable from '../../components/PeripheralAbility/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import PeripheralAbilityEditForm from '../../components/PeripheralAbility/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function PeripheralAbilityIndexPage() {
  const searchHook = useOpenApiFpRequest(PeripheralAbilityApi, PeripheralAbilityApi.prototype.peripheralAbilitySearchGet);
  const isAddPeripheralAbility = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          外设功能管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddPeripheralAbility.setTrue}>
              录入外设功能
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <PeripheralAbilityTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入外设功能'} visible={isAddPeripheralAbility.value} maskClosable={false} closable={false} footer={false}>
        <PeripheralAbilityEditForm
          onCancel={isAddPeripheralAbility.setFalse}
          onSuccess={res => {
            isAddPeripheralAbility.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
