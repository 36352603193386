import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {RobotApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import RobotTable from '../../components/Robot/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import RobotEditForm from '../../components/Robot/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function RobotIndexPage() {
  const searchHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotSearchGet);
  const isAddRobot = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          机器人管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddRobot.setTrue}>
              录入机器人
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <RobotTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入机器人'} visible={isAddRobot.value} maskClosable={false} closable={false} footer={false}>
        <RobotEditForm
          onCancel={isAddRobot.setFalse}
          onSuccess={res => {
            isAddRobot.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
