import React, {CSSProperties, useMemo} from 'react';
import {MissionDto, MissionStatus} from '../../scaffold';
import {Tag, TagProps} from 'antd';

export function MissionStatusTag(props: {mission?: MissionDto}) {
  const tagProps: TagProps = useMemo(() => {
    switch (props.mission?.status) {
      case MissionStatus.进行中: {
        return {color: '#1890ff'};
      }
      case MissionStatus.任务暂停: {
        return {color: '#fa541c'};
      }
      case MissionStatus.未开始: {
        return {color: '#595959'};
      }
      case MissionStatus.任务异常: {
        return {color: '#cf1322'};
      }
      case MissionStatus.强制结束: {
        return {color: '#d46b08'};
      }
      case MissionStatus.任务完成: {
        return {color: '#389e0d'};
      }
      default: {
        return {};
      }
    }
  }, [props.mission]);
  return <Tag {...tagProps}>{props.mission?.status}</Tag>;
}
