import {useHistory} from 'react-router-dom';

export default function useQuickRouter() {
  const history = useHistory();
  return {
    browserIndex(path: string) {
      return history.push('/browser?path=' + path);
    },
    profileIndex() {
      return history.push('/profile');
    },
    taskIndex(status: 'Working' | 'Done' | 'All') {
      return history.push('/task?status=' + status);
    },
    annotateTaskIndex() {
      return history.push('/annotateTask');
    },
    login() {
      return history.push('/login');
    },
  };
}
