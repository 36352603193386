import {useAuthContext} from 'geestack-toolbox';
import {useHistory} from 'react-router-dom';
import useOpenApi from '../Http/useOpenApi';
import {useMount} from 'react-use';
import {AuthorizeApi, UserDto} from '../scaffold';
import {useOpenApiFpRequest} from '../Http/useOpenApiRequest';
import useAuth from '../utils/AuthContext';

export default function useCheckAuth() {
  const auth = useAuth();
  const history = useHistory();
  const checkRequest = useOpenApiFpRequest(AuthorizeApi, AuthorizeApi.prototype.authorizeHeartbeatGet);

  function logout() {
    auth.logout();
    localStorage.removeItem('Application-Token');
    history.push('/login');
  }

  return {
    logout: logout,
    isChecking: checkRequest.loading,
    check: async () => {
      // wait
      const localToken = localStorage.getItem('Application-Token');
      if (localToken) {
        auth.setToken(localToken);
      }
      if (!localToken) {
        logout();
      } else {
        checkRequest
          .request({body: localToken})
          .then(i => i.user && auth.setCurrentUser(i.user))
          .catch(e => logout());
      }
    },
  };
}
