import React from 'react';
import {Avatar, Button, Divider, Popover, Space, Typography} from 'antd';
import {DashboardOutlined, NotificationTwoTone} from '@ant-design/icons';
import logo from '../../assets/logo-v.png';
import useAuth from '../../utils/AuthContext';
import {AuthContext, useAuthContext} from 'geestack-toolbox';
import UserAvatar from '../User/UserAvatar';
import MassaSelectableItem from '../Shared/MassaSelectableItem';
import useQuickRouter from '../../hooks/useQuickRouter';
import useCheckAuth from '../../hooks/useCheckAuth';
import Flex from '../Shared/Flex';
export default function LayoutHeader(props: {children?: any}) {
  const auth = useAuth();
  const authCheck = useCheckAuth();
  const quickRouter = useQuickRouter();
  return (
    <div className={'AppLayoutHeader'}>
      <div className={'AppLayoutHeaderMain'}>{props.children}</div>
      <div style={{flexShrink: 0}}>
        {auth.currentUser && (
          <Popover
            content={
              <div>
                <MassaSelectableItem onClick={quickRouter.profileIndex}>个人中心</MassaSelectableItem>
                <MassaSelectableItem onClick={authCheck.logout}>注销</MassaSelectableItem>
              </div>
            }>
            <Flex align={'center'}>
              <UserAvatar size={42} style={{marginLeft: 20, cursor: 'pointer'}} user={auth.currentUser} />
              <Typography.Text style={{marginLeft: 8, fontSize: 16}}>{auth.currentUser.name}</Typography.Text>
            </Flex>
          </Popover>
        )}
      </div>
    </div>
  );
}
