import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Descriptions, Input, List, Modal, Row, Skeleton, Space, Table} from 'antd';
import Title from 'antd/lib/typography/Title';
import {UserApi} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import LayoutPage from '../../components/Layout/LayoutPage';
import useAuth from '../../utils/AuthContext';

export default function ProfileIndexPage() {
  const queryByIdHook = useOpenApiFpRequest(UserApi, UserApi.prototype.userFindGet);
  const user = queryByIdHook.data;
  const auth = useAuth();

  function refresh() {
    if (auth.currentUser) {
      queryByIdHook
        .request({id: Number(auth.currentUser.id)})
        .then()
        .catch(CommonApiErrorHandler);
    }
  }

  useEffect(() => {
    refresh();
  }, [auth.currentUser]);
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          个人中心
        </Title>
      }>
      <Row gutter={20}>
        <Col span={6}>
          <Card title={'我的账户'} className={'m-b-16'}>
            <Skeleton loading={queryByIdHook.loading}>
              {user && user.id && (
                <div>
                  <Descriptions column={1} className={'m-b-12'}>
                    <Descriptions.Item label={'姓名'}>{user.name}</Descriptions.Item>
                    <Descriptions.Item label={'用户名'}>{user.username}</Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </LayoutPage>
  );
}
