import React, {useEffect, useState} from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Divider, Form, message, Modal, Progress, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {FloorApi, PeripheralApi, PeripheralType, RobotApi, RobotFunctionApi} from '../../scaffold';
import {useAntdTable, useMount} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import PeripheralTable from '../../components/Peripheral/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import PeripheralEditForm from '../../components/Peripheral/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {AiOutlineFieldTime, RiDownloadCloud2Line} from 'react-icons/all';
import RobotSelector from '../../components/Robot/Selector';
import {RobotPositionDescription} from '../../components/Robot/PositionDescription';
import {RunningTag} from '../../components/Robot/RunningTag';
import RobotImg from '../../assets/robot-2.png';
import {PeripheralRunningTag} from '../../components/Peripheral/RunningTag';
import {RobotMapTrace} from '../../components/Robot/MapTrace';
import {RobotFunctionRunningTag} from '../../components/RobotFunction/RunningTag';
import {PeripheralIcon} from '../../components/Peripheral/Icon';
import {RobotBattery} from '../../components/Robot/RobotBattery';
import {useRobotId} from '../../hooks/useRobotId';
import {ReloadOutlined} from '@ant-design/icons';
export function PeripheralOverviewPage() {
  const peripheralListHook = useOpenApiFpRequest(PeripheralApi, PeripheralApi.prototype.peripheralListByRobotGet);
  const functionListHook = useOpenApiFpRequest(RobotFunctionApi, RobotFunctionApi.prototype.robotFunctionListByRobotGet);
  const isAddPeripheral = useBoolean(false);
  const [currentRobotId, setCurrentRobotId] = useRobotId();

  const firstRobotHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFirstGet);
  const robotFindHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFindGet);
  const floorFindHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorFindGet);

  function refresh() {
    firstRobotHook.setData(undefined);
    robotFindHook.setData(undefined);
    if (currentRobotId) {
      peripheralListHook.requestSync({
        robotId: currentRobotId,
      });
      functionListHook.requestSync({
        robotId: currentRobotId,
      });
      robotFindHook
        .request({
          id: currentRobotId,
        })
        .then(r => {
          r.robotPosition?.floorId &&
            floorFindHook
              .request({
                id: r.robotPosition?.floorId,
              })
              .then(r => {
                const img = new Image();
                img.src = r.map?.url ?? '';
              })
              .catch(e => message.error(e));
        })
        .catch(e => message.error(e));
    }
  }
  useMount(() => {
    // firstRobotHook
    //   .request({})
    //   .then(r => {
    //     setCurrentRobotId(r.id);
    //   })
    //   .catch(e => message.error(e));
  });
  useEffect(() => {
    if (currentRobotId) {
      refresh();
    }
  }, [currentRobotId]);
  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <div>
            <Title level={4} style={{marginBottom: 0}}>
              外设状态
            </Title>
            <Typography.Text type={'secondary'}>你可以在此处实时监控机器人的所有外设</Typography.Text>
          </div>
          <div
            style={{marginLeft: 4, cursor: 'pointer'}}
            onClick={() => {
              refresh();
            }}>
            <ReloadOutlined
              spin={robotFindHook.loading || floorFindHook.loading || peripheralListHook.loading}
              style={{color: '#666', width: 16, height: 16}}
            />
          </div>
          <Form style={{marginLeft: 32}}>
            <Form.Item label={'机器人'}>
              <RobotSelector
                style={{minWidth: 300}}
                value={currentRobotId}
                onChange={setCurrentRobotId}
                placeholder={'请选择一个机器人'}
                allowClear={false}
                defaultActiveFirstOption={true}
              />
            </Form.Item>
          </Form>
        </Flex>
      }>
      <Row gutter={32} style={{maxWidth: 1000, margin: 'auto'}}>
        <Col span={12}>
          <Card style={{boxShadow: '0 8px 16px -12px rgb(27 58 146 / 16%)'}}>
            <Flex align={'center'}>
              <img src={RobotImg} style={{width: 100}} alt={'robot'} />
              <Flex direction={'column'} style={{marginLeft: 32}}>
                <Typography.Text style={{fontSize: 22}}>{robotFindHook.data?.name}</Typography.Text>
                <RunningTag robot={robotFindHook.data} />
                <RobotPositionDescription robot={robotFindHook.data} />
              </Flex>
            </Flex>
          </Card>
          <Typography.Title style={{marginTop: 32}} level={4}>
            本体信息
          </Typography.Title>
          <Card style={{boxShadow: '0 8px 16px -12px rgb(27 58 146 / 16%)'}} hoverable>
            <div>
              <Typography.Title level={5}>电池电量</Typography.Title>
              <RobotBattery robot={robotFindHook.data} />
            </div>
          </Card>
          <Typography.Title style={{marginTop: 32}} level={4}>
            服务状态
          </Typography.Title>
          <Card style={{boxShadow: '0 8px 16px -12px rgb(27 58 146 / 16%)'}} hoverable>
            {functionListHook.data?.map(i => (
              <Descriptions size={'small'} title={i.name} bordered key={i.id} className={'m-b-12'}>
                <Descriptions.Item label={'状态'}>
                  <RobotFunctionRunningTag robotFunction={i} />
                </Descriptions.Item>
              </Descriptions>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Typography.Title style={{marginTop: 0}} level={4}>
            外设信息
          </Typography.Title>
          <Card style={{boxShadow: '0 8px 16px -12px rgb(27 58 146 / 16%)'}} hoverable>
            <Skeleton loading={peripheralListHook.loading} />
            <Skeleton loading={peripheralListHook.loading} />
            <Skeleton loading={peripheralListHook.loading}>
              <div>
                <div>
                  {peripheralListHook.data?.map(i => (
                    <Descriptions
                      size={'small'}
                      title={
                        <Flex align={'center'}>
                          <PeripheralIcon size={20} peripheral={i} />
                          <span style={{marginLeft: 8}}>{i.name}</span>
                        </Flex>
                      }
                      bordered
                      column={1}
                      key={i.id}
                      className={'m-b-12'}>
                      <Descriptions.Item label={'品牌/型号'}>
                        {i.brand}/{i.model}
                      </Descriptions.Item>
                      <Descriptions.Item label={'类型'}>{i.type}</Descriptions.Item>
                      <Descriptions.Item label={'状态'}>
                        <PeripheralRunningTag peripheral={i} />
                      </Descriptions.Item>
                    </Descriptions>
                  ))}
                </div>
              </div>
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </LayoutPage>
  );
}
