import React, {useEffect, useState} from 'react';
import {Gender, RobotPositionHistoryDto, PositionApi, MissionApi} from '../../scaffold';
import {Avatar, Button, DatePicker, Drawer, Form, Image, message, Modal, Space, Table, TableProps, Tag, Typography} from 'antd';
import {Result} from 'ahooks/lib/useAntdTable';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import Flex from '../Shared/Flex';

export default function RobotPositionHistoryTable(props: {antDTable: Result<RobotPositionHistoryDto>; tableProps?: TableProps<any>; onSuccess?: () => any}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <>
      <Table<RobotPositionHistoryDto> {...tableProps} {...props.tableProps} rowKey={'id'} size={'small'} bordered>
        <Table.Column<RobotPositionHistoryDto> title="机器人" dataIndex={['robot', 'name']} />
        <Table.Column<RobotPositionHistoryDto>
          title="坐标"
          render={(txt, row) => (
            <Typography.Text strong>
              ({row.x}, {row.y}, {row.z})
            </Typography.Text>
          )}
        />
        <Table.Column<RobotPositionHistoryDto> title="楼层" dataIndex={['floor', 'name']} />
        <Table.Column<RobotPositionHistoryDto> title="楼栋" dataIndex={['floor', 'building', 'name']} />
        <Table.Column<RobotPositionHistoryDto> title="地点" dataIndex={'s'} />

        <Table.Column<RobotPositionHistoryDto> title="触发时间" dataIndex={'createdTime'} />
        <Table.Column<RobotPositionHistoryDto>
          title="操作"
          width={80}
          render={(text, row) => {
            return <Space></Space>;
          }}
        />
      </Table>
    </>
  );
}
