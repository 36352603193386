import {useEffect, useRef, useState} from 'react';
import {useBoolean, useNumber} from 'react-hanger';
import {useMount, useUpdate} from 'ahooks';

export function useMediaRecorder() {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const isCancel = useBoolean(false);
  let stream: MediaStream | undefined = undefined;

  const volume = useNumber(0);
  let chunks: Blob[] = [];

  async function requestPermission(finishCallback: (blob: Blob, audioURL: string) => any) {
    stream = await navigator.mediaDevices.getUserMedia({audio: true});
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;
    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    console.log('授权成功！');
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.onstart = () => {
      chunks = [];
    };
    mediaRecorder.onstop = function(event) {
      const blob = new Blob(chunks, {type: 'audio/ogg; codecs=opus'});
      const audioURL = window.URL.createObjectURL(blob);
      finishCallback && finishCallback(blob, audioURL);
    };
    mediaRecorder.ondataavailable = e => {
      chunks.push(e.data);
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;
      const length = array.length;
      for (let i = 0; i < length; i++) {
        values += array[i];
      }
      const average = values / length;
      volume.setValue(average);
    };
    setMediaRecorder(mediaRecorder);
  }
  function start(timeSlice: number) {
    mediaRecorder?.start(timeSlice);
  }
  function stop() {
    mediaRecorder?.stop();
  }
  useEffect(() => {
    return () => {
      setMediaRecorder(undefined);
      stream?.getAudioTracks().forEach(track => {
        console.log(track);
        track.stop();
      });
    };
  }, []);
  return {
    volume,
    requestPermission,
    start,
    state: mediaRecorder?.state,
    stop,
    chunks,
  };
}
