import React, {useEffect, useMemo, useState} from 'react';
import {Layer, Stage, Image as KonvaImage, Circle, Group, Label, Text, Tag, Line} from 'react-konva';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {FloorApi, MapApi, PositionApi, RobotApi} from '../../scaffold';
import LocateImage from '../../assets/locate.png';
import {useNumber} from 'react-hanger';
import {useInterval} from 'ahooks';
import {ShiningMapDot} from './ShiningMapDot';
import {Card, message, Slider} from 'antd';

export interface IMapPointerValue {
  x: number;
  y: number;
}

export function RobotMapTraceHistory(props: {positionKey?: string; height?: number; onChange?: (v: IMapPointerValue) => any}) {
  const listHook = useOpenApiFpRequest(PositionApi, PositionApi.prototype.positionListByKeyGet);
  const [scale, setScale] = useState<number>(0.5);
  const [layerOffsetX, setLayerOffsetX] = useState<number>(0);
  const [layerOffsetY, setLayerOffsetY] = useState<number>(0);

  async function refresh() {
    try {
      props.positionKey &&
        listHook.requestSync({
          key: props.positionKey,
        });
    } catch (e) {
      message.error(e);
    }
  }

  useEffect(() => {
    refresh();
  }, [props.positionKey]);

  const image = useMemo(() => {
    const img = new Image();
    img.src = listHook.data?.map?.url ?? '';
    return img;
  }, [listHook.data]);

  const points = useMemo(() => {
    const res: number[] = [];
    for (const item of listHook.data?.list ?? []) {
      if (item.x && item.y) {
        res.push(item.x * scale);
        res.push(item.y * scale);
      }
    }
    return res;
  }, [listHook.data, scale]);
  const lastPosition = useMemo(() => {
    const list = listHook.data?.list ?? [];
    return list.length > 0 ? list[list.length - 1] : undefined;
  }, [listHook.data]);
  const center = useMemo(() => {
    const list = listHook.data?.list ?? [];
    let sumX = 0;
    let sumY = 0;
    for (const item of list) {
      sumX += item.x ?? 0;
      sumY += item.y ?? 0;
    }
    return {
      x: sumX / (list.length == 0 ? 1 : list.length),
      y: sumY / (list.length == 0 ? 1 : list.length),
    };
  }, [listHook.data]);
  const variance = useMemo(() => {
    const list = listHook.data?.list ?? [];
    let sumX = 0;
    let sumY = 0;
    for (const item of list) {
      sumX += Math.pow((item.x ?? 0) - center.x, 2);
      sumY += Math.pow((item.y ?? 0) - center.y, 2);
    }
    return {
      x: Math.sqrt(sumX / (list.length == 0 ? 1 : list.length)),
      y: Math.sqrt(sumY / (list.length == 0 ? 1 : list.length)),
    };
  }, [listHook.data, center]);

  return (
    <>
      <Stage
        width={window.innerWidth}
        height={props.height ?? 400}
        style={{overflow: 'auto', background: 'rgba(0,0,0,0)', padding: 0}}
        onWheel={e => {
          if (e.evt.altKey) {
            if (e.evt.deltaY > 0) {
              console.log('down');
              setScale(scale * 0.92);
            }
            if (e.evt.deltaY < 0) {
              console.log('up');
              setScale(scale * 1.08);
            }
          }
        }}>
        <Layer>
          <Group
            draggable
            x={scale + (image.width * scale) / 4}
            y={scale + (image.height * scale) / 4}
            onDragEnd={e => {
              setLayerOffsetX(e.target.x());
              setLayerOffsetY(e.target.y());
            }}>
            <KonvaImage scaleX={scale} scaleY={scale} image={image} />
            <Line shadowBlur={8} shadowColor={'rgb(8,151,156)'} points={points} stroke="rgb(8,151,156)" lineCap={'round'} lineJoin={'round'} strokeWidth={4} />
            {variance.x <= 30 && variance.y <= 30 && lastPosition?.y && lastPosition?.x && (
              <ShiningMapDot color={'rgb(8,151,156)'} x={lastPosition?.x} y={lastPosition?.y} scale={scale} />
            )}
          </Group>
        </Layer>
      </Stage>
      <Card hoverable style={{position: 'absolute', top: 32, left: 32}} bodyStyle={{padding: 4}}>
        <Slider style={{height: 400, color: 'rgb(8,151,156)'}} vertical defaultValue={scale * 100} onChange={(v: number) => setScale(v / 100)} />
      </Card>
    </>
  );
}
