import Flex from '../Shared/Flex';
import {Typography} from 'antd';
import React, {useEffect, useMemo} from 'react';
import {RobotDto, RobotSocketApi, RobotStatus} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';

export function RunningTag(props: {robot?: RobotDto}) {
  const listClientHook = useOpenApiFpRequest(RobotSocketApi, RobotSocketApi.prototype.robotSocketListClientsGet);
  useEffect(() => {
    props.robot?.id && listClientHook.requestSync({});
  }, [props.robot]);
  const isOnline = useMemo(() => {
    return !!listClientHook.data?.find(i => i === props.robot?.id);
  }, [listClientHook.data, props.robot?.id]);

  const color = useMemo(() => {
    if (isOnline) {
      return '#389e0d';
    } else {
      return '#faad14';
    }
  }, [listClientHook.data, props.robot?.id, props.robot?.robotStatus]);
  return (
    <Flex direction={'row'} align={'center'}>
      <div style={{width: 12, height: 12, background: color, borderRadius: '50%'}} />
      <Typography.Text style={{color: color, marginLeft: 8}}>{isOnline ? '在线' : '离线'}</Typography.Text>
    </Flex>
  );
}
