import Flex from '../Shared/Flex';
import {Typography} from 'antd';
import React, {useMemo} from 'react';
import {PeripheralDto, RobotDto, RobotStatus} from '../../scaffold';

export function PeripheralRunningTag(props: {peripheral?: PeripheralDto}) {
  const color = useMemo(() => {
    if (props.peripheral?.status === '正常') {
      return '#389e0d';
    } else {
      return '#faad14';
    }
  }, [props.peripheral?.status]);
  return (
    <Flex direction={'row'} align={'center'}>
      <div style={{width: 12, height: 12, background: color, borderRadius: '50%'}} />
      <Typography.Text style={{color: color, marginLeft: 8}}>{props.peripheral?.status ?? '暂无状态'}</Typography.Text>
    </Flex>
  );
}
