import React, {useEffect, useMemo} from 'react';
import {Button, Col, Descriptions, Divider, Form, Input, InputNumber, message, notification, Radio, Row, Space, Switch, Tag, Typography} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {
  Gender,
  MissionTemplateApi,
  MissionTemplateBasicParams,
  MissionTemplateDto,
  MissionTemplateStepParams,
  PeripheralAbilityCommandApi,
  PeripheralAbilityCommandDto,
} from '../../scaffold';
import RobotSelector from '../Robot/Selector';
import BuildingSelector from '../Building/Selector';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import ActionTypeSelector from '../ActionType/Selector';
import Flex from '../Shared/Flex';
import SpotCascader, {SpotCascaderDisplayRender} from '../Spot/Cascader';
import {PeripheralAbilityCommandConfigureButton, PeripheralAbilityCommandConfigureValueType} from '../PeripheralAbilityCommand/Configure';

interface IStepsForm {
  steps: MissionTemplateStepParams[];
}

const MissionTemplateEditForm = (props: {onSuccess?: (res: MissionTemplateDto) => any; missionTemplate?: MissionTemplateDto; onCancel?: () => any}) => {
  const [templateForm] = Form.useForm<MissionTemplateBasicParams>();
  const commandsHook = useOpenApiFpRequest(PeripheralAbilityCommandApi, PeripheralAbilityCommandApi.prototype.peripheralAbilityCommandSearchGet);
  const [stepsForm] = Form.useForm<IStepsForm>();
  const updateHook = useOpenApiFpRequest(MissionTemplateApi, MissionTemplateApi.prototype.missionTemplateSavePost);
  const getDefaultHook = useOpenApiFpRequest(MissionTemplateApi, MissionTemplateApi.prototype.missionTemplateGetParametersGet);

  useEffect(() => {
    props.missionTemplate?.id &&
      getDefaultHook
        .request({
          id: props.missionTemplate?.id,
        })
        .then(r => {
          console.log(r);
          templateForm.setFieldsValue(r.basicForm);
          stepsForm.setFieldsValue({
            steps: r.steps,
          });
        })
        .catch(e => {
          message.error(e);
        });
  }, [props.missionTemplate]);

  useEffect(() => {
    commandsHook.requestSync({
      pi: 1,
      ps: 999,
    });
  }, []);
  const commandMap = useMemo(() => {
    const map = new Map<number, PeripheralAbilityCommandDto>();
    commandsHook.data?.list?.forEach(i => i.id && map.set(i.id, i));
    return map;
  }, [commandsHook.data]);

  return (
    <div>
      <Form<MissionTemplateBasicParams> layout={'inline'} form={templateForm}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="任务模板名称" name="name" rules={[{required: true, message: '请输入任务模板名称'}]}>
          <Input placeholder="请输入名称" />
        </Form.Item>
        <Form.Item label="机器人" name="robotId" rules={[{required: true, message: '请选择机器人'}]}>
          <RobotSelector placeholder="请选择机器人" style={{minWidth: 200}} />
        </Form.Item>
        <Form.Item label="楼栋" name="buildingId" rules={[{required: true, message: '请选择楼栋'}]}>
          <BuildingSelector placeholder="请选择楼栋" style={{minWidth: 200}} />
        </Form.Item>
        <Form.Item label="完成后是否回桩" name="autoAppendRecharge" valuePropName="checked" rules={[{required: true, message: '请选择完成后是否回桩'}]}>
          <Switch />
        </Form.Item>
        <Form.Item label="重复执行" name="isRepeat" valuePropName="checked" rules={[{required: true, message: '请选择是否重复执行'}]}>
          <Switch />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() =>
            templateForm.getFieldValue('isRepeat') && (
              <Form.Item label="重复执行次数" name="repeatCount" rules={[{required: true, message: '请输入重复执行次数'}]}>
                <InputNumber min={1} />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
      <Divider>指令序列</Divider>
      <Form<IStepsForm> form={stepsForm} layout={'vertical'}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.List name="steps">
          {(fields, {add, remove, move}) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                <Flex key={key} align={'center'} style={{marginBottom: 8}}>
                  <Form.Item
                    label="动作名称"
                    {...restField}
                    name={[name, 'actionName']}
                    rules={[{required: true, message: '请输入动作名称'}]}
                    style={{marginRight: 8, minWidth: 200}}>
                    <Input placeholder="请输入动作名称" />
                  </Form.Item>
                  <Form.Item
                    label="动作属性"
                    {...restField}
                    name={[name, 'actionTypeId']}
                    rules={[{required: true, message: '请选择动作属性'}]}
                    style={{marginRight: 8}}>
                    <ActionTypeSelector style={{minWidth: 120}} />
                  </Form.Item>
                  <Form.Item
                    label="终点"
                    {...restField}
                    name={[name, 'spotIdList']}
                    rules={[{required: false, message: '请选择终点'}]}
                    style={{marginRight: 8}}>
                    <SpotCascader expandTrigger="hover" placeholder={'请选择终点'} style={{minWidth: 400}} />
                  </Form.Item>
                  <Form.Item
                    label="外设状态"
                    {...restField}
                    name={[name, 'peripherals']}
                    rules={[{required: false, message: '点击配置外设状态'}]}
                    style={{marginRight: 8}}>
                    <PeripheralAbilityCommandConfigureButton />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item label={' '}>
                        {stepsForm.getFieldValue('steps')[key]?.peripherals?.map((i: {name: string; commandId: number}) => {
                          return (
                            <div style={{display: 'inline-block'}} key={i.commandId}>
                              <Typography.Text style={{margin: 4}}>
                                <Typography.Text strong>{i.name}</Typography.Text> : <Tag>{commandMap.get(i.commandId)?.name}</Tag>
                              </Typography.Text>
                              <Divider type={'vertical'} />
                            </div>
                          );
                        })}
                      </Form.Item>
                    )}
                  </Form.Item>

                  <Form.Item label={' '} style={{display: 'flex', alignItems: 'center'}}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                </Flex>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  添加子任务
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>

      <div className={'text-right m-t-16'}>
        <Space>
          <Button
            type="primary"
            htmlType={'submit'}
            onClick={async () => {
              try {
                await templateForm.validateFields();
                await stepsForm.validateFields();
              } catch (e) {
                return;
              }
              try {
                console.log(templateForm.getFieldsValue());
                console.log(stepsForm.getFieldsValue());
                const r = await updateHook.request({
                  missionTemplateSaveParams: {
                    id: props.missionTemplate?.id,
                    basicForm: templateForm.getFieldsValue(),
                    steps: stepsForm.getFieldsValue().steps,
                  },
                });
                props.onSuccess && props.onSuccess(r);
                notification.success({
                  message: '新建任务模板',
                  description: `您已经成功${props.missionTemplate?.name ? '编辑' : '新建'}任务模板: ${templateForm.getFieldsValue().name}`,
                });
              } catch (e) {
                message.error(e.message);
              }
            }}
            style={{width: 120}}
            loading={updateHook.loading}
            disabled={updateHook.loading}>
            保存
          </Button>
          <Button style={{width: 120}} onClick={props.onCancel}>
            取消
          </Button>
        </Space>
      </div>
    </div>
  );
};
export default MissionTemplateEditForm;
