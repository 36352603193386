import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch, TimePicker} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, MissionScheduleApi, MissionScheduleApiFactory, MissionScheduleDto, MissionScheduleSaveParams} from '../../scaffold';
import RobotSelector from '../Robot/Selector';
import MissionTemplateRadio from '../MissionTemplate/Radio';
import {DayOfWeeksEditor} from './DayOfWeeksEditor';
import moment from 'moment';

const MissionScheduleEditForm = (props: {onSuccess?: (res: MissionScheduleDto) => any; item?: MissionScheduleDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<MissionScheduleSaveParams>();
  const updateHook = useOpenApiFpRequest(MissionScheduleApi, MissionScheduleApi.prototype.missionScheduleSavePost);
  useEffect(() => {
    form.setFieldsValue({
      dayOfWeeks: props.item?.dayOfWeeks ?? [],
      id: props.item?.id,
      robotId: props.item?.robotId,
      missionTemplateId: props.item?.missionTemplateId,
      remark: props.item?.remark,
      time: props.item?.time ?? undefined,
    });
  }, [props.item]);
  function onSubmit(data: MissionScheduleSaveParams) {
    updateHook
      .request({
        missionScheduleSaveParams: {
          ...data,
        },
      })
      .then(res => {
        message.success('操作成功');
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<MissionScheduleSaveParams> form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="选择机器人" name="robotId" rules={[{required: true, message: '请选择要定时执行的机器人'}]}>
          <RobotSelector />
        </Form.Item>
        <Form.Item label="任务模板" name="missionTemplateId" rules={[{required: true, message: '请选择一个任务模板'}]}>
          <MissionTemplateRadio />
        </Form.Item>
        <Form.Item label="重复方式" name="dayOfWeeks" rules={[{required: true, message: '至少选择一天以重复'}]}>
          <DayOfWeeksEditor />
        </Form.Item>
        <Form.Item label="触发时间" name="time" rules={[{required: true, message: '请输入触发时间'}]}>
          <TimePicker format={'HH:mm'} />
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default MissionScheduleEditForm;
