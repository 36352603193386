import React from 'react';
import {Typography} from 'antd';
import Flex from '../Shared/Flex';
import {RobotDto} from '../../scaffold';
export function RobotPositionDescription(props: {robot?: RobotDto; direction?: 'column' | 'row'}) {
  return (
    <Flex direction={props.direction ?? 'column'} align={props.direction === 'row' ? 'center' : undefined}>
      <Typography.Text type={'secondary'}>最近汇报时间：</Typography.Text>
      <Typography.Text strong style={{fontSize: 18, marginRight: 8}}>
        {props.robot?.robotPosition?.createdTime}
      </Typography.Text>
      <Typography.Text type={'secondary'}>当前坐标：</Typography.Text>
      <Typography.Text strong style={{fontSize: 18, marginRight: 8}}>
        ({props.robot?.robotPosition?.x},{props.robot?.robotPosition?.y})
      </Typography.Text>
      <Typography.Text type={'secondary'}>当前楼层：</Typography.Text>
      <Typography.Text strong style={{fontSize: 18, marginRight: 8}}>
        {props.robot?.robotPosition?.floor?.name}
      </Typography.Text>
      <Typography.Text type={'secondary'}>当前位置：</Typography.Text>
      <Typography.Text strong style={{fontSize: 18, marginRight: 8}}>
        {props.robot?.robotPosition?.s} 附近
      </Typography.Text>
    </Flex>
  );
}
