import React, {useEffect, useMemo, useState} from 'react';
import {Layer, Stage, Image as KonvaImage, Circle, Group, Label, Text, Tag, Line} from 'react-konva';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {FloorApi, MapApi, PositionApi, RobotApi} from '../../scaffold';
import LocateImage from '../../assets/locate.png';
import {useNumber} from 'react-hanger';
import {useInterval} from 'ahooks';
import {ShiningMapDot} from './ShiningMapDot';
import {Card, message, Slider} from 'antd';

export interface IMapPointerValue {
  x: number;
  y: number;
}

export function RobotMapTrace(props: {url?: string; refreshKey?: string; robotId?: number; height?: number; onChange?: (v: IMapPointerValue) => any}) {
  const robotFind = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFindGet);
  const floorFind = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorFindGet);
  const positionHook = useOpenApiFpRequest(MapApi, MapApi.prototype.mapCalculatePositionPost);
  const historyHook = useOpenApiFpRequest(PositionApi, PositionApi.prototype.positionSearchHistoryByFloorGet);
  const [scale, setScale] = useState<number>(1);
  const [layerOffsetX, setLayerOffsetX] = useState<number>(0);
  const [layerOffsetY, setLayerOffsetY] = useState<number>(0);

  async function refresh() {
    try {
      if (!props.robotId) return;
      const robot = await robotFind.request({
        id: props.robotId,
      });
      if (!robot.robotPosition?.floorId) return;
      const floor = await floorFind.request({
        id: robot.robotPosition.floorId,
      });
      floor.id &&
        historyHook.requestSync({
          floorId: floor.id,
          robotId: props.robotId,
        });
      await positionHook.request({
        mapCalculatePositionParams: {
          id: floor.map?.id ?? 0,
          x: robot.robotPosition.x ?? 0,
          y: robot.robotPosition.y ?? 0,
        },
      });
    } catch (e) {
      message.error(e);
    }
  }

  useEffect(() => {
    refresh();
  }, [props.robotId, props.refreshKey]);

  useInterval(() => {
    refresh();
  }, 10 * 1000);

  const image = useMemo(() => {
    const img = new Image();
    img.src = floorFind.data?.map?.url ?? '';
    return img;
  }, [floorFind.data]);
  const points = useMemo(() => {
    const res: number[] = [];
    for (const item of historyHook.data ?? []) {
      if (item.x && item.y) {
        res.push(item.x * scale);
        res.push(item.y * scale);
      }
    }
    console.log(res);
    return res;
  }, [historyHook.data, scale]);

  const position = useMemo(() => {
    return {
      x: positionHook.data?.x,
      y: positionHook.data?.y,
    };
  }, [positionHook.data]);

  const center = useMemo(() => {
    if (floorFind.data?.map?.isTranspose) {
      return {
        x: -(position?.x ?? 0) * scale + 200,
        y: -(position?.y ?? 0) * scale - 200,
      };
    } else {
      return {
        x: -(position?.x ?? 0) * scale + window.innerWidth / 2,
        y: -(position?.y ?? 0) * scale + (props.height ?? 400) / 2,
      };
    }
  }, [position, floorFind.data]);
  return (
    <>
      <Stage
        width={window.innerWidth}
        height={props.height ?? 400}
        rotation={floorFind.data?.map?.isTranspose ? 90 : 0}
        style={{overflow: 'auto', background: 'rgba(0,0,0,0)', padding: 0}}
        onWheel={e => {
          if (e.evt.altKey) {
            if (e.evt.deltaY > 0) {
              console.log('down');
              setScale(scale * 0.92);
            }
            if (e.evt.deltaY < 0) {
              console.log('up');
              setScale(scale * 1.08);
            }
          }
        }}>
        <Layer>
          <Group
            draggable
            x={center.x}
            y={center.y}
            onDragEnd={e => {
              console.log(e.target.x);
              setLayerOffsetX(e.target.x());
              setLayerOffsetY(e.target.y());
            }}>
            <KonvaImage scaleX={scale} scaleY={scale} image={image} />
            <Line shadowBlur={8} shadowColor={'rgb(7,120,235)'} points={points} stroke="rgb(7,120,235)" lineCap={'round'} lineJoin={'round'} strokeWidth={4} />
            {position?.y && position?.x && <ShiningMapDot x={position?.x} y={position?.y} scale={scale} />}
          </Group>
        </Layer>
      </Stage>
      <Card hoverable style={{position: 'absolute', top: 32, left: 32}} bodyStyle={{padding: 4}}>
        <Slider style={{height: 400, color: 'rgb(7,120,235)'}} vertical defaultValue={scale * 100} onChange={(v: number) => setScale(v / 100)} />
      </Card>
    </>
  );
}
