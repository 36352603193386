import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, MissionTemplateActionApi, MissionTemplateActionDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Divider, message, Space, Table, Tooltip, Typography} from 'antd';
import MissionTemplateActionUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';

export default function MissionTemplateActionTable(props: {id?: number}) {
  const actionSearchHook = useOpenApiFpRequest(MissionTemplateActionApi, MissionTemplateActionApi.prototype.missionTemplateActionListByTemplateGet);
  const {tableProps, search, loading} = useAntdTable(
    async params => {
      if (!props.id) {
        return {
          list: [],
          total: 0,
        };
      }
      const list = await actionSearchHook.request({
        missionTemplateId: props.id,
        includeCommands: true,
      });
      return {
        list,
        total: list.length,
      };
    },
    {...DefaultAntdTableConfig, onSuccess: () => console.log('获取成功')},
  );
  return (
    <Table<MissionTemplateActionDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<MissionTemplateActionDto> title="名称" dataIndex={'actionName'} width={100} />
      <Table.Column<MissionTemplateActionDto> title="类型" dataIndex={['actionType', 'name']} width={80} />
      <Table.Column<MissionTemplateActionDto>
        width={200}
        title="子任务终点"
        dataIndex={['spot', 'name']}
        render={(txt, row) => {
          return `${row.spot?.floor?.building?.name}/${row.spot?.floor?.name}/${row.spot?.name}`;
        }}
      />
      <Table.Column<MissionTemplateActionDto>
        title="功能开启状态"
        width={500}
        render={(txt, row) => {
          const list = row.missionTemplateActionToPeripheralCommands?.map(
            i => `${i.peripheralAbilityCommand?.peripheralAbility?.name} - ${i.peripheralAbilityCommand?.name}`,
          );
          return (
            <Tooltip title={list?.join(' / ')}>
              <Typography.Paragraph style={{width: 500, margin: 0, padding: 0}} ellipsis={true}>
                {list?.map(i => (
                  <span key={i}>
                    {i} <Divider type={'vertical'} />
                  </span>
                ))}
              </Typography.Paragraph>
            </Tooltip>
          );
        }}
      />
    </Table>
  );
}
