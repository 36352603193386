import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Select, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, RobotFunctionApi, RobotFunctionDto, RobotFunctionSaveParams} from '../../scaffold';
import RobotSelector from '../Robot/Selector';

const RobotFunctionEditForm = (props: {onSuccess?: (res: RobotFunctionDto) => any; robotFunction?: RobotFunctionDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<RobotFunctionSaveParams>();
  const updateHook = useOpenApiFpRequest(RobotFunctionApi, RobotFunctionApi.prototype.robotFunctionSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.robotFunction?.id,
      name: props.robotFunction?.name ?? undefined,
      robotId: props.robotFunction?.robotId ?? undefined,
      remark: props.robotFunction?.remark ?? undefined,
      isDelete: props.robotFunction?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.robotFunction]);

  function onSubmit(data: RobotFunctionSaveParams) {
    updateHook
      .request({robotFunctionSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<RobotFunctionSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="服务名称" name="name" rules={[{required: true, message: '请输入服务名称'}]}>
          <Input placeholder="请输入服务名称" />
        </Form.Item>
        <Form.Item label="机器人" name="robotId">
          <RobotSelector placeholder="请选择所在机器人" />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default RobotFunctionEditForm;
