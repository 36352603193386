import React, {useEffect} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionTemplateApi} from '../../scaffold';
import {Descriptions} from 'antd';

export function MissionTemplateDescriptions(props: {id?: number}) {
  const findHook = useOpenApiFpRequest(MissionTemplateApi, MissionTemplateApi.prototype.missionTemplateFindGet);
  useEffect(() => {
    props.id &&
      findHook.requestSync({
        id: props.id,
      });
  }, [props.id]);
  return (
    <div>
      <Descriptions bordered>
        {!findHook.data?.isTemporary && <Descriptions.Item label="任务模板名称">{findHook.data?.name}</Descriptions.Item>}
        <Descriptions.Item label="自动回桩电位">{findHook.data?.autoAppendRecharge ? '✅ 是' : '❌ 否'}</Descriptions.Item>
        <Descriptions.Item label="重复执行次数">{findHook.data?.repeatCount + ' 次'}</Descriptions.Item>
        <Descriptions.Item label="楼栋">{findHook.data?.building?.name}</Descriptions.Item>
      </Descriptions>
    </div>
  );
}
