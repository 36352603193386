import React, {useMemo} from 'react';
import {RobotDto} from '../../scaffold';
import {Progress, ProgressProps} from 'antd';
interface IProps extends ProgressProps {
  robot?: RobotDto;
}
export function RobotBattery(props: IProps) {
  const colors = [
    {
      '0%': '#135200',
      '100%': '#389e0d',
    },
    {
      '0%': '#612500',
      '100%': '#d46b08',
    },
    {
      '0%': '#610b00',
      '100%': '#d4380d',
    },
  ];
  const color = useMemo(() => {
    const battery = props.robot?.battery ?? 0;
    if (battery > 60) {
      return colors[0];
    } else if (battery > 40) {
      return colors[1];
    } else {
      return colors[2];
    }
  }, [props.robot]);
  return <Progress strokeColor={color} percent={props.robot?.battery} {...props} />;
}
