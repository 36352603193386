import React, {useEffect, useMemo} from 'react';
import {Avatar, Button, List, message, notification, Skeleton} from 'antd';
import {useDebounceEffect} from 'ahooks';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionScheduleApi, MissionScheduleDto} from '../../scaffold';
import RobotImage from '../../assets/robot-1.png';
import {AiOutlineFieldTime} from 'react-icons/all';
const daysMap = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
const colorList = ['#1890ff', '#87d068', '#f56a00', '#08979c', '#531dab', '#c41d7f'];

export function MissionScheduleText(props: {missionSchedule?: MissionScheduleDto}) {
  const weekends = useMemo(() => {
    const dayOfWeeks = props.missionSchedule?.dayOfWeeks ?? [];
    return dayOfWeeks.map(i => daysMap[i]);
  }, [props.missionSchedule?.dayOfWeeks]);
  const timePeriod = useMemo(() => {
    const hour = props.missionSchedule?.hour ?? 0;
    if (hour >= 0 && hour <= 6) {
      return '凌晨';
    } else if (hour > 6 && hour <= 12) {
      return '上午';
    } else if (hour > 12 && hour <= 17) {
      return '下午';
    } else if (hour > 17 && hour <= 19) {
      return '傍晚';
    } else if (hour > 19 && hour <= 24) {
      return '夜间';
    }
  }, [props.missionSchedule?.hour]);
  return (
    <span>
      {weekends.join('、')} {timePeriod} {props.missionSchedule?.time}
    </span>
  );
}

export function MissionScheduleList(props: {robotId: number; onSuccess?: () => any}) {
  const listHook = useOpenApiFpRequest(MissionScheduleApi, MissionScheduleApi.prototype.missionScheduleListGet);
  const removeHook = useOpenApiFpRequest(MissionScheduleApi, MissionScheduleApi.prototype.missionScheduleRemovePost);
  function refresh() {
    listHook.requestSync({
      robotId: props.robotId,
    });
  }
  useEffect(() => {
    refresh();
  }, [props.robotId]);
  return (
    <div>
      <Skeleton avatar={true} round={true} active={true} loading={listHook.loading}>
        <List
          itemLayout="horizontal"
          dataSource={listHook.data}
          renderItem={item => (
            <List.Item
              extra={
                <Button.Group size="small">
                  {/*<Button danger>取消下次</Button>*/}
                  <Button
                    danger
                    onClick={() => {
                      const res = confirm('您确定要取消所有此定时任务');
                      if (res && item.id) {
                        removeHook
                          .request({
                            missionScheduleRemoveParams: {
                              id: item.id,
                            },
                          })
                          .then(r => {
                            notification.success({
                              message: '取消定时任务',
                              description: '您已成功删除此定时任务',
                            });
                            refresh();
                            props.onSuccess && props.onSuccess();
                          })
                          .catch(e => message.error(e));
                      }
                    }}>
                    取消所有
                  </Button>
                </Button.Group>
              }>
              <List.Item.Meta
                avatar={<AiOutlineFieldTime color={colorList[(item.missionTemplateId ?? 0) % colorList.length]} size={40} />}
                title={item?.missionTemplate?.name}
                description={<MissionScheduleText missionSchedule={item} />}
              />
            </List.Item>
          )}
        />
      </Skeleton>
    </div>
  );
}
