import React, {useEffect, useState} from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, DatePicker, Descriptions, Form, Input, message, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {RobotMessageApi, SorterOrder} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import RobotMessageTable from '../../components/RobotMessage/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import AntDTableUtils from '../../utils/AntDTableUtils';
import RobotSelector from '../../components/Robot/Selector';
import {RobotPositionDescription} from '../../components/Robot/PositionDescription';
import {Moment} from 'moment';
import {ReloadOutlined} from '@ant-design/icons';
export function RobotMessageIndexPage() {
  const searchHook = useOpenApiFpRequest(RobotMessageApi, RobotMessageApi.prototype.robotMessageSearchGet);
  const exportHook = useOpenApiFpRequest(RobotMessageApi, RobotMessageApi.prototype.robotMessageExportPost);
  const [currentRobotId, setCurrentRobotId] = useState<number>();
  const [packageId, setPackageId] = useState<string>();
  const [from, setFrom] = useState<Moment>();
  const [to, setTo] = useState<Moment>();
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        robotId: currentRobotId,
        packageId,
      }),
    {...DefaultAntdTableConfig, onSuccess: () => console.log('fetchSuccess')},
  );
  useEffect(() => {
    antdConfig.search.submit();
  }, [packageId, currentRobotId]);
  function refresh() {
    antdConfig.search.submit();
  }
  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <div>
            <Title level={4} style={{marginBottom: 0}}>
              通信日志
            </Title>
            <Typography.Text type={'secondary'}>你可以在此处实时查询机器人通信日志</Typography.Text>
          </div>
          <div
            style={{marginLeft: 4, marginRight: 4, cursor: 'pointer'}}
            onClick={() => {
              refresh();
            }}>
            <ReloadOutlined spin={searchHook.loading} style={{color: '#666', width: 16, height: 16}} />
          </div>
        </Flex>
      }>
      <Card size={'small'} style={{marginBottom: 16}}>
        <Form style={{marginLeft: 32}} layout={'inline'}>
          <Form.Item label={'机器人'}>
            <RobotSelector
              allowClear={true}
              style={{minWidth: 180}}
              value={currentRobotId}
              onChange={setCurrentRobotId}
              placeholder={'请选择一个机器人'}
              defaultActiveFirstOption={true}
            />
          </Form.Item>
          <Form.Item label={'PackageId'}>
            <Input value={packageId} onChange={v => setPackageId(v.target.value)} placeholder={'请输入 PackageId'} />
          </Form.Item>
          <Form.Item label={'开始时间'}>
            <DatePicker
              value={from}
              onChange={v => {
                setFrom(v as Moment | undefined);
              }}
            />
          </Form.Item>
          <Form.Item label={'结束时间'}>
            <DatePicker
              value={to}
              onChange={v => {
                setTo(v as Moment | undefined);
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                exportHook
                  .request({
                    robotMessageExportParams: {
                      robotId: currentRobotId,
                      packageId,
                      from: from?.format('YYYY-MM-DD HH:mm:ss'),
                      to: to?.format('YYYY-MM-DD HH:mm:ss'),
                    },
                  })
                  .then(r => {
                    window.open(r.info);
                  })
                  .catch(e => message.error(e));
              }}>
              导出
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Skeleton loading={searchHook.loading}>
        <RobotMessageTable antDTable={antdConfig} tableProps={{size: 'small'}} />
      </Skeleton>
    </LayoutPage>
  );
}
