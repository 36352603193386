import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, FloorApi, FloorApiFactory, FloorDto, FloorSaveParams} from '../../scaffold';
import BuildingSelector from '../Building/Selector';

const FloorEditForm = (props: {onSuccess?: (res: FloorDto) => any; floor?: FloorDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<FloorSaveParams>();
  const updateHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.floor?.id,
      name: props.floor?.name ?? undefined,
      buildingId: props.floor?.buildingId ?? undefined,
      remark: props.floor?.remark ?? undefined,
      isDelete: props.floor?.isDelete,
      commandName: props.floor?.commandName ?? undefined,
    });
  }

  useEffect(() => {
    reset();
  }, [props.floor]);

  function onSubmit(data: FloorSaveParams) {
    updateHook
      .request({floorSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<FloorSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="楼层名" name="name" rules={[{required: true, message: '请输入楼层名'}]}>
          <Input placeholder="请输入楼层名(面向用户显示)" />
        </Form.Item>
        <Form.Item label="楼层对应代码" name="commandName" rules={[{required: true, message: '请输入楼层对应代码'}]}>
          <Input placeholder="请输入楼层对应代码 (面向机器人)" />
        </Form.Item>
        <Form.Item label="所在楼栋" name="buildingId" rules={[{required: true, message: '请选择所在楼栋'}]}>
          <BuildingSelector placeholder="请选择所在楼栋" />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default FloorEditForm;
