import React, {useEffect} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {BuildingApi, FloorApi} from '../../scaffold';
import {Button, Modal, Typography} from 'antd';
import FloorTable from '../Floor/Table';
import {useBoolean} from 'react-hanger';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import FloorEditForm from '../Floor/EditForm';
export function BuildingDetail(props: {id?: number; onSuccess?: () => any}) {
  const findHook = useOpenApiFpRequest(BuildingApi, BuildingApi.prototype.buildingFindGet);
  const listHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorListByBuildingGet);
  const isAddFloor = useBoolean(false);
  const floorAntdTable = useAntdTable(
    async params => {
      if (!props.id) {
        return {};
      }
      const list = await listHook.request({
        buildingId: props.id,
      });
      return {
        list: list,
        total: list.length,
      };
    },
    {
      ...DefaultAntdTableConfig,
    },
  );
  function refresh(id: number) {
    findHook.requestSync({
      id: id,
    });
    floorAntdTable.search.submit();
  }
  useEffect(() => {
    props.id && refresh(props.id);
  }, [props.id]);
  return (
    <div>
      <Typography.Text style={{fontSize: 20}} strong>
        {findHook.data?.name}
      </Typography.Text>
      <div style={{marginTop: 8}}>
        <Button size={'small'} onClick={isAddFloor.setTrue} type={'primary'} style={{marginBottom: 8}}>
          新增楼层
        </Button>
        <FloorTable antDTable={floorAntdTable} tableProps={{size: 'small'}} />
      </div>
      <Modal visible={isAddFloor.value} onCancel={isAddFloor.setFalse} footer={null}>
        <FloorEditForm
          onSuccess={() => {
            isAddFloor.setFalse();
            props.id && refresh(props.id);
            props.onSuccess && props.onSuccess();
          }}
        />
      </Modal>
    </div>
  );
}
