import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MapApi, SorterOrder} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import MapTable from '../../components/Map/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import MapEditForm from '../../components/Map/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
import MapCardList from '../../components/Map/CardList';
export function MapIndexPage() {
  const searchHook = useOpenApiFpRequest(MapApi, MapApi.prototype.mapSearchGet);
  const isAddMap = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            地图管理
          </Title>
          <Typography.Text type={'secondary'}>您可以在此处编辑/删除/新建地图</Typography.Text>
        </div>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddMap.setTrue}>
              录入地图
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <MapCardList />
      </Skeleton>
      <Modal title={'录入地图'} visible={isAddMap.value} maskClosable={false} closable={false} footer={false}>
        <MapEditForm
          onCancel={isAddMap.setFalse}
          onSuccess={res => {
            isAddMap.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
