import React, {useEffect, useState} from 'react';
import {PeripheralHistoryDto} from '../../scaffold';
import {Space, Table, TableProps} from 'antd';
import {Result} from 'ahooks/lib/useAntdTable';

export default function PeripheralHistoryTable(props: {antDTable: Result<PeripheralHistoryDto>; tableProps?: TableProps<any>; onSuccess?: () => any}) {
  const {tableProps} = props.antDTable;

  return (
    <>
      <Table<PeripheralHistoryDto> {...tableProps} {...props.tableProps} rowKey={'id'} size={'small'} bordered>
        <Table.Column<PeripheralHistoryDto> title="机器人" dataIndex={['peripheral', 'robot', 'name']} />
        <Table.Column<PeripheralHistoryDto> title="外设" dataIndex={['peripheral', 'name']} />
        <Table.Column<PeripheralHistoryDto> title="状态" dataIndex={['status']} />
        <Table.Column<PeripheralHistoryDto> title="读取时间" dataIndex={'createdTime'} />
      </Table>
    </>
  );
}
