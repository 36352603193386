import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, MapApi, MapDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Card, Image, message, Space, Table} from 'antd';
import MapUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';

export default function MapCardList() {
  const searchHook = useOpenApiFpRequest(MapApi, MapApi.prototype.mapSearchGet);
  useEffect(() => {
    searchHook.requestSync({
      pi: 1,
      ps: 999,
    });
  }, []);
  return (
    <div>
      <Card
        tabList={[
          {
            key: 'article',
            tab: 'article',
          },
          {
            key: 'app',
            tab: 'app',
          },
          {
            key: 'project',
            tab: 'project',
          },
        ]}></Card>
      {searchHook.data?.list
        ?.filter(i => i.url)
        .map(i => (
          <Card key={i.id} hoverable style={{width: 800, margin: 16}} cover={i.url && <Image src={i.url} />}>
            <Card.Meta title={i.floor?.name} />
          </Card>
        ))}
    </div>
  );
}
