import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {UserRoleApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import UserRoleTable from '../../components/UserRole/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import UserRoleEditForm from '../../components/UserRole/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function UserRoleIndexPage() {
  const searchHook = useOpenApiFpRequest(UserRoleApi, UserRoleApi.prototype.userRoleSearchGet);
  const isRegisterUserRole = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          角色管理
        </Title>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isRegisterUserRole.setTrue}>
              录入角色
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <UserRoleTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入角色'} visible={isRegisterUserRole.value} maskClosable={false} closable={false} footer={false}>
        <UserRoleEditForm
          onCancel={isRegisterUserRole.setFalse}
          onSuccess={res => {
            isRegisterUserRole.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
