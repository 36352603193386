import React, {useEffect, useMemo} from 'react';
import {message, Radio, RadioGroupProps} from 'antd';
import {PeripheralAbilityCommandApi, SorterOrder} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {RadioChangeEvent} from 'antd/lib/radio/interface';

interface IProps extends RadioGroupProps {
  peripheralAbilityId?: number;
}
export default function PeripheralAbilityCommandRadio(props: IProps) {
  const searchHook = useOpenApiFpRequest(PeripheralAbilityCommandApi, PeripheralAbilityCommandApi.prototype.peripheralAbilityCommandSearchGet);
  useEffect(() => {
    searchHook.requestSync({pi: 1, ps: 999, peripheralAbilityId: props.peripheralAbilityId, sorterKey: 'name', sorterOrder: SorterOrder.Asc});
  }, [props.peripheralAbilityId]);

  return (
    <Radio.Group {...props}>
      {searchHook?.data?.list?.map(i => (
        <Radio key={i.id} value={i.id}>
          {i.name}
        </Radio>
      ))}
    </Radio.Group>
  );
}
