import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, SpotApi, SpotApiFactory, SpotDto, SpotSaveParams} from '../../scaffold';
import FloorSelector from '../Floor/Selector';
import SpotTypeSelector from '../SpotType/Selector';
import SpotTypeRadio from '../SpotType/Radio';

const SpotEditForm = (props: {onSuccess?: (res: SpotDto) => any; spot?: SpotDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<SpotSaveParams>();
  const updateHook = useOpenApiFpRequest(SpotApi, SpotApi.prototype.spotSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.spot?.id,
      name: props.spot?.name ?? undefined,
      floorId: props.spot?.floorId ?? undefined,
      spotTypeId: props.spot?.spotTypeId ?? undefined,
      remark: props.spot?.remark ?? undefined,
      commandName: props.spot?.commandName ?? undefined,
      isDelete: props.spot?.isDelete,
    });
  }

  useEffect(() => {
    reset();
  }, [props.spot]);

  function onSubmit(data: SpotSaveParams) {
    updateHook
      .request({spotSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<SpotSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="地点名称" name="name" rules={[{required: true, message: '请输入地点名称'}]}>
          <Input placeholder="请输入地点名称(面向用户)" />
        </Form.Item>
        <Form.Item label="机器人对应代码" name="commandName" rules={[{required: true, message: '请输入机器人对应代码'}]}>
          <Input placeholder="请输入对应代码(面向机器人)" />
        </Form.Item>
        <Form.Item label="终点类型" name="spotTypeId" rules={[{required: true, message: '请选择终点类型'}]}>
          <SpotTypeRadio />
        </Form.Item>
        <Form.Item label="楼层" name="floorId" rules={[{required: true, message: '请输入楼层'}]}>
          <FloorSelector placeholder="请选择楼层" />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default SpotEditForm;
