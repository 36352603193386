import React, {useEffect, useState} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, BuildingDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Button, message, Modal, Space, Table, Typography} from 'antd';
import BuildingUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {BuildingDetail} from './Detail';

export default function BuildingTable(props: {antDTable: Result<BuildingDto>}) {
  const {tableProps, search, loading} = props.antDTable;
  const [selectedId, setSelectedId] = useState<number>();
  return (
    <div>
      <Table<BuildingDto> {...tableProps} rowKey={'id'} bordered>
        <Table.Column<BuildingDto> title="ID" dataIndex="id" sorter />
        <Table.Column<BuildingDto> title="楼栋名" dataIndex={'name'} sorter />
        <Table.Column<BuildingDto> title="负责人" dataIndex={['principal', 'name']} sorter />
        <Table.Column<BuildingDto> title="备注" dataIndex={'remark'} sorter />
        <Table.Column<BuildingDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
        <Table.Column<BuildingDto>
          title="操作"
          render={(text, row) => {
            return (
              <Space>
                <BuildingUpdateInfoBtn building={row} type={'link'} size={'small'} onSuccess={search.submit} />
                <Button
                  onClick={() => {
                    setSelectedId(row.id);
                  }}
                  type={'link'}>
                  修改楼层
                </Button>
              </Space>
            );
          }}
        />
      </Table>
      <Modal visible={!!selectedId} onCancel={() => setSelectedId(undefined)} footer={null} width={800}>
        <BuildingDetail id={selectedId} />
      </Modal>
    </div>
  );
}
