import React from 'react';
import {useEffect, useState} from 'react';
import {useOpenApiFpRequest} from '../Http/useOpenApiRequest';
import {RobotApi} from '../scaffold';
import {message} from 'antd';

export function useRobotId() {
  const [robotId, setter] = useState<number>();
  const firstRobotHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFirstGet);
  const setRobotId = (id?: number) => {
    localStorage.setItem('robotId', String(id));
    return setter(id);
  };

  useEffect(() => {
    const id = localStorage.getItem('robotId');
    if (!id) {
      firstRobotHook
        .request({})
        .then(r => {
          setRobotId(r.id);
        })
        .catch(e => message.error(e));
    }
    setter(Number(id));
  }, []);

  return [robotId, setRobotId] as any;
}
