import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi, MissionDto, MissionStatus, RobotApi, RobotDto, RobotSocketApi, RobotStatus, User} from '../../scaffold';
import {useBoolean} from 'react-hanger';
import LayoutPage from '../../components/Layout/LayoutPage';
import Title from 'antd/lib/typography/Title';
import {RiFireFill} from 'react-icons/all';
import Flex from '../../components/Shared/Flex';
import {Button, Card, Col, Form, Modal, Row, Skeleton, Spin, Tabs, Tag, Typography} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import RobotImg from '../../assets/robot-2.png';
import {RunningTag} from '../../components/Robot/RunningTag';
import {AiOutlineFieldTime, RiDownloadCloud2Line} from 'react-icons/all';
import MissionEditForm from '../../components/Mission/EditForm';
import {MissionSteps} from '../../components/Mission/MissionSteps';
import MissionScheduleEditForm from '../../components/MissionSchedule/EditForm';
import {MissionScheduleList} from '../../components/MissionSchedule/List';
import RobotSelector from '../../components/Robot/Selector';
import {MissionPauseButton} from '../../components/Mission/PauseButton';
import {MissionStopButton} from '../../components/Mission/StopButton';
import {MissionResumeButton} from '../../components/Mission/ResumeButton';
import {RobotPositionDescription} from '../../components/Robot/PositionDescription';
import {MissionBasicDescriptions} from '../../components/Mission/BasicDescriptions';
import {MissionLogSteps} from '../../components/Mission/LogSteps';
import {MissionHistoryTable} from '../../components/Mission/HistoryTable';
import {MissionTemplateDescriptions} from '../../components/MissionTemplate/Descriptions';
import MissionTemplateActionTable from '../../components/MissionTemplateAction/Table';
import {RobotMapTrace} from '../../components/Robot/MapTrace';
import {RobotBattery} from '../../components/Robot/RobotBattery';
import {RobotBatteryLineChart} from '../../components/Robot/BatteryLineChart';
import {RobotPositionHistoryTableCtrl} from '../../components/PeripheralStatusHistory/TableCtrl';
import {PeripheralHistoryTableCtrl} from '../../components/RobotPositionHistory/TableCtrl';
import {useRobotId} from '../../hooks/useRobotId';
import {ReloadOutlined} from '@ant-design/icons';
import {useAuthContext} from 'geestack-toolbox';
import {UserRolePermissionType} from '../../components/UserRole/UserRolePermissionType';

type PageTabType = '当前进度' | '任务日志' | '历史任务' | '指令序列' | '当前位置' | '电池电量' | '位置历史' | '外设历史';
export function getRobotStatus(mission?: MissionDto, robot?: RobotDto) {
  if (robot?.robotStatus === RobotStatus.异常) {
    return '异常:' + robot.robotError;
  }
  if (mission?.status === MissionStatus.进行中) {
    return '正在执行任务';
  }
  if (mission?.status === MissionStatus.任务暂停) {
    return '任务暂停中';
  }
  return '空闲中';
}

export function MissionIndexPage() {
  const searchHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionSearchGet);
  const firstRobotHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFirstGet);
  const robotFindHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotFindGet);
  const getCurrentMissionHook = useOpenApiFpRequest(RobotApi, RobotApi.prototype.robotGetCurrentMissionGet);
  const [currentTab, setCurrentTab] = useState<PageTabType>();
  const isAddTemp = useBoolean(false);
  const isAddSchedule = useBoolean(false);
  const [currentRobotId, setCurrentRobotId] = useRobotId();
  const listUpperSocketHook = useOpenApiFpRequest(RobotSocketApi, RobotSocketApi.prototype.robotSocketListUpperClientsGet);
  const auth = useAuthContext();
  const user = useMemo(() => {
    return auth.currentUser as User;
  }, [auth.currentUser]);
  function containsPermission(type: UserRolePermissionType) {
    return user.userRole?.userRolePermissions?.includes(UserRolePermissionType[type]);
  }
  function refresh() {
    getCurrentMissionHook.setData(undefined);
    firstRobotHook.setData(undefined);
    listUpperSocketHook.setData(undefined);
    robotFindHook.setData(undefined);
    if (currentRobotId) {
      getCurrentMissionHook.requestSync({
        id: currentRobotId,
      });
      robotFindHook.requestSync({
        id: currentRobotId,
      });
    }
    listUpperSocketHook.requestSync({});
  }
  useEffect(() => {
    if (currentRobotId) {
      refresh();
    }
  }, [currentRobotId]);

  const currentMission = useMemo(() => {
    return getCurrentMissionHook.data?.info as MissionDto | undefined;
  }, [getCurrentMissionHook.data]);

  useEffect(() => {
    const tab = containsPermission(UserRolePermissionType.任务查看) ? '当前进度' : '当前位置';
    setCurrentTab(tab);
  }, [auth.currentUser]);

  return (
    <LayoutPage
      header={
        <Flex direction={'row'} align={'center'}>
          <Title level={4} style={{marginBottom: 0}}>
            任务计划管理
          </Title>
          <div style={{marginLeft: 4, cursor: 'pointer'}} onClick={() => refresh()}>
            <ReloadOutlined spin={getCurrentMissionHook.loading || robotFindHook.loading} style={{color: '#666', width: 16, height: 16}} />
          </div>
          {containsPermission(UserRolePermissionType.下发任务) && (
            <Button.Group style={{marginLeft: 32}}>
              <Button type="primary" style={{display: 'flex', alignItems: 'center'}} onClick={isAddTemp.setTrue}>
                <RiDownloadCloud2Line style={{color: '#fff', marginRight: 8, fontSize: 20}} />
                下发临时任务
              </Button>
              <Button type="primary" style={{display: 'flex', alignItems: 'center'}} onClick={isAddSchedule.setTrue}>
                <AiOutlineFieldTime style={{color: '#fff', marginRight: 8, fontSize: 20}} />
                安排定时任务
              </Button>
            </Button.Group>
          )}

          <Form style={{marginLeft: 32}}>
            <Form.Item label={'机器人'}>
              <RobotSelector
                style={{minWidth: 300}}
                value={currentRobotId}
                onChange={setCurrentRobotId}
                placeholder={'请选择一个机器人'}
                allowClear={false}
                defaultActiveFirstOption={true}
              />
            </Form.Item>
          </Form>
          <Flex style={{marginLeft: 16, padding: 8, border: '1px solid #eaeaea', borderRadius: 20}} align={'center'}>
            <RiFireFill fontSize={18} color={'red'} />
            <Typography.Text style={{marginLeft: 8}}>
              消防客户端：
              <Tag color={(listUpperSocketHook.data?.length ?? 0) > 0 ? 'green' : 'red'}>
                {(listUpperSocketHook.data?.length ?? 0) > 0 ? '已连接' : '未连接'}
              </Tag>
              <Typography.Text type={(listUpperSocketHook.data?.length ?? 0) > 0 ? 'success' : 'danger'}></Typography.Text>
            </Typography.Text>
          </Flex>
        </Flex>
      }>
      <Card style={{margin: 'auto', width: 1200}}>
        <Flex direction={'row'} justify={'space-between'} align={'center'}>
          <div>
            <Flex direction={'row'} align={'center'}>
              <img src={RobotImg} style={{width: 80, marginRight: 32, marginLeft: 32}} alt={'robot'} />
              <Flex direction={'column'} style={{marginLeft: 12}}>
                <Typography.Text style={{fontSize: 24}}>{robotFindHook.data?.name}</Typography.Text>
                <RunningTag robot={robotFindHook.data} />
                {containsPermission(UserRolePermissionType.任务控制) && (
                  <Flex direction={'row'} align={'center'}>
                    {currentMission?.status === MissionStatus.进行中 && <MissionPauseButton mission={currentMission} onSuccess={refresh} />}
                    {currentMission?.status === MissionStatus.任务暂停 && <MissionResumeButton mission={currentMission} onSuccess={refresh} />}
                    <MissionStopButton mission={currentMission} onSuccess={refresh} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </div>
          <Flex align={'center'} direction={'column'}>
            <Typography.Text
              style={{
                fontSize: 32,
                marginTop: 16,
                color: robotFindHook.data?.robotStatus === RobotStatus.异常 ? 'red' : '#096dd9',
              }}>
              {getRobotStatus(currentMission, robotFindHook.data)}
            </Typography.Text>
            {currentMission ? (
              <>
                <Typography.Text style={{fontSize: 16, marginTop: 16}} type={'secondary'}>
                  任务名称
                </Typography.Text>
                <Typography.Text style={{fontSize: 24}} type={'secondary'}>
                  {currentMission?.missionTemplate?.name}
                </Typography.Text>
              </>
            ) : (
              <>
                <Typography.Text style={{fontSize: 16, marginTop: 16}} type={'secondary'}>
                  您可以选择一项任务以开始
                </Typography.Text>
              </>
            )}
          </Flex>
          <RobotPositionDescription robot={robotFindHook.data} />
        </Flex>
      </Card>
      <Skeleton loading={searchHook.loading}>
        <div style={{width: 1200, margin: '32px auto'}}>
          <Tabs defaultActiveKey={currentTab} onChange={v => setCurrentTab(v as PageTabType)}>
            {containsPermission(UserRolePermissionType.任务查看) && <Tabs.TabPane key={'当前进度'} tabKey={'当前进度'} tab={'当前进度'} />}

            {containsPermission(UserRolePermissionType.任务查看) && currentMission && (
              <>
                <Tabs.TabPane key={'任务日志'} tabKey={'任务日志'} tab={'任务日志'} />
                <Tabs.TabPane key={'指令序列'} tabKey={'指令序列'} tab={'指令序列'} />
              </>
            )}
            <Tabs.TabPane key={'当前位置'} tabKey={'当前位置'} tab={'当前位置'} />
            {containsPermission(UserRolePermissionType.任务查看) && <Tabs.TabPane key={'历史任务'} tabKey={'历史任务'} tab={'历史任务'} />}
            <Tabs.TabPane key={'电池电量'} tabKey={'电池电量'} tab={'电池电量'} />
            <Tabs.TabPane key={'位置历史'} tabKey={'位置历史'} tab={'位置历史'} />
            {containsPermission(UserRolePermissionType.历史外接设备状态查询) && <Tabs.TabPane key={'外设历史'} tabKey={'外设历史'} tab={'外设历史'} />}
          </Tabs>
          {currentTab === '当前进度' && (
            <Row gutter={32}>
              <Col span={8}>
                <Typography.Title level={4}>当前任务进度</Typography.Title>
                <Card>
                  <div style={{maxHeight: 500, overflow: 'auto'}}>
                    <MissionSteps missionId={currentMission?.id} />
                  </div>
                </Card>
              </Col>
              <Col span={16}>
                <Typography.Title level={4}>未来的定时计划</Typography.Title>
                <Card>{robotFindHook.data?.id && <MissionScheduleList robotId={robotFindHook.data?.id} />}</Card>
              </Col>
            </Row>
          )}
          {currentTab === '任务日志' && (
            <div>
              <Typography.Title level={4}>基本信息</Typography.Title>
              <Card bodyStyle={{padding: 0}}>
                <MissionBasicDescriptions id={currentMission?.id} />
              </Card>
              <Typography.Title level={4} style={{marginTop: 16}}>
                任务日志
              </Typography.Title>
              <Card>
                <MissionLogSteps id={currentMission?.id} />
              </Card>
            </div>
          )}
          {currentTab === '当前位置' && (
            <div>
              <Card bodyStyle={{padding: 0}}>
                <RobotMapTrace height={800} robotId={currentRobotId} />
              </Card>
            </div>
          )}
          {currentTab === '指令序列' && (
            <div>
              <Typography.Title level={4}>任务信息</Typography.Title>
              <Card bodyStyle={{padding: 0}}>
                <MissionTemplateDescriptions id={currentMission?.missionTemplateId} />
              </Card>
              <Typography.Title level={4} style={{marginTop: 16}}>
                指令序列
              </Typography.Title>
              <Card>
                <MissionTemplateActionTable id={currentMission?.missionTemplateId} />
              </Card>
            </div>
          )}
          {currentTab === '历史任务' && (
            <div>
              <Typography.Title level={4}>历史任务</Typography.Title>
              <Card bodyStyle={{padding: 0}}>
                <Spin spinning={!currentRobotId}>{currentRobotId && <MissionHistoryTable robotId={currentRobotId} />}</Spin>
              </Card>
            </div>
          )}
          {currentTab === '电池电量' && (
            <div>
              <Row gutter={32}>
                <Col span={8}>
                  <Typography.Title level={4}>当前电量</Typography.Title>
                  <Card>
                    <RobotBattery robot={robotFindHook.data} />
                  </Card>
                </Col>
                <Col span={16}>
                  <Typography.Title level={4}>电量历史</Typography.Title>
                  <Card>
                    <RobotBatteryLineChart height={300} robotId={currentRobotId} />
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          {currentTab === '位置历史' && (
            <div>
              <RobotPositionHistoryTableCtrl robotId={currentRobotId} />
            </div>
          )}
          {currentTab === '外设历史' && (
            <div>
              <PeripheralHistoryTableCtrl robotId={currentRobotId} />
            </div>
          )}
        </div>
      </Skeleton>
      <Modal title={'下发任务'} visible={isAddTemp.value} maskClosable={false} closable={false} footer={false} width={1200} destroyOnClose={true}>
        <MissionEditForm
          robotId={robotFindHook.data?.id}
          onCancel={isAddTemp.setFalse}
          onSuccess={res => {
            isAddTemp.setFalse();
            refresh();
          }}
        />
      </Modal>
      <Modal title={'添加定时执行任务'} visible={isAddSchedule.value} maskClosable={false} closable={false} footer={false} width={800}>
        <MissionScheduleEditForm
          onCancel={isAddSchedule.setFalse}
          onSuccess={res => {
            isAddSchedule.setFalse();
            refresh();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
