import React from 'react';
import {Button, ButtonProps, Modal} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {UserRoleDto} from '../../scaffold';
import UserRoleEditForm from './EditForm';
import PermissionEditForm from './PermissionEditForm';
import UserRolePermissionEditForm from './PermissionEditForm';

interface IEditPermissionBtnProps extends ButtonProps {
  userRole: UserRoleDto;
  onSuccess?: (userRole: UserRoleDto) => any;
}

export default function UserRoleEditPermissionBtn(props: IEditPermissionBtnProps) {
  const {userRole, ...btnProps} = props;
  const isDialog = useBoolean(false);

  return (
    <div>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        编辑权限
      </Button>
      <Modal title={'编辑权限'} visible={isDialog.value} maskClosable={false} closable={false} footer={false} width={800}>
        <UserRolePermissionEditForm
          userRole={props.userRole}
          onSuccess={res => {
            props.onSuccess && props.onSuccess(res);
            isDialog.setFalse();
          }}
          onCancel={isDialog.setFalse}
        />
      </Modal>
    </div>
  );
}
