import Flex from '../Shared/Flex';
import {Typography} from 'antd';
import React, {useMemo} from 'react';
import {RobotFunctionDto, RobotDto, RobotStatus} from '../../scaffold';

export function RobotFunctionRunningTag(props: {robotFunction?: RobotFunctionDto}) {
  const color = useMemo(() => {
    if (props.robotFunction?.status === '正常') {
      return '#389e0d';
    } else {
      return '#faad14';
    }
  }, [props.robotFunction?.status]);
  return (
    <Flex direction={'row'} align={'center'}>
      <div style={{width: 12, height: 12, background: color, borderRadius: '50%'}} />
      <Typography.Text style={{color: color, marginLeft: 8}}>{props.robotFunction?.status ?? '暂无状态'}</Typography.Text>
    </Flex>
  );
}
