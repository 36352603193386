import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Card, Col, Descriptions, Modal, Row, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {SpotTypeApi} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import SpotTypeTable from '../../components/SpotType/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import SpotTypeEditForm from '../../components/SpotType/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';
export function SpotTypeIndexPage() {
  const searchHook = useOpenApiFpRequest(SpotTypeApi, SpotTypeApi.prototype.spotTypeSearchGet);
  const isAddSpotType = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            终点类型管理
          </Title>
          <Typography.Text type={'secondary'}>您可以在此处编辑、删除、新建终点类型, 如: 房间/走廊/楼梯 等, 对终点进行分类</Typography.Text>
        </div>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddSpotType.setTrue}>
              录入终点类型
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <SpotTypeTable antDTable={antdConfig} />
      </Skeleton>
      <Modal title={'录入终点类型'} visible={isAddSpotType.value} maskClosable={false} closable={false} footer={false}>
        <SpotTypeEditForm
          onCancel={isAddSpotType.setFalse}
          onSuccess={res => {
            isAddSpotType.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
