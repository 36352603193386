import React from 'react';
import Title from 'antd/lib/typography/Title';
import {Button, Modal, Skeleton, Space, Typography} from 'antd';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {FloorApi, SorterOrder} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import FloorTable from '../../components/Floor/Table';
import {useBoolean} from 'react-hanger';
import Flex from '../../components/Shared/Flex';
import FloorEditForm from '../../components/Floor/EditForm';
import AntDTableUtils from '../../utils/AntDTableUtils';

export function FloorIndexPage() {
  const searchHook = useOpenApiFpRequest(FloorApi, FloorApi.prototype.floorSearchGet);
  const isAddFloor = useBoolean(false);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        sorterKey: 'name',
        sorterOrder: SorterOrder.Asc,
      }),
    {...DefaultAntdTableConfig},
  );
  return (
    <LayoutPage
      header={
        <div>
          <Title level={4} style={{marginBottom: 0}}>
            楼层管理
          </Title>
          <Typography.Text type={'secondary'}>您可以在此处编辑/删除/新建楼层</Typography.Text>
        </div>
      }>
      <div style={{width: '100%'}}>
        <Flex className={'m-b-16'} justify={'space-between'}>
          <Space>
            <Button type={'primary'} onClick={isAddFloor.setTrue}>
              录入楼层
            </Button>
          </Space>
        </Flex>
      </div>
      <Skeleton loading={searchHook.loading}>
        <FloorTable antDTable={antdConfig} tableProps={{size: 'small'}} />
      </Skeleton>
      <Modal title={'录入楼层'} visible={isAddFloor.value} maskClosable={false} closable={false} footer={false}>
        <FloorEditForm
          onCancel={isAddFloor.setFalse}
          onSuccess={res => {
            isAddFloor.setFalse();
            antdConfig.search.submit();
          }}
        />
      </Modal>
    </LayoutPage>
  );
}
