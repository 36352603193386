import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Select, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, PeripheralApi, PeripheralApiFactory, PeripheralDto, PeripheralSaveParams, PeripheralType} from '../../scaffold';
import UserSelector from '../User/Selector';
import BuildingSelector from '../Building/Selector';
import PeripheralTypeRadio from './PeripheralTypeRadio';
import RobotSelector from '../Robot/Selector';

const PeripheralEditForm = (props: {onSuccess?: (res: PeripheralDto) => any; peripheral?: PeripheralDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<PeripheralSaveParams>();
  const updateHook = useOpenApiFpRequest(PeripheralApi, PeripheralApi.prototype.peripheralSavePost);

  function reset() {
    let liveAddressList = props.peripheral?.liveAddressList ?? ['', '', '', ''];
    if (liveAddressList.length < 4) {
      liveAddressList = ['', '', '', ''];
    }
    form.setFieldsValue({
      id: props.peripheral?.id,
      name: props.peripheral?.name ?? undefined,
      type: props.peripheral?.type ?? undefined,
      robotId: props.peripheral?.robotId ?? undefined,
      liveAddress: props.peripheral?.liveAddress ?? undefined,
      liveAddressList: liveAddressList,
      remark: props.peripheral?.remark ?? undefined,
      isDelete: props.peripheral?.isDelete,
      model: props.peripheral?.model,
      brand: props.peripheral?.brand,
    });
  }

  useEffect(() => {
    reset();
  }, [props.peripheral]);

  function onSubmit(data: PeripheralSaveParams) {
    updateHook
      .request({peripheralSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<PeripheralSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="名称" name="name" rules={[{required: true, message: '请输入楼层名'}]}>
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item label="类型" name="type" rules={[{required: true, message: '请选择外设类型'}]}>
          <PeripheralTypeRadio />
        </Form.Item>
        <Form.Item label="品牌" name="brand">
          <Input placeholder="请输入品牌" />
        </Form.Item>
        <Form.Item label="类型" name="model">
          <Input placeholder="请输入外设型号" />
        </Form.Item>
        <Form.Item label="机器人" name="robotId">
          <RobotSelector placeholder="请选择所在机器人" />
        </Form.Item>
        <Form.Item noStyle={true} shouldUpdate={true}>
          {() =>
            [PeripheralType.白光相机, PeripheralType.双光谱相机, PeripheralType.深度相机].includes(form.getFieldValue('type')) && (
              <Form.Item label="直播流地址" name="liveAddress" rules={[{required: true, message: '请输入直播流地址'}]}>
                <Input addonBefore="rtsp://" />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item noStyle={true} shouldUpdate={true}>
          {() =>
            form.getFieldValue('type') === PeripheralType.全景相机 && (
              <Form.List name="liveAddressList">
                {(fields, _, {errors}) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...field}
                        label={index + '号相机地址'}
                        rules={[
                          {
                            required: true,
                            message: '请输入直播流地址',
                          },
                        ]}
                        key={field.key}>
                        <Input addonBefore="rtsp://" />
                      </Form.Item>
                    ))}
                  </>
                )}
              </Form.List>
            )
          }
        </Form.Item>

        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default PeripheralEditForm;
