import React, {useContext, useMemo} from 'react';
import {Button, Checkbox, Form, Input, message, Space, Typography} from 'antd';
import './login.less';
import {BrowserRouter, Switch, Route, Link, useHistory} from 'react-router-dom';
import {useAuthContext} from 'geestack-toolbox';
import {useBoolean, useInput} from 'react-hanger';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {AuthorizeApi, UserApi} from '../../scaffold';
import LoginLogo from '../../assets/logo.png';
import Flex from '../../components/Shared/Flex';
import CommonApiErrorHandler from '../../utils/HttpInstance';
import {useMount} from 'ahooks';
import Text from 'antd/lib/typography/Text';
import {Config} from '../../config';

const LoginPage: React.FunctionComponent<any> = props => {
  const auth = useAuthContext();
  const history = useHistory();
  const userLoginHook = useOpenApiFpRequest(AuthorizeApi, AuthorizeApi.prototype.authorizeLoginPost);
  const usernameInput = useInput();
  const passwordInput = useInput();
  const isRememberCheck = useBoolean(true);
  const isForget = useBoolean(false);

  async function login() {
    userLoginHook
      .request({
        authorizeLoginParams: {
          password: passwordInput.value,
          username: usernameInput.value,
        },
      })
      .then(response => {
        if (response.body && response.user) {
          localStorage.setItem('Application-Token', response.body);
          auth.setToken(response.body);
          auth.setCurrentUser(response.user);
        }
        history.push(`/mission`);
      })
      .catch(CommonApiErrorHandler);
  }

  return (
    <div className={'LoginPage'}>
      <div className={'LoginPageMask'} />
      <div className={'LoginPanel'}>
        {isForget.value ? (
          <div className={'LoginForm'}>
            <Button block type={'primary'} onClick={isForget.setFalse} size={'large'}>
              返回
            </Button>
          </div>
        ) : (
          <div className={'LoginForm'}>
            <h1 className={'LoginTitle'}>登录</h1>
            <Form layout={'vertical'}>
              <Form.Item label={'用户名'}>
                <Input {...usernameInput.eventBind} placeholder={'请输入用户名'} className={'m-b-8'} />
              </Form.Item>
              <Form.Item label={'密码'}>
                <Input.Password {...passwordInput.eventBind} placeholder={'请输入密码'} onPressEnter={login} className={'m-b-16'} />
              </Form.Item>
            </Form>
            <Flex style={{marginBottom: 40}} justify={'space-between'}>
              <Checkbox checked={isRememberCheck.value} onChange={e => isRememberCheck.setValue(e.target.checked)}>
                自动登录
              </Checkbox>
              <a className={'text-bold'} onClick={isForget.setTrue}>
                忘记密码
              </a>
            </Flex>
            <Button block type={'primary'} className={'m-b-8'} onClick={login} size={'large'} loading={userLoginHook.loading}>
              登录
            </Button>
            <Text type={'secondary'}>版本号: {Config.version}</Text>
          </div>
        )}
      </div>
    </div>
  );
};
export default LoginPage;
