import React from 'react';
import {Avatar, AvatarProps} from 'antd';
import {UserDto} from '../../scaffold';
interface IUserAvatarProps extends AvatarProps {
  user: UserDto;
}
export default function UserAvatar(props: IUserAvatarProps) {
  const {user, ...avatarProps} = props;
  const colorList = ['#1890ff', '#87d068', '#f56a00'];
  return (
    <Avatar {...avatarProps} style={{backgroundColor: colorList[(props.user.id ?? 0) % colorList.length]}} shape={'square'} gap={6}>
      {user.name ? user.name[0] : ''}
    </Avatar>
  );
}
