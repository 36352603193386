import React, {useEffect} from 'react';
import {Gender, SorterOrder, UserApi, UserApiFactory, UserDto} from '../../scaffold';
import {Avatar, message, Space, Table} from 'antd';
import UserAvatar from './UserAvatar';
import UserUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function UserTable(props: {antDTable: Result<UserDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<UserDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<UserDto> title="ID" dataIndex="id" sorter />
      <Table.Column<UserDto>
        title="姓名"
        dataIndex={'name'}
        sorter
        render={(_, item) => (
          <Space>
            <UserAvatar user={item} />
            <strong>{item.name}</strong>
          </Space>
        )}
      />
      <Table.Column<UserDto> title="真实姓名" dataIndex="name" sorter />
      <Table.Column<UserDto> title="用户名" dataIndex="username" sorter />
      <Table.Column<UserDto> title="角色" dataIndex={['userRole', 'name']} sorter />
      <Table.Column<UserDto>
        title="性别"
        dataIndex="gender"
        sorter
        render={(_, item) => {
          if (_ === Gender.Female) {
            return '女';
          } else {
            return '男';
          }
        }}
      />
      <Table.Column<UserDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
      <Table.Column<UserDto> title="创建时间" dataIndex={'createdTime'} sorter />
      <Table.Column<UserDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <UserUpdateInfoBtn user={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
