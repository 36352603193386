import React from 'react';
import {Radio} from 'antd';
import {RadioProps} from 'antd/lib/radio';
import {PeripheralType} from '../../scaffold';
export default function PeripheralTypeRadio(props: RadioProps) {
  return (
    <Radio.Group {...props}>
      <Radio value={PeripheralType.默认外设}>{PeripheralType.默认外设}</Radio>
      <Radio value={PeripheralType.双光谱相机}>{PeripheralType.双光谱相机}</Radio>
      <Radio value={PeripheralType.白光相机}>{PeripheralType.白光相机}</Radio>
      <Radio value={PeripheralType.深度相机}>{PeripheralType.深度相机}</Radio>
      <Radio value={PeripheralType.全景相机}>{PeripheralType.全景相机}</Radio>
      <Radio value={PeripheralType.云台}>{PeripheralType.云台}</Radio>
      <Radio value={PeripheralType.梯控模块}>{PeripheralType.梯控模块}</Radio>
    </Radio.Group>
  );
}
