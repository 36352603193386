import React, {useEffect, useMemo} from 'react';
import {Empty, message, Skeleton, Steps, Typography} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi, MissionTemplateAction, MissionTemplateActionApi, MissionTemplateActionDto} from '../../scaffold';
export function MissionSteps(props: {missionId?: number}) {
  const missionFindHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionFindGet);
  const missionTemplateActionListHook = useOpenApiFpRequest(
    MissionTemplateActionApi,
    MissionTemplateActionApi.prototype.missionTemplateActionListByTemplateGet,
  );
  useEffect(() => {
    missionFindHook.setData(undefined);
    missionTemplateActionListHook.setData(undefined);
    props.missionId &&
      missionFindHook
        .request({
          id: props.missionId,
        })
        .then(
          r =>
            r.missionTemplateId &&
            missionTemplateActionListHook.requestSync({
              missionTemplateId: r.missionTemplateId,
            }),
        )
        .catch(e => message.error(e));
  }, [props.missionId]);
  const list = useMemo(() => {
    const indexList = new Array(missionFindHook.data?.missionTemplate?.repeatCount ?? 1).fill(0);
    const res: Array<{
      repeatCount: number;
      item: MissionTemplateActionDto;
    }> = [];
    for (let index = 0; index < indexList.length; index++) {
      missionTemplateActionListHook.data?.forEach(i => {
        res.push({
          repeatCount: index,
          item: i,
        });
      });
    }
    return res;
  }, [missionTemplateActionListHook.data, missionFindHook.data]);
  const currentIndex = useMemo(() => {
    return list.findIndex(i => i.item.id === missionFindHook.data?.missionTemplateActionId && i.repeatCount === missionFindHook.data?.currentRepeatCount);
  }, [list, missionFindHook.data]);

  return (
    <div>
      {!missionFindHook.data && <Empty description="暂无任务执行" />}
      <Skeleton round={true} active={true} loading={missionTemplateActionListHook.loading || missionFindHook.loading}>
        <Steps current={currentIndex} direction="vertical" style={{marginTop: 16}}>
          {list.map((i, index) => {
            const missionTemplateActionId = missionFindHook.data?.missionTemplateActionId ?? 0;
            const id = i.item.id ?? 0;
            return (
              <Steps.Step
                key={i.item.id}
                title={
                  <Typography.Text>
                    {i.item.actionName} {i.repeatCount != 0 && `[第 ${i.repeatCount} 次重复]`}
                  </Typography.Text>
                }
                description={`${i.item.actionType?.name}${i.item.spot?.name ? ' - ' + i.item.spot?.name : ''}`}
              />
            );
          })}
        </Steps>
      </Skeleton>
    </div>
  );
}
