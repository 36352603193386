import React from 'react';
import {PeripheralDto, PeripheralType} from '../../scaffold';
import {AiOutlineVideoCamera, BiDevices, MdOutlineElevator} from 'react-icons/all';
import {IconBaseProps} from 'react-icons';
interface IProps extends IconBaseProps {
  peripheral?: PeripheralDto;
}
export function PeripheralIcon(props: IProps) {
  if (
    props.peripheral?.type === PeripheralType.深度相机 ||
    props.peripheral?.type === PeripheralType.双光谱相机 ||
    props.peripheral?.type === PeripheralType.白光相机 ||
    props.peripheral?.type === PeripheralType.全景相机
  ) {
    return <AiOutlineVideoCamera {...props} />;
  } else if (props.peripheral?.type === PeripheralType.梯控模块) {
    return <MdOutlineElevator {...props} />;
  } else {
    return <BiDevices {...props} />;
  }
}
