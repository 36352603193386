import React from 'react';
import {Button, ButtonProps, Modal} from 'antd';
import {useBoolean, useInput} from 'react-hanger';
import {PeripheralAbilityDto} from '../../scaffold';
import PeripheralAbilityEditForm from './EditForm';

interface IUpdateInfoBtnProps extends ButtonProps {
  peripheralAbility: PeripheralAbilityDto;
  onSuccess?: (peripheralAbility: PeripheralAbilityDto) => any;
}

export default function PeripheralAbilityUpdateInfoBtn(props: IUpdateInfoBtnProps) {
  const {peripheralAbility, ...btnProps} = props;
  const isDialog = useBoolean(false);

  return (
    <div>
      <Button {...btnProps} onClick={isDialog.setTrue}>
        编辑
      </Button>
      <Modal title={'编辑信息'} visible={isDialog.value} maskClosable={false} closable={false} footer={false}>
        <PeripheralAbilityEditForm
          peripheralAbility={props.peripheralAbility}
          onSuccess={res => {
            props.onSuccess && props.onSuccess(res);
            isDialog.setFalse();
          }}
          onCancel={isDialog.setFalse}
        />
      </Modal>
    </div>
  );
}
