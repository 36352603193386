import React, {useEffect} from 'react';
import {Button, Form, Input, message, Radio, Space, Switch} from 'antd';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {Gender, BuildingApi, BuildingApiFactory, BuildingDto, BuildingSaveParams} from '../../scaffold';
import UserRoleSelector from '../UserRole/UserRoleSelector';
import UserSelector from '../User/Selector';

const BuildingEditForm = (props: {onSuccess?: (res: BuildingDto) => any; building?: BuildingDto; onCancel?: () => any}) => {
  const [form] = Form.useForm<BuildingSaveParams>();
  const updateHook = useOpenApiFpRequest(BuildingApi, BuildingApi.prototype.buildingSavePost);

  function reset() {
    form.setFieldsValue({
      id: props.building?.id,
      name: props.building?.name ?? undefined,
      remark: props.building?.remark ?? undefined,
      isDelete: props.building?.isDelete,
      principalId: props.building?.principalId,
    });
  }

  useEffect(() => {
    reset();
  }, [props.building]);

  function onSubmit(data: BuildingSaveParams) {
    updateHook
      .request({buildingSaveParams: data})
      .then(res => {
        message.success('操作成功');
        reset();
        props.onSuccess && props.onSuccess(res);
      })
      .catch(e => message.error(e.message));
  }

  return (
    <div>
      <Form<BuildingSaveParams> layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item label="ID" name="id" hidden>
          <Input placeholder="自动生成, 无需填写" disabled />
        </Form.Item>
        <Form.Item label="楼栋名" name="name" rules={[{required: true, message: '请输入楼栋名'}]}>
          <Input placeholder="请输入姓名" />
        </Form.Item>
        <Form.Item label="负责人" name="principalId" rules={[{required: true, message: '请选择负责人'}]}>
          <UserSelector />
        </Form.Item>
        <Form.Item label="备注" name="remark">
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="是否启用" name="isDelete">
          <Radio.Group>
            <Radio value={false}>启用</Radio>
            <Radio value={true}>冻结</Radio>
          </Radio.Group>
        </Form.Item>
        <div className={'text-right m-t-16'}>
          <Space>
            <Button type="primary" htmlType={'submit'} style={{width: 120}} loading={updateHook.loading} disabled={updateHook.loading}>
              保存
            </Button>
            <Button style={{width: 120}} onClick={props.onCancel}>
              取消
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};
export default BuildingEditForm;
