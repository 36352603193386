import React, {useEffect, useMemo} from 'react';
import {Line, LineConfig} from '@ant-design/charts';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {RobotStatusHistoryApi, SorterOrder} from '../../scaffold';

export function RobotBatteryLineChart(props: {robotId?: number; height?: number}) {
  const searchHook = useOpenApiFpRequest(RobotStatusHistoryApi, RobotStatusHistoryApi.prototype.robotStatusHistorySearchGet);
  useEffect(() => {
    props.robotId &&
      searchHook.requestSync({
        robotId: props.robotId,
        pi: 1,
        ps: 1000,
        sorterKey: 'createdTime',
        sorterOrder: SorterOrder.Desc,
      });
  }, [props.robotId]);
  const data = useMemo(() => {
    return searchHook.data?.list?.reverse() ?? [];
  }, [searchHook.data]);
  const config: LineConfig = {
    data: data,
    padding: 'auto',
    autoFit: true,
    height: props.height ?? 500,
    xField: 'createdTime',
    yField: 'battery',
  };

  return <Line {...config} />;
}
