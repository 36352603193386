import React, {useEffect, useState} from 'react';
import Title from 'antd/lib/typography/Title';
import Flex from '../../components/Shared/Flex';
import {Button, Card, Drawer, List, Modal, notification, Skeleton, Space, Typography} from 'antd';
import BuildingTable from '../../components/Building/Table';
import BuildingEditForm from '../../components/Building/EditForm';
import LayoutPage from '../../components/Layout/LayoutPage';
import {useArray} from 'react-hanger';
import {Config} from '../../config';
import moment from 'moment';
import ReactJson from 'react-json-view';
export function DebugIndexPage() {
  const list = useArray<{
    time: string;
    str: string;
  }>([]);
  const [selected, setSelected] = useState<any>();
  let ws: WebSocket | undefined;
  function connect() {
    ws = new WebSocket(`ws://${Config.wsHost}:${Config.wsPort}/debug`);

    ws.onopen = () => {
      console.log('debug socket opened');
    };
    ws.onmessage = msg => {
      list.push({
        time: moment().format('HH:mm:ss'),
        str: msg.data,
      });
    };
    ws.onclose = () => {
      console.log('closed');
    };
    ws.onerror = () => {
      ws = connect();
    };
    return ws;
  }

  useEffect(() => {
    ws = connect();
    return () => {
      if (ws) {
        console.log('debug closed');
        ws.close();
      }
    };
  }, []);

  return (
    <LayoutPage
      header={
        <Title level={4} style={{marginBottom: 0}}>
          实时日志
        </Title>
      }>
      <Card>
        <List<{
          time: string;
          str: string;
        }>
          dataSource={list.value}
          renderItem={row => (
            <List.Item>
              <List.Item.Meta
                title={`[${row.time}] 来自${JSON.parse(row.str)?.From}`}
                description={
                  <div>
                    <Typography.Text>{JSON.parse(row.str)?.Msg}</Typography.Text>
                    <Button
                      type={'link'}
                      onClick={() => {
                        setSelected(JSON.parse(row.str));
                      }}>
                      以JSON查看
                    </Button>
                  </div>
                }
              />
            </List.Item>
          )}></List>
      </Card>
      <Drawer visible={!!selected} onClose={() => setSelected(undefined)} width={800}>
        {selected?.Msg && <ReactJson name={null} collapsed={2} src={JSON.parse(selected?.Msg)} />}
      </Drawer>
    </LayoutPage>
  );
}
