import React, {useEffect, useState} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, MissionDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, Button, Card, message, Modal, Space, Table, Tabs, Typography} from 'antd';
import MissionUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';
import {MissionStatusTag} from './StatusTag';
import {useBoolean} from 'react-hanger';
import {MissionBasicDescriptions} from './BasicDescriptions';
import {MissionLogSteps} from './LogSteps';
import {MissionTemplateDetail} from '../MissionTemplate/Detail';
import {MissionDetail} from './Detail';

export default function MissionTable(props: {antDTable: Result<MissionDto>}) {
  const {tableProps, search, loading} = props.antDTable;
  const [mission, setMission] = useState<MissionDto>();
  const isShowMission = useBoolean(false);

  return (
    <>
      <Table<MissionDto> {...tableProps} rowKey={'id'} bordered>
        <Table.Column<MissionDto> title="ID" dataIndex="id" />
        <Table.Column<MissionDto> title="任务内容" dataIndex={['missionTemplate', 'name']} />
        <Table.Column<MissionDto> title="状态" dataIndex="status" render={(txt, row) => <MissionStatusTag mission={row} />} />
        <Table.Column<MissionDto> title="下发时间" dataIndex="createdTime" render={(txt: string) => !txt.includes('0001') && txt} />
        <Table.Column<MissionDto> title="任务最后更新时间" dataIndex="lastReportTime" render={(txt: string) => !txt.includes('0001') && txt} />
        <Table.Column<MissionDto> title="完成时间" dataIndex="finishTime" render={(txt: string) => !txt.includes('0001') && txt} />
        <Table.Column<MissionDto> title="任务异常" width={200} dataIndex="errorMessage" />
        <Table.Column<MissionDto>
          title="操作"
          render={(_, row) => (
            <Button.Group>
              <Button
                type={'link'}
                onClick={() => {
                  isShowMission.setTrue();
                  setMission(row);
                }}>
                查看任务内容
              </Button>
            </Button.Group>
          )}
        />
      </Table>
      <Modal visible={isShowMission.value} onCancel={isShowMission.setFalse} title={'任务信息'} width={1000} footer={null}>
        <div>
          <MissionDetail mission={mission} />
        </div>
      </Modal>
    </>
  );
}
