import React, {useEffect} from 'react';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {MissionApi, PeripheralApi} from '../../scaffold';
import {useBoolean} from 'react-hanger';
import {useAntdTable} from 'ahooks';
import AntDTableUtils from '../../utils/AntDTableUtils';
import {DefaultAntdTableConfig} from '../../utils/CommonConfig';
import MissionTable from './Table';
export function MissionHistoryTable(props: {robotId: number}) {
  const searchHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionSearchGet);
  const antdConfig = useAntdTable(
    params =>
      searchHook.request({
        ...AntDTableUtils.makeParams(params),
        robotId: props.robotId,
      }),
    {
      ...DefaultAntdTableConfig,
      onSuccess: () => {
        console.log('获取成功');
      },
    },
  );
  useEffect(() => {
    antdConfig.search.submit();
  }, [props.robotId]);
  return <MissionTable antDTable={antdConfig} />;
}
