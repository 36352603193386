import React from 'react';
import {Button, message, notification} from 'antd';
import {MissionApi, MissionDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
export function MissionStopButton(props: {mission?: MissionDto; onSuccess?: () => any}) {
  const stopHook = useOpenApiFpRequest(MissionApi, MissionApi.prototype.missionForceStopPost);
  return (
    <Button
      style={{margin: 8}}
      type={'primary'}
      danger={true}
      disabled={!props.mission}
      onClick={() => {
        const res = confirm('您确认要停止此任务?');
        if (res) {
          props.mission?.robotId &&
            stopHook
              .request({
                missionForceStopAllParams: {
                  robotId: props.mission?.robotId,
                },
              })
              .then(r => {
                notification.success({
                  message: '终止任务',
                  description: ' 您已成功停止此任务',
                });
              })
              .catch(e => {
                message.error({
                  content: e.message,
                });
              })
              .finally(() => {
                props.onSuccess && props.onSuccess();
              });
        }
      }}>
      取消任务
    </Button>
  );
}
