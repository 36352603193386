import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, RobotFunctionDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table} from 'antd';
import RobotFunctionUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function RobotFunctionTable(props: {antDTable: Result<RobotFunctionDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<RobotFunctionDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<RobotFunctionDto> title="ID" dataIndex="id" sorter />
      <Table.Column<RobotFunctionDto> title="机器人" dataIndex={['robot', 'name']} sorter />
      <Table.Column<RobotFunctionDto> title="服务" dataIndex={['name']} sorter />
      <Table.Column<RobotFunctionDto> title="备注" dataIndex={['remark']} sorter />
      <Table.Column<RobotFunctionDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <RobotFunctionUpdateInfoBtn robotFunction={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
