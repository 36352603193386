import React, {useMemo} from 'react';
import {Cascader, CascaderProps, Divider, Select} from 'antd';
import {SelectProps} from 'antd/lib/select';
import {BuildingDto, FloorDto, Gender, SpotApi, SpotDto, SpotTypeDto} from '../../scaffold';
import {useOpenApiFpRequest} from '../../Http/useOpenApiRequest';
import {useMount} from 'ahooks';
import {CascaderOptionType, CascaderValueType} from 'antd/lib/cascader';
export function SpotCascaderDisplayRender(labels: string[], selectedOptions: CascaderOptionType[] | undefined) {
  return labels.map((label, i) => {
    const option = selectedOptions![i];
    if (i === labels.length - 1) {
      return <span key={option.value}>{label}</span>;
    }
    return <span key={option.value}>{label} / </span>;
  });
}
export default function SpotCascader(props: Partial<CascaderProps>) {
  const searchHook = useOpenApiFpRequest(SpotApi, SpotApi.prototype.spotSearchGet);
  useMount(() => {
    searchHook.requestSync({pi: 1, ps: 999});
  });

  const buildingMap = useMemo(() => {
    const list = searchHook.data?.list?.map(i => i.floor?.building) ?? [];
    const map = new Map<number, BuildingDto>();
    for (const item of list) {
      item && item.id && map.set(item.id, item);
    }
    return map;
  }, [searchHook.data]);

  const floorMap = useMemo(() => {
    const list = searchHook.data?.list?.map(i => i.floor).sort((a, b) => (String(a?.name) > String(b?.name) ? 1 : -1)) ?? [];
    const map = new Map<number, FloorDto>();
    for (const item of list) {
      item && item.id && map.set(item.id, item);
    }
    return map;
  }, [searchHook.data]);

  const spotMap = useMemo(() => {
    const list = searchHook.data?.list ?? [];
    const map = new Map<number, SpotDto>();
    for (const item of list) {
      item && item.id && map.set(item.id, item);
    }
    return map;
  }, [searchHook.data]);

  const spotTypeToSpotsMap = useMemo(() => {
    const list = searchHook.data?.list?.map(i => i.spotType) ?? [];
    const spotTypeMap = new Map<number, SpotDto>();
    for (const item of list) {
      item && item.id && spotTypeMap.set(item.id, item);
    }

    const map = new Map<
      number,
      {
        spotType: SpotTypeDto;
        list: SpotDto[];
      }
    >();
    for (const [spotTypeId, spotType] of spotTypeMap) {
      const spots = [];
      for (const [spotId, spot] of spotMap) {
        if (spot.spotTypeId === spotTypeId) spots.push(spot);
      }
      map.set(spotTypeId, {
        list: spots,
        spotType,
      });
    }
    return map;
  }, [searchHook.data]);

  function getFloorOptions(floor: FloorDto) {
    const spotOptions: CascaderOptionType[] = [];
    for (const [spotTypeId, spotTypeToSpotsMapValue] of spotTypeToSpotsMap) {
      spotOptions.push({
        label: spotTypeToSpotsMapValue.spotType.name,
        value: spotTypeToSpotsMapValue.spotType.id ?? undefined,
        children: spotTypeToSpotsMapValue.list
          .filter(i => i.floorId === floor.id)
          .map(i => ({
            value: i.id ?? undefined,
            label: i.name,
          })),
      });
    }
    return spotOptions;
  }

  const options = useMemo(() => {
    const list: CascaderOptionType[] = [];
    for (const [buildingId, building] of buildingMap) {
      const floors: FloorDto[] = [];
      for (const [floorId, floor] of floorMap) {
        if (floor.buildingId === buildingId) {
          floors.push(floor);
        }
      }
      list.push({
        label: building.name,
        value: building.id ?? undefined,
        children: floors.map(i => ({
          value: i.id ?? undefined,
          label: i.name,
          children: getFloorOptions(i),
        })),
      });
    }
    return list;
  }, [buildingMap, floorMap, spotTypeToSpotsMap]);

  return <Cascader {...props} onChange={v => v.length == 4 && props.onChange && props.onChange(v)} options={options} />;
}
