import React, {useEffect} from 'react';
import useOpenApi from '../../Http/useOpenApi';
import {Gender, RobotDto} from '../../scaffold';
import {useAntdTable} from 'ahooks';
import {Avatar, message, Space, Table} from 'antd';
import RobotUpdateInfoBtn from './UpdateInfoBtn';
import {Result} from 'ahooks/lib/useAntdTable';
import {IsDeleteTag} from '../Shared/IsDeleteTag';

export default function RobotTable(props: {antDTable: Result<RobotDto>}) {
  const {tableProps, search, loading} = props.antDTable;

  return (
    <Table<RobotDto> {...tableProps} rowKey={'id'} bordered>
      <Table.Column<RobotDto> title="ID" dataIndex="id" sorter />
      <Table.Column<RobotDto> title="名称" dataIndex={'name'} sorter />
      <Table.Column<RobotDto>
        title="消防Socket客户端配置"
        dataIndex={'fireControlIpHost'}
        sorter
        render={(_, row) => `${row.fireControlIpHost}:${row.fireControlIpPort}`}
      />
      <Table.Column<RobotDto> title="语音Socket客户端配置" dataIndex={'chatIpHost'} sorter render={(_, row) => `${row.chatIpHost}:${row.chatIpPort}`} />
      <Table.Column<RobotDto> title="本体Socket客户端配置" dataIndex={'ipHost'} sorter render={(_, row) => `${row.ipHost}:${row.ipPort}`} />
      <Table.Column<RobotDto> title="负责人" dataIndex={['principal', 'name']} sorter />
      <Table.Column<RobotDto> title="楼栋" dataIndex={['building', 'name']} sorter />
      <Table.Column<RobotDto> title="备注" dataIndex={['remark']} sorter />
      <Table.Column<RobotDto> title="状态" sorter dataIndex="isDelete" render={(_, item) => <IsDeleteTag isDelete={_} />} />
      <Table.Column<RobotDto>
        title="操作"
        render={(text, row) => {
          return (
            <Space>
              <RobotUpdateInfoBtn robot={row} type={'link'} size={'small'} onSuccess={search.submit} />
            </Space>
          );
        }}
      />
    </Table>
  );
}
